import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { emphasize } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import Button from "@mui/material/Button";

import { ReactComponent as BackIcon } from "../../../../assets/icons/back-icon.svg";
import { TermConditionsStyles } from './index.css';
import { theme } from '../../../../theme/theme';
import { to } from '../../../../utils/enums';


const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const CustomButtom = styled(Button)((props) => ({
    
    [theme.breakpoints.up('sm')]: {
        width: "300px",
    },

    [theme.breakpoints.only('xs')]: {
        margin: '0px',
        marginTop: '5px'
    },

    [theme.breakpoints.down('md')]: {
        width: "250px",
    },

    textTransform: "none",
    fontSize: ".95rem",
    fontFamily: "PoppinsBold",
    padding: "10px 0px",
    borderRadius: "7px",
    marginBottom: '20px',
    marginTop: '20px',
    '&:disabled': {
        backgroundColor: props.theme.palette.gray.light,
        color: props.theme.palette.white.main
    },
    boxShadow: "none",
    color: `${props.theme.palette.white.main} !important`,
}));

const TermConditions = () => {
    const {
        formANFTramit: { restClvUnica },
    } = useSelector((state) => state.root);
    const navigate = useNavigate();
    const styles = TermConditionsStyles();
    const [showText, setShowText] = useState('term');

    const handleClick = (event) => {
        event.preventDefault();
    }

    const handleGoBack = () => navigate(`${to.CLV_UNIQUE_VERIFY}/${restClvUnica['contractID']}/${restClvUnica['signerID']}`);

    return (
        <div className={styles.mainContent}>

            <Grid container spacing={2} className="content-grid">

                <Grid item xs={12} className={styles.headerGrid}>

                    <BackIcon onClick={() => handleGoBack()} />

                    <Typography
                        onClick={() => handleGoBack()}
                        variant="h5"
                        gutterBottom
                    >
                        Volver atrás
                    </Typography>

                </Grid>

                <Grid item xs={12} className={styles.breadcrumbsGrid}>

                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        Te encuentras aquí:
                    </Typography>

                    <div role="presentation" onClick={handleClick}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <StyledBreadcrumb
                                onClick={() => handleGoBack()}
                                component="a"
                                href="#"
                                label="Inicio"
                                icon={<HomeIcon fontSize="small" />}
                                sx={{
                                    color: theme.palette.gray.main
                                }}
                            />
                            <StyledBreadcrumb
                                component="a"
                                sx={{
                                    color: theme.palette.primary.main
                                }}
                                href="#"
                                label="Términos y políticas"
                            />
                        </Breadcrumbs>
                    </div>

                </Grid>

                <Grid item xs={12} className={styles.tabButtomsGrid}>
                    <CustomButtom
                        onClick={() => setShowText('term')}
                        sx={{
                            backgroundColor: showText === 'term' ? theme.palette.primary.main : theme.palette.gray.light,
                            marginRight: '30px',

                            '&:hover': {
                                backgroundColor: showText === 'term' ? theme.palette.primary.main : theme.palette.gray.light,
                            },
                        }}
                    >Términos y condiciones</CustomButtom>
                    <CustomButtom
                        onClick={() => setShowText('polit')}
                        sx={{
                            backgroundColor: showText === 'term' ? theme.palette.gray.light : theme.palette.primary.main,

                            '&:hover': {
                                backgroundColor: showText === 'term' ? theme.palette.gray.light : theme.palette.primary.main
                            },
                        }}
                    >Políticas de seguridad</CustomButtom>
                </Grid>

                <Grid item xs={12} className={styles.contentTextGrid}>

                    {showText === 'term' && (
                        <Fragment>
                            <Typography
                                variant="h5"
                                gutterBottom
                            >
                                Términos y Condiciones
                            </Typography>
                            <Typography
                                variant="h6"
                                gutterBottom
                            >
                                En Infocheck, la transparencia es un valor central, por lo cual hemos puesto mucho esfuerzo en hacer este documento lo más simple y entendible posible.Estos Términos y Condiciones y el Contrato expresados más adelante, explican a qué nos comprometemos nosotros, y a que te comprometes tú, cuando compras un informe de evaluación Infocheck (www.infocheck.cl). La idea es que estés informado sobre lo que hacemos, así como cuál es el correcto uso y acceso al sitio web. Te recomendamos leer atentamente estos Términos y Condiciones y su Contrato, los que fueron actualizados por última vez el 01 de marzo de 2022.
                            </Typography>
                        </Fragment>
                    )}

                    {showText === 'polit' && (
                        <Fragment>
                            <Typography
                                variant="h5"
                                gutterBottom
                            >
                                Políticas de privacidad
                            </Typography>
                            <Typography
                                variant="h6"
                                gutterBottom
                            >
                                En este documento queremos explicarte que datos recogemos y como trabajamos con ellos para brindarte la mejor atención y experiencia posible.
                                Ten presente que la privacidad es un valor central de Infocheck, por lo cual hemos fijado nuestros estándares siguiendo la exigente Ley Europea de Datos Personales, mucho más avanzada que la legislación local. Así nos aseguramos de dar la mayor protección posible a tus datos.
                                El principio principal que rige nuestra política de manejo de datos personales es que TÚ eres el dueño de cualquier dato asignado a tu número de Rut, y por ende cualquier acceso, transferencia, solicitud, edición, y en general cualquier procesamiento de información concerniente a tu persona debe ser autorizada por ti. Dado esto, se solicitará tu autorización para compartir alguno de tus datos con un tercero. Este documento es una parte integral de los Términos y Condiciones de la Plataforma. Te recomendamos leer atentamente este documento que fue actualizado por última vez el 01 de marzo de 2022.
                                Este documento es una parte integral de los Términos y Condiciones de la Plataforma. Te recomendamos leer atentamente este documento que fue actualizado por última vez el 01 de marzo de 2022.
                            </Typography>
                        </Fragment>
                    )}


                </Grid>

            </Grid>

        </div>

    );

}

export default TermConditions;