import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { BiRightArrowAlt } from "react-icons/bi";

import { motion } from "framer-motion";

import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import { FormularioUachtyles } from "../../index.css";
import { errorStepForm, saveFormInfo } from "../../../../store/formUach/formUach";
import { checkRut, validation } from "../../../../utils/validations";


const StepTwo = ({ control, watch, trigger, setValue, formState }) => {
    const styles = FormularioUachtyles();
    const {
        formUach: {
            rut
        }
    } = useSelector((state) => state.root);
    const dispatch = useDispatch();
    const nameInput = 'rut';
    const watchRut = watch(nameInput);

    useEffect(() => {
        if ([undefined, null, ''].includes(formState['errors'][nameInput])) {
            dispatch(errorStepForm(false));
        } else {
            dispatch(errorStepForm(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    useEffect(() => {
        setValue(nameInput, rut);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rut]);

    useEffect(() => {

        trigger();

        if (!['', null, undefined].includes(watchRut) && watchRut !== rut) dispatch(saveFormInfo({ indexe: nameInput, value: watchRut }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchRut]);

    return (
        <div className={styles.stepBox}>

            <motion.div
                initial={{ y: "50vh" }}
                animate={{ y: 0 }}
                transition={{ stiffness: 150 }}>

                <Grid container xs={12}>

                    <Grid item={true} xs={1} className={styles.boxIndetify}>

                        2<BiRightArrowAlt color="rgb(0, 79, 159)" />

                    </Grid>

                    <Grid item={true} xs={11}>

                        <Typography variant="h4" className={styles.cssTexto1} gutterBottom>
                            RUT *
                        </Typography>

                        <Typography variant="h5" className={styles.cssTexto2} gutterBottom>
                            ¿Cuál es tu RUT?
                        </Typography>

                        <Controller
                            name={nameInput}
                            control={control}
                            rules={{
                                required: "Este campo es requerido",
                                validate: {

                                    isPassPortOrRut: (value) => {

                                        if ([checkRut(value), validation.passport.test(value)].includes(true)) {
                                            return true
                                        } else {
                                            return 'RUT o Pasaporte inválido Ej: 11111111-1 o 11111111'
                                        }
                                    }
                                }
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                return (
                                    <FormControl
                                        fullWidth
                                        error={fieldState.error ? true : false}
                                    >
                                        <TextField
                                            type="text"
                                            error={fieldState.error ? true : false}
                                            ref={ref}
                                            onBlur={onBlur}
                                            variant={'standard'}
                                            onChange={onChange}
                                            value={value}
                                            helperText={
                                                fieldState.error
                                                    ? fieldState.error.message
                                                    : null
                                            }
                                            placeholder={"11111111-1 o 11111111"}
                                            color={
                                                fieldState.error
                                                    ? "error"
                                                    : "success"
                                            }
                                            focused={
                                                fieldState.isTouched ||
                                                fieldState.isDirty
                                            }
                                            fullWidth
                                        />

                                    </FormControl>
                                );
                            }}
                        />

                    </Grid>

                </Grid>

            </motion.div>

        </div>
    );
}

export default StepTwo;