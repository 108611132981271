import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { default as AutocompleteMui } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";

import { typeUser, verifiVariable } from "../../../../../utils/enums";
import { saveTramitANFInfo } from "../../../../../store/formANFTramit";
import { SignAuthorizationStyles } from "../../index.css";

const Agreement = () => {
    const {
        organizations: { agreements },
        formANFTramit: { agrement },
        session: {
            login: { userInfo },
        },
    } = useSelector((state) => state.root);
    const styles = SignAuthorizationStyles();
    const refInputControl = useRef();
    const dispatch = useDispatch();
    const { control, watch } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            typeAgrement: "",
        },
    });

    const typeAgrementWatch = watch("typeAgrement");

    const getOptionLabel = (option) => {
        if (option.trim && option.trim() === "") {
            return "";
        }
        return option.agreementId+" - " +option.agreement + " - " + option.ModalPayment;
    };

    const renderAgrementsOptions = (props, option) => {
        return (
            <li
                {...props}
                key={option.agreementId}>
                {option.agreementId} - {option.agreement} - {option.ModalPayment}
            </li>
        );
    };

    useEffect(() => {
        if (!verifiVariable(typeAgrementWatch)) {
            dispatch(
                saveTramitANFInfo({
                    indexe: "agrement",
                    value: typeAgrementWatch,
                })
            );
        }
        // eslint-disable-next-line
    }, [typeAgrementWatch]);

    return (
        <Box className={styles.spacinContent}>
            {!verifiVariable(userInfo) &&
                userInfo["iProfileID"] === typeUser.ADMIN && (
                    <Controller
                        name={"typeAgrement"}
                        control={control}
                        rules={{
                            required: {
                                value:
                                    !verifiVariable(userInfo) &&
                                        userInfo["iProfileID"] === typeUser.ADMIN
                                        ? true
                                        : false,
                                message: "Este campo es requerido",
                            },
                        }}
                        render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState,
                        }) => {
                            return (
                                <FormControl
                                    fullWidth
                                    error={fieldState.error ? true : false}
                                >
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className={styles.title}
                                        color={["black.main"]}
                                    >
                                        Selecciona tipo de convenio
                                    </Typography>

                                    <AutocompleteMui
                                        value={agrement}
                                        isOptionEqualToValue={(option, value) => option.agreementId === value.agreementId}
                                        multiple={false}
                                        options={agreements}
                                        getOptionLabel={getOptionLabel}
                                        onChange={(e, options, reason) => {
                                            if (reason === "clear") {
                                                dispatch(
                                                    saveTramitANFInfo({
                                                        indexe: "agrement",
                                                        value: null,
                                                    })
                                                );
                                            }

                                            onChange(options);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                ref={refInputControl}
                                                {...params}
                                                placeholder="Selecciona tipo de convenio"
                                                className={
                                                    styles.autocompleteInput
                                                }
                                            />
                                        )}
                                        renderOption={renderAgrementsOptions}
                                    />

                                    <FormHelperText
                                        hidden={fieldState.error ? false : true}
                                    >
                                        {fieldState.error
                                            ? fieldState.error.message
                                            : null}
                                    </FormHelperText>

                                    <br />
                                </FormControl>
                            );
                        }}
                    />
                )}
        </Box>
    );
};

export default Agreement;
