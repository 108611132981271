import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
    Box, Typography, Grid, Button, TextField, FormHelperText,
    FormControl, Paper,
    RadioGroup,
    FormControlLabel,
    Radio,
    CircularProgress,
    Autocomplete
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { ManageCouponsTypeStyless } from '../index.css';
import { theme } from '../../../../theme/theme';

import { ReactComponent as IconBack } from '../../../../assets/icons/arrow-left-circularButtom.svg';
import { ReactComponent as IconHouseDisabled } from '../../../../assets/icons/icon-house-disabled.svg';

import { to, verifiVariable } from '../../../../utils/enums';
import { validation } from '../../../../utils/validations';
import { useDispatch, useSelector } from 'react-redux';
import { couponsTypeSaveInfo, createCouponType } from '../../../../store/coupons/couponsType';
import InfoMessage from '../../../infoMessage';


const CustomButton = styled(Button)((props) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.white.main,
    textTransform: 'none',
    padding: '.5rem 1.5rem',
    margin: '2rem 0',
    fontFamily: "PoppinsBold",
    width: '10rem',
    height: '3rem',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.primary.main
    }
}));

const AddCouponsType = ({ couponEdit }) => {

    const styles = ManageCouponsTypeStyless();

    //hooks
    const {
        control,
        register,
        handleSubmit,
        //reset,
        //formState: { errors },
        setValue,
        watch,
        //clearErrors
    } = useForm();
    // const { couponEdit } = useParams();
    const [queryResult, setQueryResult] = useState({});
    const navegate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useSelector(state => state.root.session.login.user);
    const { /* couponType ,*/ loading, message, status } = useSelector(state => state.root.coupons.couponsType)
    const { discountValueTypeSelected } = watch();

    //actions

    const options = [
        {
            label: 'Activo',
            value: 'ACTIVE'
        },
        {
            label: 'Inactivo',
            value: 'INACTIVE'
        }
    ];

    //form data
    const onSubmit = (data) => {

        const newData = {
            coupon_type_name: data.couponName,
            apply_count: Number(data.numberToUses),
            discount_type: data.discountValueTypeSelected === 'percentage' ? 'PERCENTAGE' : 'ABSOLUTE_VALUE',
            discount: data.discountValue.includes('%') ? Number(data.discountValue.replace('%', '')) : Number(data.discountValue),
            coupon_type_status: data.status
        };

        dispatch(createCouponType(newData, token))

    };

    useEffect(() => {

        if (!verifiVariable(couponEdit)) {
            setValue('couponName', couponEdit.coupon_type_name ?? 'fsdf');
            setValue('numberToUses', couponEdit.apply_count ?? 0);
            setValue('discountValueTypeSelected', couponEdit.discount_type ?? 0);
            setValue('discountValue', couponEdit.discount ?? 0);
            setValue('status', couponEdit.coupon_type_status ?? '');
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [couponEdit]);

    useEffect(() => {

        if (status === 'success') {
            setQueryResult({
                type: 'success',
                title: 'Creacion exitosa',
                message: 'El tipo de cupón se ha creado correctamente',
                iconSize: 'sm'
            });
        } else if (status === 'fail') {
            if (message[0][0].err === 4621) {
                setQueryResult({
                    type: 'error',
                    title: 'Problema al crear el tipo de cupon',
                    message: 'El nombre del cupon no es valido',
                    iconSize: 'sm'
                });
            } else if (message[0][0].err === 4720) {
                setQueryResult({
                    type: 'error',
                    title: 'Problema al crear el tipo de cupón',
                    message: 'El valor de número de usos no es correcto',
                    iconSize: 'sm'
                });
            } else if (message[0][0].err === 4693) {
                setQueryResult({
                    type: 'error',
                    title: 'Problema al crear el tipo de cupón',
                    message: 'El tipo de descuento no es correcto',
                    iconSize: 'sm'
                });
            } else if (message[0][0].err === 4696) {
                setQueryResult({
                    type: 'error',
                    title: 'Problema al crear el tipo de cupón',
                    message: 'El monto de descuento no es correcto',
                    iconSize: 'sm'
                });
            } else if (message[0][0].err === 4694) {
                setQueryResult({
                    type: 'error',
                    title: 'Problema al crear el tipo de cupón',
                    message: 'El valor del estatus no es valido',
                    iconSize: 'sm'
                });
            } else if (message.error === '460') {
                setQueryResult({
                    type: 'error',
                    title: 'Problema al crear el tipo de cupón',
                    message: 'No estas autorizado para ejecutar esta acción',
                    iconSize: 'sm'
                });
            } else {
                setQueryResult({
                    type: 'error',
                    title: 'Problema al crear el tipo de cupón',
                    message: 'El tipo de cupón no se ha podido crear',
                    iconSize: 'sm'
                });
            };
        };

        const clearStateInfo = () => {
            dispatch(couponsTypeSaveInfo({
                indexe: 'loading',
                value: false
            }));
            dispatch(couponsTypeSaveInfo({
                indexe: 'status',
                value: null
            }));
            dispatch(couponsTypeSaveInfo({
                indexe: 'message',
                value: []
            }));
            setQueryResult({});
        };

        const timeOut = setTimeout(() => {
            clearStateInfo();
        }, 5000);

        return () => {
            if (timeOut) {
                clearTimeout(timeOut);
            };
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, dispatch]);

    return (
        <Box className={styles.container}>

            <Grid>
                <Box className={styles.goBack_box}>
                    <IconBack onClick={() => navegate(to.MANAGE_COUPONS_TYPE)} />
                    <Typography onClick={() => navegate(to.MANAGE_COUPONS_TYPE)} >Volver atrás</Typography>
                </Box>

                <Box className={styles.breadcrumbs_box}>
                    <Typography>Te encuentras aqui: </Typography>

                    <Paper elevation={1} className={styles.breadcrumbs_item_disabled}>
                        <IconHouseDisabled />
                        <Typography>Gestión de tipo de cupones</Typography>
                    </Paper>

                    /

                    <Paper elevation={1} className={styles.breadcrumbs_item_active}>
                        <Typography>Agregar nuevo</Typography>
                    </Paper>
                </Box>
            </Grid>

            <Typography fontSize={'1.5rem'} margin={'1.5rem 0'}>
                {couponEdit
                    ? 'Modificar tipo de cupón'
                    : 'Agregar nuevo tipo de cupón'}
            </Typography>

            <Grid className={styles.formStyles}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid item xs={12} className={styles.form_box}>
                        <Controller
                            name='couponName'
                            control={control}
                            rules={{
                                required: 'Este campo es requerido',
                                pattern: {
                                    value: validation.nombre,
                                    message: 'No están permitidos algunos caracteres especiales.'
                                },
                                maxLength: {
                                    value: 48,
                                    message: 'Máximo de 48 caracteres permitido.'
                                }
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState
                            }) => {
                                return (
                                    <FormControl
                                        fullWidth
                                        error={
                                            fieldState.error ? true : false
                                        }
                                    >
                                        <label htmlFor='couponName'>
                                            <p className={styles.form_boldText}>
                                                Nombre del tipo de cupón
                                            </p>
                                        </label>

                                        <TextField
                                            id='couponName'
                                            type='text'
                                            error={
                                                fieldState.error ? true : false
                                            }
                                            ref={ref}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                            placeholder='Ingresa nombre del tipo de cupón'
                                            color={
                                                fieldState.error ? 'error' : 'success'
                                            }
                                            focused={
                                                fieldState.isTouched || fieldState.isDirty
                                            }
                                            fullWidth
                                        />

                                        <FormHelperText
                                            hidden={
                                                fieldState.error ? false : true
                                            }
                                        >
                                            {fieldState.error ? fieldState.error.message : null}
                                        </FormHelperText>
                                    </FormControl>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} className={styles.form_box}>
                        <Controller
                            name='numberToUses'
                            control={control}
                            rules={{
                                required: 'Este campo es requerido',
                                pattern: {
                                    value: validation.number,
                                    message: 'Sólo están permitidos caracteres numéricos.'
                                },
                                maxLength: {
                                    value: 12,
                                    message: 'Máximo de 12 caracteres permitido.'
                                }
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState
                            }) => {
                                return (
                                    <FormControl
                                        fullWidth
                                        error={
                                            fieldState.error ? true : false
                                        }
                                    >

                                        <label htmlFor='numberToUses'>
                                            <p className={styles.form_boldText}>
                                                Número de uso
                                            </p>
                                        </label>

                                        <TextField
                                            id='numberToUses'
                                            type='text'
                                            error={
                                                fieldState.error ? true : false
                                            }
                                            ref={ref}
                                            onBlur={onBlur}
                                            onChange={(e) => {
                                                let formattedValue = e.target.value.replace(/[^\d]/g, '');
                                                if (formattedValue < 1) {
                                                    formattedValue = 1
                                                };
                                                setValue('numberToUses', formattedValue);
                                                onChange(e);
                                            }}
                                            value={value}
                                            placeholder='Ingresa número de usos'
                                            color={
                                                fieldState.error ? 'error' : 'success'
                                            }
                                            focused={
                                                fieldState.isTouched || fieldState.isDirty
                                            }
                                            fullWidth
                                        />

                                        <FormHelperText
                                            hidden={
                                                fieldState.error ? false : true
                                            }
                                        >
                                            {fieldState.error ? fieldState.error.message : null}
                                        </FormHelperText>
                                    </FormControl>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} className={styles.form_box}>
                        <Controller
                            name='discountValue'
                            control={control}
                            rules={{
                                required: 'Este campo es requerido',
                                /* pattern: {
                                    value: discountValueTypeSelected !== 'percentage' ? validation.number : null,
                                    message: 'Sólo están permitidos caracteres numéricos.'
                                } */
                                /* maxLength: {
                                    value: 12,
                                    message: 'Máximo de 12 caracteres permitido.'
                                } */
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState
                            }) => {
                                return (
                                    <FormControl
                                        fullWidth
                                        error={
                                            fieldState.error ? true : false
                                        }
                                    >
                                        <Box>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                defaultValue={(couponEdit && couponEdit['discountValueTypeSelect']) ? couponEdit['discountValueTypeSelect'] : 'percentage'}
                                                onChange={(e) => { setValue('discountValue', '') }}
                                            >
                                                <Box className={styles.form_radioBox}>

                                                    <label htmlFor='discountValue'>
                                                        <p className={styles.form_boldText}>
                                                            Valor tipo de descuento
                                                        </p>
                                                    </label>

                                                    <FormControlLabel
                                                        value="percentage"
                                                        control={<Radio style={{ color: theme.palette.primary.light }} />}
                                                        label="Porcentaje"
                                                        {...register("discountValueTypeSelected", {
                                                            required: 'Debes seleccionar una opción'
                                                        })}
                                                    />
                                                    <FormControlLabel
                                                        value="absolute"
                                                        control={<Radio style={{ color: theme.palette.primary.light }} />}
                                                        label="Valor Absoluto"
                                                        {...register("discountValueTypeSelected", {
                                                            required: 'Debes seleccionar una opción'
                                                        })}
                                                    />
                                                </Box>
                                            </RadioGroup>
                                        </Box>

                                        <TextField
                                            id='discountValue'
                                            type='text'
                                            error={
                                                fieldState.error ? true : false
                                            }
                                            ref={ref}
                                            onBlur={onBlur}
                                            onChange={(e) => {
                                                let formattedValue = e.target.value.replace(/[^\d,.]/g, '');
                                                if (isNaN(formattedValue) || formattedValue < 1) {
                                                    formattedValue = 1
                                                }
                                                if (discountValueTypeSelected === 'percentage' && formattedValue > 100) {
                                                    formattedValue = 100
                                                }
                                                onChange(formattedValue);
                                                setValue('discountValue', discountValueTypeSelected === 'percentage' ? `${formattedValue}%` : formattedValue);
                                            }}
                                            value={value}
                                            placeholder='Ingresa monto'
                                            color={
                                                fieldState.error ? 'error' : 'success'
                                            }
                                            focused={
                                                fieldState.isTouched || fieldState.isDirty
                                            }
                                            fullWidth
                                        />

                                        <FormHelperText
                                            hidden={
                                                fieldState.error ? false : true
                                            }
                                        >
                                            {fieldState.error ? fieldState.error.message : null}
                                        </FormHelperText>
                                    </FormControl>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} className={styles.form_box}>
                        <Controller
                            name="status"
                            control={control}
                            rules={{
                                required: 'Este campo es requerido',
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState
                            }) => (
                                <FormControl
                                    fullWidth
                                    error={!!fieldState.error}
                                >
                                    <label htmlFor='country-customized-option-demo'>
                                        <p className={styles.form_boldText}>
                                            Estatus
                                        </p>
                                    </label>

                                    <Autocomplete
                                        id="country-customized-option-demo"
                                        options={options}
                                        getOptionLabel={(option) => option.label}
                                        value={options.find(option => option.value === value) || null}
                                        onChange={(event, newValue) => {
                                            onChange(newValue ? newValue.value : '');
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Selecciona una opción"
                                                error={!!fieldState.error}
                                            />
                                        )}
                                    />

                                    <FormHelperText
                                        hidden={
                                            fieldState.error ? false : true
                                        }
                                    >
                                        {fieldState.error ? fieldState.error.message : null}
                                    </FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Grid>


                    <CustomButton type='submit'>
                        {loading ?
                            <CircularProgress color='white' size={20} /> :
                            couponEdit ? 'Modificar' : 'Guardar'}
                    </CustomButton>
                </form>

            </Grid>

            {queryResult?.type && (
                <InfoMessage
                    type={queryResult.type}
                    title={queryResult.title}
                    message={queryResult.message}
                    iconSize={queryResult.iconSize}
                />
            )}

        </Box>
    )
}

export default AddCouponsType