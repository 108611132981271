import React, { useRef, useState } from 'react';

import { Divider, Grid, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import { theme } from '../../theme/theme';
import { useDispatch, useSelector } from 'react-redux';
import { saveTramitExpressInfo } from '../../store/formExpressTramit';
import { saveTramitANFInfo } from '../../store/formANFTramit';
import { saveFormInfo } from '../../store/formTramit';
import { validation } from '../../utils/validations';

const styles = {
    spacinContent: {
        margin: '25px 45px 25px 45px'
    },
    title: {
        color: theme.palette.black.main,
        fontFamily: "PoppinsBold",
        fontSize: "0.95rem",
    },
    divider: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    labelStyles: {
        fontWeight: "500",
        marginBottom: "0",
        fontSize: "0.95rem",
        color: theme.palette.black.main,
    },
};

const ContractName = ({ signatureType }) => {

    const inputRef = useRef();
    const dispatch = useDispatch();
    const { contractName: contractNameExpress } = useSelector(state => state.root.formExpressTramit);
    const { contractName: contractNameANF } = useSelector(state => state.root.formANFTramit);
    const { contractName: contractNameApprovers } = useSelector(state => state.root.formTramit);
    const [isError, setIsError] = useState(false);
    const [textError, setTextError] = useState('');

    const handleInputChange = (e) => {
        setIsError(false);
        const { value } = e.target;        

        if(value.length >= 50) {
            setTextError('La longitud máxima es de 50 caracteres');
            setIsError(true);
        }

        if (!validation.general.test(value)) {            
            setTextError('No está permitido utilizar ciertos caracteres especiales. Carcateres especiales permitidos "-_#()@:?$+= áéíóúÁÉÍÓÚñÑüÜ.,!"');
            setIsError(true);
        };

        let filteredValue = value.split('').filter(char => validation.general.test(char)).join('');

        if( value.length >= 50 ) filteredValue = value.slice(0, 50);

        if (inputRef.current) {

            inputRef.current.value = filteredValue;

            if (signatureType === 'express') {
                dispatch(saveTramitExpressInfo({
                    indexe: 'contractName',
                    value: filteredValue.trim()
                }));
            };
            if (signatureType === 'anf') {
                dispatch(saveTramitANFInfo({
                    indexe: 'contractName',
                    value: filteredValue.trim()
                }));
            };
            if (signatureType === 'approvers') {
                dispatch(saveFormInfo({
                    indexe: 'contractName',
                    value: filteredValue.trim()
                }));
            };
        };
    };

    const getDefaultValue = () => {
        if (signatureType === 'express') {
            return contractNameExpress || ''
        } else if (signatureType === 'anf') {
            return contractNameANF || ''
        } else if (signatureType === 'approvers') {
            return contractNameApprovers || ''
        } else {
            return ''
        };
    };

    return (
        <Grid item xs={12}  >

            <Divider sx={signatureType === 'approvers' ? {} : { display: 'none' }} />

            <Grid sx={signatureType === 'approvers' ? { margin: '25px 0' } : styles.spacinContent}>
                <label htmlFor='contractName'>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={signatureType === 'approvers' ? styles.labelStyles : styles.title}
                    >
                        Nombre del trámite (opcional)
                    </Typography>
                </label>

                <FormControl error={isError} variant="standard" style={{ width: '100%' }} >

                    <TextField
                        id='contractName'
                        type="text"
                        inputRef={inputRef}
                        onChange={handleInputChange}
                        defaultValue={getDefaultValue()}
                        placeholder={"Ingresa nombre del tramite"}
                        pattern={validation.general}
                        fullWidth
                    />

                    <FormHelperText
                        hidden={!isError}
                        id="component-error-text"
                    >
                        {textError}
                    </FormHelperText>

                </FormControl>
            </Grid>

            <Divider sx={signatureType === 'approvers' ? { display: 'none' } : {}} />

        </Grid>
    )
}

export default ContractName