import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { BiRightArrowAlt } from "react-icons/bi";

import { motion } from "framer-motion";

import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import {
    clearFormSolicitud,
    errorStepFormSolicitud,
    saveInfoFormSolicitud,
} from "../../../../store/formSolicitud/formSolicitud";
import { FormularioSolicitudStyles } from "../../index.css";
import { signaturesType } from "../../../../placeholder/contracts";
import { endSession } from "../../../../store/auth/userLogin";
import { purgeSigners } from "../../../../store/contract/signatoriesList";

const StepSeven = ({ control, watch, trigger, setValue }) => {
    const styles = FormularioSolicitudStyles();
    const {
        contract: {
            contractCreator: { contractList },
        },
        formSolicitud: {
            iContractTypeID,
            iSignedCount,
            procedureid,
            proceduretype,
        },
    } = useSelector((state) => state.root);
    const dispatch = useDispatch();
    const [signaturesQuery, setSignaturesQuery] = useState([]);
    const refFirstLoad = useRef({});
    const [errorInput, setErrorInput] = useState(undefined);
    const [firmTypeList, setFirmTypeList] = useState([]);

    const watchIContractTypeID = watch("iContractTypeID");

    const clearSteps = () => {
        endSession();
        dispatch(purgeSigners());
        dispatch(clearFormSolicitud());
    };

    const setIContractTypeFeeID = (item) => {
        switch (item["value"]) {
            case "(ANF)":
                clearSteps();
                window.location.replace("https://notaria.firmavirtual.com");
                break;

            case "No lo sé":
                clearSteps();
                window.location.replace(
                    "https://api.whatsapp.com/send?phone=+56945227304"
                );
                break;

            default:
                dispatch(
                    saveInfoFormSolicitud({
                        indexe: "iContractTypeFeeID",
                        value: item["iContractTypeFeeID"],
                    })
                );
                break;
        }
    };

    const signaturesInputChoiceOptions = () => {
        const results = [];
        // eslint-disable-next-line
        signaturesType.map((signature) => {
            // eslint-disable-next-line
            signaturesQuery.map((option, index) => {
                if (option.sModal === signature.value) {
                    results.push({
                        label: signature.name,
                        value: signature.name,
                        iContractTypeFeeID: option["iContractTypeFeeID"],
                    });
                }
            });
        });

        results.push({
            label: "Máxima Seguridad Legal Digital: Autorización Notarial de Firmas(ANF) (por el momento sólo jurisdicción de Santiago - varias comunas)",
            value: "(ANF)",
        });

        results.push({
            label: "No lo sé, necesito que me contacten",
            value: "No lo sé",
        });

        return results;
    };

    useEffect(() => {
        if (![undefined].includes(errorInput)) {
            dispatch(errorStepFormSolicitud(true));
        } else {
            dispatch(errorStepFormSolicitud(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorInput]);

    // eslint-disable-next-line
    useEffect(async () => {
        try {
            if (
                !["", null, undefined].includes(contractList) &&
                typeof contractList === "object" &&
                contractList["message"].length
            ) {
                const realStateContractsTypes = contractList["message"].filter(
                    (contract) => {
                        let ret;

                        if (
                            contract.sContractType === proceduretype["value"] &&
                            contract.sContractTypeName === procedureid["value"]
                        ) {
                            ret = contract;
                        }

                        return ret;
                    }
                );

                const getContractsByCounter = realStateContractsTypes.filter(
                    (contract) => {
                        if (Number(iSignedCount) > 1) {
                            return contract.iSignCount === 2;
                        } else {
                            return contract.iSignCount === 1;
                        }
                    }
                );

                setSignaturesQuery(getContractsByCounter);
            } else {
                console.log("nada");
            }
        } catch (error) {
            console.log("error: ", error);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedureid, proceduretype]);

    useEffect(() => {
        if (signaturesQuery.length) {
            const arrayList = signaturesInputChoiceOptions();

            setFirmTypeList(arrayList);
        }
        // setValue("iContractTypeID", iContractTypeID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signaturesQuery]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iContractTypeID]);

    refFirstLoad.current = false;

    useEffect(() => {
        trigger();

        if (
            !["", null, undefined].includes(watchIContractTypeID) &&
            watchIContractTypeID !== iContractTypeID
        )
            dispatch(
                saveInfoFormSolicitud({
                    indexe: "iContractTypeID",
                    value: watchIContractTypeID,
                })
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchIContractTypeID]);

    return (
        <div className={styles.stepBox}>
            <motion.div
                initial={{ y: "50vh" }}
                animate={{ y: 0 }}
                transition={{ stiffness: 150 }}
            >
                <Grid container xs={12}>
                    <Grid item xs={1} className={styles.boxIndetify}>
                        7<BiRightArrowAlt />
                    </Grid>

                    <Grid item xs={11}>
                        <Typography
                            variant="h4"
                            className={styles.cssTexto1}
                            gutterBottom
                        >
                            ¿Qué tipo de firma necesitas? *
                        </Typography>

                        <Controller
                            name="iContractTypeID"
                            control={control}
                            rules={{
                                required: "Debe elegir una opción",
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                setErrorInput(fieldState.error);
                                return (
                                    <FormControl
                                        fullWidth
                                        error={fieldState.error ? true : false}
                                    >
                                        <RadioGroup
                                            onBlur={onBlur}
                                            ref={ref}
                                            value={value}
                                            onChange={onChange}
                                        >
                                            {firmTypeList.map((item) => {
                                                return (
                                                    <FormControlLabel
                                                        key={item["label"]}
                                                        value={item["value"]}
                                                        onClick={() =>
                                                            setIContractTypeFeeID(
                                                                item
                                                            )
                                                        }
                                                        control={<Radio />}
                                                        label={item["label"]}
                                                    />
                                                );
                                            })}
                                        </RadioGroup>

                                        <FormHelperText
                                            hidden={
                                                fieldState.error ? false : true
                                            }
                                        >
                                            {fieldState.error
                                                ? fieldState.error.message
                                                : null}
                                        </FormHelperText>
                                    </FormControl>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </motion.div>
        </div>
    );
};

export default StepSeven;
