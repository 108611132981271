import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';

import { FirmaVirtualAvanzadaStyles } from "../../FirmaVirtualAvanzadaStyles.css";
import {
    claveUnicaAccessToken,
    claveUnicaDatosUsuario,
    claveUnicaToken,
    urlAuthentication,
} from "../../../../../utils/api-fva";
import {
    addClvUnicaAccessToken,
    addClvUnicaDatosUsuario,
    addClvUnicaToken,
    addClvUnicaUrlAuth,
    clearAll,
} from "../../../../../store/fvavanzada/fvavanzada";
import Spinner from "../../../../spinners/Spinner";
import DatosUsuario from "../datosUsuario";
import { textErrorAsync } from "../../../../../utils/validations";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import ModalAlert from "../../../../floats/ModalAlert";


const Step1 = () => {
    const {
        fvavanzada: {
            clvUnicaUrlAutenticacion,
            clvUnicaObtenerToken,
            clvUnicaAccessToken,
        },
    } = useSelector((state) => state.root);
    const dispatch = useDispatch();
    const styles = FirmaVirtualAvanzadaStyles();
    const screenSizeHook = ScreenSizeHook();
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [textLoading, setTextLoading] = useState("");
    const [urlLoginClvUnica, setUrlLoginClvUnica] = useState("");

    const [showModal, setShowModal] = useState(false);
    const [modalStatus, setModalStatus] = useState({});

    const getTokenAndUrlAuth = async () => {
        try {
            setTextLoading(
                "Preparando inicio de sessión de firma virtual avanzada...Por favor espere"
            );

            setLoading(true);

            let stringUrl = "";

            const restClaveToken = await claveUnicaToken();

            dispatch(addClvUnicaToken(restClaveToken));

            const restUrlAuth = await urlAuthentication(restClaveToken["code"]);

            dispatch(addClvUnicaUrlAuth(restUrlAuth));

            stringUrl = restUrlAuth["url"].replace(
                ":code",
                restClaveToken["code"]
            );

            setCode(restClaveToken["code"]);

            window.open(stringUrl, "_blank"); // , "toolbar=1, scrollbars=1, resizable=1, width=" + 1015 + ", height=" + 800

            setUrlLoginClvUnica(stringUrl);

            setLoading(false);
        } catch (error) {
            dispatch(addClvUnicaUrlAuth(null));
            dispatch(addClvUnicaToken(null));
            setLoading(false);

            setModalStatus({
                title: "¡Ups!, Algo ha pasado",
                message: "Ah ocurrido un error al realizar su peticion, por favor intente nuevamente o comunícate con un administrador del sistema",
                buttonText: "Cerrar",
                modalIcon: 'warnIcon'
            });
            setShowModal(true);

            console.log("error", error);
        }
    };

    const getAccessToken = async () => {
        try {
            setTextLoading(
                "Cargando informacion de usuario...Por favor espere"
            );

            setLoading(true);

            const restClavUniAccessToken = await claveUnicaAccessToken(code);

            if (
                ["", null, undefined].includes(
                    restClavUniAccessToken["accessToken"]
                )
            ) {

                setModalStatus({
                    title: "¡Ups!, Algo ha pasado",
                    message: "Ah ocurrido un error al realizar su peticion, por favor intente nuevamente o comunícate con un administrador del sistema",
                    buttonText: "Cerrar",
                    modalIcon: 'warnIcon'
                });
                setShowModal(true);

                window.open(urlLoginClvUnica, "_blank");
                setLoading(false);
                return;
            }

            dispatch(addClvUnicaAccessToken(restClavUniAccessToken));

            const restDatosUsuario = await claveUnicaDatosUsuario({
                code,
                accessToken: restClavUniAccessToken["accessToken"],
            });

            dispatch(addClvUnicaDatosUsuario(Object.assign(restDatosUsuario, { formData: undefined })));

            setModalStatus({
                title: "¡Datos cargados exitosamente!",
                message: "Porfavor recuerde completar su información de usuario",
                buttonText: "Cerrar",
                modalIcon: 'successIcon'
            });
            setShowModal(true);

            setLoading(false);
        } catch (error) {
            dispatch(addClvUnicaDatosUsuario(null));
            dispatch(addClvUnicaAccessToken(null));
            setLoading(false);

            setModalStatus({
                title: "¡Ups!, Algo ha pasado",
                message: textErrorAsync,
                buttonText: "Cerrar",
                modalIcon: 'warnIcon'
            });
            setShowModal(true);

            console.log("error", error);
        }
    };

    const cerrarSessionClvUnica = () => {
        window.open('https://api.claveunica.gob.cl/api/v1/accounts/app/logout', "_blank");
        dispatch(clearAll());
    }

    const handleModal = () => {
        setShowModal(!showModal);
        setModalStatus({
            title: "",
            message: "",
            buttonText: "",
            modalIcon: ""
        });
    };


    return (
        <Grid container xs={12} className={styles.stepsContent}>

            <ModalAlert
                buttonText={modalStatus.buttonText}
                modalIcon={modalStatus.modalIcon}
                onAcept={handleModal}
                openModal={showModal}
                setOpenModal={setShowModal}
                subTitle={modalStatus.message}
                title={modalStatus.title}
            />

            <br />

            <Grid xs={12} sm={7} xl={4} className={styles.contentLoginFVA}>
                {loading && (
                    <Spinner
                        loading={true}
                        type="bar"
                        text={textLoading}
                        opacity={true}
                    />
                )}

                <Typography
                    variant="h3"
                    sx={{
                        fontWeight: "bold",
                        color: "#646b6f",
                        fontSize: ['xs', 'sm'].includes(screenSizeHook) ? '1.7rem' : '3rem'
                    }}
                    gutterBottom
                    component="strong"
                >
                    Iniciar sesión con clave única
                </Typography>

                <Typography variant="body1" gutterBottom>
                    Inicia sesión con clave única y autentica tu identidad para obtener tu Firma Personal Electronica Avanzada.
                </Typography>

                <br />

                {([null, undefined].includes(clvUnicaUrlAutenticacion) && [null, undefined].includes(clvUnicaObtenerToken)) && (<Button
                    variant="contained"
                    color="primary"
                    onClick={() => getTokenAndUrlAuth()}
                >
                    Ingresar
                </Button>)}

                {(![null, undefined].includes(clvUnicaUrlAutenticacion) && ![null, undefined].includes(clvUnicaObtenerToken)) && (<Button
                    variant="contained"
                    color="primary"
                    onClick={() => cerrarSessionClvUnica()}
                >
                    Cerrar Sesión
                </Button>)}

            </Grid>

            <br />
            <br />

            {clvUnicaObtenerToken !== null &&
                clvUnicaUrlAutenticacion !== null && (
                    <React.Fragment>
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={() => getAccessToken()}
                        >
                            Cargue su información
                        </Button>
                        <br />
                        <Typography variant="body2" gutterBottom sx={{ /* width: 'auto',  */textAlign: 'center' }}>
                            Cargue su informacion de usuario al momento de realizar el inicio de sesion en clave unica.
                        </Typography>
                    </React.Fragment>
                )}

            <br />

            {clvUnicaObtenerToken !== null &&
                clvUnicaUrlAutenticacion !== null &&
                ![null].includes(clvUnicaAccessToken) &&
                ![null, ""].includes(clvUnicaAccessToken["accessToken"]) && (
                    <DatosUsuario />
                )}

            <br />

            <Button
                variant="contained"
                color="inherit"
                onClick={() => cerrarSessionClvUnica()}
            >
                Borrar Datos
            </Button>

        </Grid>
    );
};

export default Step1;
