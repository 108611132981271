import { Fragment, forwardRef, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FaCopy } from "react-icons/fa";
import { IoArrowRedo } from "react-icons/io5";
import { FaCloudArrowDown } from "react-icons/fa6";
import { BsEnvelope } from "react-icons/bs";
import { BiCloudUpload } from "react-icons/bi";

import NumberFormat from 'react-number-format';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import useMediaQuery from '@mui/material/useMediaQuery';

import Tracker from "../molecules/tracker";
import Contact from "../molecules/contact";

import { ReactComponent as ResetIcon } from "../icons/reset-icon.svg";
import { ReactComponent as TrashIcon } from "../icons/trash-icon.svg";
import { ReactComponent as UpdateIcon } from "../icons/update-icon.svg";
import { ReactComponent as PayIcon } from "../icons/pay-icon.svg";
import { ReactComponent as UserIcon } from "../icons/user-icon.svg";
import { ReactComponent as SignedIcon } from "../icons/signed-icon.svg";
import { ReactComponent as UnsignedIcon } from "../icons/unsigned-icon.svg";
import { ReactComponent as ClvUnicaAceptedIcon } from "../icons/clv-unica-acepted-icon.svg";
import { ReactComponent as ClvUnicaUnaceptedIcon } from "../icons/clv-unica-unacepted-icon.svg";
import { ReactComponent as WhatsAppIcon } from "../../../../assets/icons/whatsApp-icon.svg";
import { ReactComponent as DownloadOutlineDetailIcon } from "../../../../assets/icons/icon_download_outline_detail_tramit.svg";

import ScreenSizeHook from "../../../../hooks/ScreenSizeHook";
import { SignAuthorizationStyles } from "../index.css";
import VideoVerifyModal from "../molecules/videoVerifyModal";
import {
    emailClaveUnica,
    getDetailTramit,
    getDetailTramitFromExpress,
} from "../../../../store/formANFTramit";
import { api, copyText, getErrorMsnWs, to, typeUser, verifiVariable } from "../../../../utils/enums";
import ViewModalDocs from "../molecules/viewModalDocs";
import {
    //downloadCompiledContractDoc,
    downloadCompiledDTE,
    resetContractApi,
    sendEmailSign,
    sendSignerEmail,
} from "../../../../utils/api";
import Spinner from "../../../spinners/Spinner";
import ErrorAlert from "../molecules/errorAlert";
import DeleteContractAlert from "../molecules/deleteContractAlert";
import Btn from "../../../buttons/Button";
import ModalUploadNotaryDoc from "../../../modalUploadNotaryDoc/ModalUploadNotaryDoc";
import { Container } from "@mui/material";
import TramitDetail from "../../../tramitDetailData/TramitDetail/TramitDetail";
import DocumentInfo from "../../../tramitDetailData/documentInfo/DocumentInfo";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomWhatsAppButton = styled(Button)((props) => ({
    width: '100%',
    height: '2.5rem',
    textTransform: 'none',
    backgroundColor: '#32c143',
    color: "white",
    fontSize: '.6rem',
    borderRadius: '7px',
    marginTop: '1rem',
    fontFamily: "PoppinsRegular",
    /* padding: '0 8rem', */
    whiteSpace: 'nowrap',
    '&:hover': {
        backgroundColor: '#32c143'
    },
    "& svg.icon-buttom": {
        marginBottom: "0px !important",
        marginTop: "0px !important"
    }
}));

const DownloadDTEButton = styled(Button)((props) => ({
    width: '1rem',
    height: '3.125rem',
    alignSelf: "flex-end",
    marginTop: "10px",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "0.80rem",
    padding: "0px 0px",
    border: "1px solid",
    borderRadius: "7px",
    lineHeight: 1.5,
    backgroundColor: props.theme.palette.primary.light,
    // backgroundColor: '#0063cc',
    borderColor: props.theme.palette.primary.light,
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        // backgroundColor: '#0069d9',
        backgroundColor: props.theme.palette.primary.xlight,
        // borderColor: '#0062cc',
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

const CustomActionButton = styled(Button)((props) => ({
    width: '1rem',
    height: '3.125rem',
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "0.80rem",
    borderRadius: "7px",
    padding: '.813rem 1.25rem',
    backgroundColor: props.theme.palette.primary.light,
    borderColor: props.theme.palette.primary.light,
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        backgroundColor: props.theme.palette.primary.xlight,
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
    "& span.MuiButton-startIcon": {
        color: props.theme.palette.white.main,
        marginRight: "0px",
        marginLeft: "0px",
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    color: theme.palette.text.secondary,
}));

const ANFDetailProcedure = () => {
    const {
        formANFTramit: {
            document,
            detailContract: { firmantes, owner, contrato, archivos },
            detailContractFromExpress: {
                archivos: archivosFromExpress,
                firmantes: firmantesFromExpress,
                contrato: contratoExpress,
            },
            loading,
            //tramitANFCreated
        },
        session: {
            login: { user, userInfo },
        },
    } = useSelector((state) => state.root);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const Ref = useRef(null);
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const screenSizeHook = ScreenSizeHook();
    const styles = SignAuthorizationStyles({ document, screenSizeHook });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [loadingReq, setLoadingReq] = useState(false);
    const [textLoading, setTextLoading] = useState("");
    const [errorAlert, setErrorAlert] = useState(0);
    const [deleteContract, setDeleteContract] = useState(0);
    const [isAllUpload, setIsAllUpload] = useState(true);
    const [titleErrorAlert, setTitleErrorAlert] = useState(undefined);
    const [msnErrorAlert, setMsnErrorAlert] = useState(undefined);
    const [textSnackbar, setTextSnackbar] = useState("");
    const [open, setOpen] = useState(false);
    const [loadingReminder, setLoadingReminder] = useState(false);
    const [openModalNotary, setOpenModalNotary] = useState(false);
    const [verifyStatusContract, setVerifyStatusContract] = useState(false);
    const [timer, setTimer] = useState('00:00:00');
    const [docLog, setDocLog] = useState(null);
    const [openModalDocument, setOpenModalDocument] = useState({
        openModal: false,
        tramitType: '',
        download: false
    });
    const [payInfo, setPayInfo] = useState(undefined);

    const CustomTextTitle = styled(Typography)((props) => ({
        color: props.theme.palette.black.main,
        fontSize: screenSizeHook === "xs" ? "1rem" : "1.2rem",
        marginBottom: screenSizeHook === "xs" ? "0px" : "0.35em",
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [props.theme.breakpoints.up('lg')]: {
            maxWidth: '15rem',
        },
    }));

    const urlPay = (tokenPayment, sContractID) => {
        return `${api.BASE_URI}/webpay/create/${tokenPayment}/${sContractID}`;
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const ModalNotary = () => {
        const { token } = user;

        return (
            <ModalUploadNotaryDoc
                contract={state["sContractID"]}
                userToken={token}
                inList={true}
                openModalNotary={openModalNotary}
                setOpenModalNotary={setOpenModalNotary}
                hideButoomsModal={true}
                tramitType={'anf'}
            />
        );
    };

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, hours, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {

            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }

    const clearTimer = (e) => {

        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next    
        setTimer('00:00:60');

        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 60);
        return deadline;
    }

    const handleClickSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleShowDocument = (open, tramitType, download) => {
        setOpenModalDocument({
            openModal: open,
            tramitType: tramitType,
            download: download
        });
    };

    const resentUniqClv = (signerID) => {
        const { token } = user;

        dispatch(emailClaveUnica({ signerID }, token));

        setTextSnackbar("Correo de clave unica enviado.");
        handleClickSnackbar();
    };

    const dataUpdate = () => {
        const { token } = user;
        setTextLoading("Actualizando datos. Por favor, espere.");
        dispatch(getDetailTramit(token, state["sContractID"]));
        dispatch(getDetailTramitFromExpress(token, state["sContractID"]));
    };

    const descargarDTE = async (email, sContractID) => {
        try {
            const { token } = user;

            const {
                data: { status },
            } = await downloadCompiledDTE(sContractID, token, email);

            if (status === "fail") {
                setTitleErrorAlert("Archivo no disponible.");
                setMsnErrorAlert(
                    "No se puede descargar el archivo porque no existe."
                );

                setErrorAlert((e) => 1 + e);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const sendReminder = async () => {
        try {
            setLoadingReminder(true);

            const { token } = user;

            setTextLoading("Enviado recordatorio a todos los firmantes");

            setTextSnackbar(
                "Se ha enviado un recordatorio a todos los firmantes"
            );

            const { status: sendEmailSignStatus } = await sendEmailSign(
                state["sContractID"],
                token
            );

            if ([sendEmailSignStatus].includes("fail")) {
                setTitleErrorAlert(
                    "Ha surgido un problema al reenviar el contrato."
                );
                setMsnErrorAlert(
                    "Podrías intentar nuevamente o comunícate con un administrador del sistema."
                );

                setErrorAlert((e) => 1 + e);
            } else {
                dataUpdate();
                handleClickSnackbar();
            }

            setLoadingReminder(false);
        } catch (error) {
            setTitleErrorAlert(
                "Ha surgido un problema al reenviar el contrato."
            );
            setMsnErrorAlert(
                "Podrías intentar nuevamente o comunícate con un administrador del sistema."
            );

            setLoadingReminder(false);
            setErrorAlert((e) => 1 + e);
            console.log(error);
        }
    };

    const handleChangeAppBarBottom = (event, value) => {

        if (value === "Actualizar") dataUpdate();

        if (value === "Eliminar") setDeleteContract((value) => 1 + value);
    };

    const setEmailSigner = async (email) => {

        try {

            setTextLoading("Enviando WhatsApp. El mensaje puede tardar unos minutos en llegar según la demanda. Gracias por tu paciencia.");

            setLoadingReq(true);

            setTextSnackbar(
                "Se ha enviado un recordatorio a todos los firmantes"
            );

            const { token } = user;

            const response = await sendSignerEmail(token, { email, sContractID: state["sContractID"] });

            if (response["status"] === "fail") {
                setTitleErrorAlert("Problemas al enviar WhatsApp.");
                setMsnErrorAlert(getErrorMsnWs(response["message"]["err"]));
                setErrorAlert((value) => 1 + value);
            } else {
                handleClickSnackbar();
                clearTimer(getDeadTime());
            }

            setLoadingReq(false);

        } catch (error) {
            setLoadingReq(false);

            setTitleErrorAlert("Problemas al enviar WhatsApp.");
            setMsnErrorAlert("Podrías intentar nuevamente o comunícate con un administrador del sistema.");
            setErrorAlert((value) => 1 + value);
        }

    }

    const resetContract = async () => {
        try {
            setTextLoading("Restaurando trámite. Por favor, espere.");
            setLoadingReq(true);

            const { token } = user;

            const { status, message } = await resetContractApi(
                state["sContractID"],
                token
            );

            if (status === "fail") {
                setTitleErrorAlert("Ha ocurrido un problema");
                setMsnErrorAlert(message["msg"]);
                setErrorAlert((value) => 1 + value);
            }

            setLoadingReq(false);

            dataUpdate();
        } catch (error) {
            setLoadingReq(false);
            console.log(error);
        }
    };

    /* const downloadContract = async () => {
        try {
            console.log({ contratoExpress })
            const { token } = user;

            await downloadCompiledContractDoc(
                contrato["contractID"],
                token,
                contrato["status"]
            );

        } catch (error) {
            console.log(error);
        }
    }; */

    useEffect(() => {

        if (!verifiVariable(firmantesFromExpress) && firmantesFromExpress.length !== 0) {

            let total = 0;
            let iva = 0;
            let notary = 0;
            let signature = 0;

            // setDetailContract(contractCreatedDetails);

            // setApproveSigner(contractCreatedDetails['firmantes'].map((item) => item['agree_draft']));

            firmantesFromExpress.forEach((firmante) => {
                if (firmante.portion !== 0) {
                    total += parseInt(firmante.totalPay);
                    iva += parseInt(firmante.iva);
                    notary += parseInt(firmante.notaryPay);
                    signature += parseInt(firmante.amount);
                }
            });

            setPayInfo({
                total,
                iva,
                notary,
                signature,
            });

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firmantesFromExpress]);

    useEffect(() => {
        if (!verifiVariable(state) && !verifiVariable(state["sContractID"])) {
            dataUpdate();
        } else {
            navigate(to.DASHBOARD_WELCOME);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        if (
            !verifiVariable(firmantesFromExpress) &&
            !verifiVariable(firmantes)
        ) {
            setParticipants(
                [...firmantes].map((item) => {
                    const sign = firmantesFromExpress.find(
                        (element) => element["RUT"] === item["rutId"]
                    );

                    return Object.assign({ ...item }, { expressData: sign });
                })
            );
        }
    }, [firmantes, firmantesFromExpress]);

    useEffect(() => {

        if (!verifiVariable(firmantes) && !verifiVariable(contrato)) {

            const arraySignersUploads = [...firmantes].filter((item) => !["PENDING", "NOTHING"].includes(item["signStatus"]));

            if (arraySignersUploads.length === 0 && ["CREATED", "REVIEW"].includes(contrato["status"])) {
                setIsAllUpload(true);
            } else {
                setIsAllUpload(false);
            }
        }

    }, [firmantes, contrato]);

    useEffect(() => {

        if (!verifiVariable(firmantes) && !verifiVariable(contrato)) {

            const awaitSigners = [...firmantes].filter((item) => item["authNeed"] === "1" && item["rol"] === "0" && ["AUTHENTICATED", "PENDING"].includes(item["signStatus"]));

            if (contrato["status"] === "REJECTED") {
                setVerifyStatusContract(contrato["status"]);
            } else if (awaitSigners.length > 0) {
                setVerifyStatusContract("REVIEW");
            } else {
                setVerifyStatusContract(contrato["status"]);
            }

        }

    }, [firmantes, contrato]);

    /* useEffect(() => {

        dispatch(saveTramitANFInfo({ indexe: "status", value: null }));

        console.log("status: ", status)
        console.log("loading: ", loading)

        if (loading === false && verifiVariable(contrato)) {
            invalidContract.current = true;
            setTitleErrorAlert("Trámite no encontrado");
            setMsnErrorAlert("El trámite ha sido eliminado o no existe.");
            setErrorAlert((value) => 1 + value);
        }

        return () => {
            dispatch(saveTramitANFInfo({ indexe: "status", value: null }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contrato, loading]); */

    return (
        <Container maxWidth={'xl'}>
            <Box className={styles.mainContent}>

                <ViewModalDocs
                    docLog={docLog}
                    openModal={openModalDocument.openModal}
                    setOpenModal={setOpenModalDocument}
                    download={openModalDocument.download}
                    tramitType={openModalDocument.tramitType}
                />

                <ErrorAlert
                    errors={errorAlert}
                    msn={msnErrorAlert}
                    title={titleErrorAlert}
                />

                {!verifiVariable(state) && !verifiVariable(state["sContractID"]) && (<ModalNotary />)}

                {(loading || loadingReq || loadingReminder) && (
                    <Spinner
                        loading={true}
                        type="bar"
                        text={textLoading}
                        opacity={true}
                    />
                )}
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{
                        horizontal: "center",
                        vertical: "bottom",
                    }}
                >
                    <Alert
                        className={styles.muiAlert}
                        onClose={handleCloseSnackbar}
                        severity="success"
                    >
                        {textSnackbar}
                    </Alert>
                </Snackbar>

                <Dialog
                    fullWidth={true}
                    TransitionComponent={Transition}
                    open={open}
                    onClose={handleClose}
                    maxWidth="xs"
                    className={styles.mainContainerDialog}
                >
                    <DialogTitle sx={{ textAlign: "center" }}>
                        ¿Estás seguro que deseas realizar esta acción?
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            Todos los firmantes recibiran nuevamente el correo de
                            firma.
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions
                        style={{ justifyContent: "center", paddingBottom: "20px" }}
                    >
                        <Btn
                            variant="outlined"
                            text="Cancelar"
                            color="error"
                            onClick={() => handleClose()}
                            sx={{
                                height: "39px",
                                borderRadius: "20px",
                            }}
                        />

                        <Btn
                            variant="outlined"
                            text="Aceptar"
                            color="primary"
                            onClick={() => {
                                sendReminder();
                                handleClose();
                            }}
                            sx={{
                                height: "39px",
                                borderRadius: "20px",
                                marginLeft: "30px !important",
                            }}
                        />
                    </DialogActions>
                </Dialog>

                <Grid container spacing={2}>
                    {!verifiVariable(contrato) &&
                        isMobile && (
                            <Grid item xs={12}>
                                <div
                                    className={`${styles.contentTracker} content-detail__container`}
                                >
                                    <Tracker
                                        showGoback={false}
                                        status={contrato["status"]}
                                        paymentStatus={contrato["paymentStatus"]}
                                    />
                                </div>
                            </Grid>
                        )
                    }

                    {!verifiVariable(contrato) &&
                        !isMobile && (
                            <Grid item xs={12} sm={8}>
                                <div
                                    className={`${styles.contentTracker} content-detail__container`}
                                >
                                    <Tracker
                                        showGoback={false}
                                        status={verifyStatusContract}
                                        paymentStatus={contrato["paymentStatus"]}
                                    />
                                </div>

                                <Item
                                    className={`${styles.contenItem} ${styles.formItem}`}
                                >
                                    <Box className={styles.spacinContent}>
                                        <Typography
                                            variant="h5"
                                            gutterBottom
                                            className={styles.titleDFV}
                                        >
                                            Bienvenido al servicio de firma
                                            electrónica con autorización notarial de
                                            firmas
                                        </Typography>

                                        <div className={styles.optionsTramit}>
                                            <Button
                                                variant="outlined"
                                                startIcon={<ResetIcon />}
                                                size="small"
                                                className={styles.buttonTramit}
                                                onClick={() => resetContract()}
                                                disabled
                                            >
                                                Resetear contrato
                                            </Button>

                                            <DeleteContractAlert
                                                showIcon={true}
                                                openFromMobile={deleteContract}
                                                setTexLoadin={(text) =>
                                                    setTextLoading(text)
                                                }
                                                contrato={contrato}
                                            />

                                            <Button
                                                variant="outlined"
                                                startIcon={<UpdateIcon />}
                                                size="small"
                                                className={styles.buttonTramit}
                                                onClick={() => dataUpdate()}
                                            >
                                                Actualizar seguimiento
                                            </Button>

                                            {!verifiVariable(userInfo) &&
                                                userInfo["iProfileID"] ===
                                                typeUser.ADMIN &&
                                                !verifiVariable(contrato) &&
                                                ["IN_NOTARY"].includes(
                                                    contrato["status"]
                                                ) && (<Button
                                                    variant="outlined"
                                                    startIcon={
                                                        <BiCloudUpload />
                                                    }
                                                    onClick={() => setOpenModalNotary(true)}
                                                    size="small"
                                                    className={
                                                        styles.buttonTramit
                                                    }
                                                    sx={{ marginTop: "15px" }}
                                                >
                                                    Subir documento notariado
                                                </Button>
                                                )}

                                            {!verifiVariable(userInfo) &&
                                                userInfo["iProfileID"] ===
                                                typeUser.ADMIN &&
                                                !verifiVariable(contrato) &&
                                                ["SIGNED_PENDING"].includes(
                                                    contrato["status"]
                                                ) /* &&
                                            detailContract["contrato"][0][
                                                "withDocument"
                                            ] === 1 */ && (
                                                    <Tooltip
                                                        title={
                                                            <Typography
                                                                variant="subtitle1"
                                                                color={[
                                                                    "white.main",
                                                                ]}
                                                                gutterBottom
                                                            >
                                                                Reenvia correo de
                                                                firmas, los
                                                                participantes
                                                                deberán realizar el
                                                                proceso de firma
                                                                nuevamente.
                                                            </Typography>
                                                        }
                                                        TransitionComponent={Fade}
                                                        TransitionProps={{
                                                            timeout: 600,
                                                        }}
                                                        arrow
                                                    >
                                                        <Button
                                                            variant="outlined"
                                                            startIcon={
                                                                <BsEnvelope />
                                                            }
                                                            onClick={() =>
                                                                handleClickOpen()
                                                            }
                                                            size="small"
                                                            className={
                                                                styles.buttonTramit
                                                            }
                                                        >
                                                            Reenviar firma
                                                        </Button>
                                                    </Tooltip>
                                                )}

                                            {
                                                !verifiVariable(userInfo) &&
                                                /* userInfo["iProfileID"] === typeUser.ADMIN && */
                                                !verifiVariable(contrato) &&
                                                ["FINISHED"].includes(contrato["status"]) &&
                                                (
                                                    <Tooltip
                                                        title={
                                                            <Typography
                                                                variant="subtitle1"
                                                                color={["white.main"]}
                                                                gutterBottom
                                                            >
                                                                Descargar documento notariado.
                                                            </Typography>
                                                        }
                                                        TransitionComponent={Fade}
                                                        TransitionProps={{
                                                            timeout: 600,
                                                        }}
                                                        arrow
                                                    >

                                                        <Button
                                                            variant="outlined"
                                                            startIcon={<DownloadOutlineDetailIcon style={{ width: 23, height: 23 }} />}
                                                            onClick={() => {
                                                                setDocLog(null);
                                                                handleShowDocument(true, 'ANF', true)
                                                            }}
                                                            size="small"
                                                            className={
                                                                styles.buttonTramit
                                                            }
                                                            sx={{ margin: .4 }}
                                                        >
                                                            Documento Notariado
                                                        </Button>

                                                    </Tooltip>
                                                )
                                            }
                                        </div>

                                        <div className={styles.numberTitle}>
                                            <Typography
                                                className={styles.stepNumbers}
                                                variant="h5"
                                                gutterBottom
                                            >
                                                1
                                            </Typography>

                                            <CustomTextTitle
                                                variant="h6"
                                                gutterBottom
                                                className={styles.detailTextTitle}
                                            >
                                                Datos personales del solicitante{" "}
                                                <br />
                                                <span>Mis datos personales</span>
                                            </CustomTextTitle>
                                        </div>

                                        <div className={styles.infoOwner}>
                                            <UserIcon />

                                            <div>
                                                <CustomTextTitle
                                                    variant="h6"
                                                    gutterBottom
                                                >
                                                    Nombre
                                                </CustomTextTitle>

                                                <CustomTextTitle
                                                    variant="h6"
                                                    gutterBottom
                                                >
                                                    Correo electrónico
                                                </CustomTextTitle>

                                                <CustomTextTitle
                                                    variant="h6"
                                                    gutterBottom
                                                >
                                                    Teléfono
                                                </CustomTextTitle>
                                            </div>

                                            {!verifiVariable(owner) && (
                                                <div>
                                                    <CustomTextTitle
                                                        variant="body1"
                                                        gutterBottom
                                                    >
                                                        {owner["full_name"]}
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="body1"
                                                        gutterBottom
                                                    >
                                                        {owner["email"]}
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="body1"
                                                        gutterBottom
                                                    >
                                                        {owner["phone"]}
                                                    </CustomTextTitle>
                                                </div>
                                            )}
                                        </div>

                                        <TramitDetail
                                            tramitID={contratoExpress?.[0]?.autoId}
                                            tramitIDAnf={contrato?.["numberID"]}
                                            tramitType={contrato?.[0]?.["autoId"] || "ANF"}
                                            participantsNumber={firmantes?.length}
                                            tramitName={contrato?.["contractName"] || 'No especificado'}
                                        />

                                        <DocumentInfo
                                            sectionTitle={contratoExpress?.[0]?.sStatus === 'FINISHED'}
                                            signLog={
                                                contratoExpress?.[0] &&
                                                (contrato && ["FINISHED", "IN_NOTARY", "SIGNED"].includes(contrato["status"]))
                                            }
                                            isReadyToView={true}
                                            isReadyToDownload={true}
                                            isReplaceable={isAllUpload}
                                            viewDocument={() => {
                                                setDocLog(null);
                                                handleShowDocument(true, 'ANF', false);
                                            }}
                                            downloadDocument={() => {
                                                setDocLog(null);
                                                handleShowDocument(true, 'ANF', true);
                                            }}
                                            downloadLogDocument={() => {
                                                setDocLog(true);
                                                handleShowDocument(true, 'ANF', true);
                                            }}
                                            replaceDocument={() => navigate(to.ANF_REPLACE_DOCUMENT)}
                                            documentName={archivosFromExpress?.[0]?.["file_name"] || archivos[0]["file_name"]}
                                        />

                                        {!verifiVariable(payInfo) && (<Box>
                                            <div className={styles.numberTitle}>
                                                <Typography
                                                    className={styles.stepNumbers}
                                                    variant="h5"
                                                    gutterBottom
                                                >
                                                    4
                                                </Typography>

                                                <CustomTextTitle
                                                    variant="h6"
                                                    gutterBottom
                                                    className={styles.detailTextTitle}
                                                >
                                                    Resúmen del pedido<br />
                                                    <span>
                                                        Total a pagar
                                                    </span>
                                                </CustomTextTitle>
                                            </div>

                                            <Box className={styles.orderInfo}>

                                                <Box className={"service-info"}>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom
                                                    >
                                                        Servicio de notaría
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['notary']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomTextTitle>

                                                </Box>

                                                <br />

                                                <Box className={"service-info"}>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom
                                                    >
                                                        Servicio de firma electrónica
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['signature']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomTextTitle>

                                                </Box>

                                                <br />

                                                <Box className={"service-info"}>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom
                                                    >
                                                        IVA
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['iva']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomTextTitle>

                                                </Box>

                                                <br />

                                                <Box className={"service-info-total"}>

                                                    <CustomTextTitle
                                                        mr={8}
                                                        variant="h6"
                                                        gutterBottom
                                                    >
                                                        Total
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['total']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomTextTitle>

                                                </Box>

                                            </Box>

                                        </Box>
                                        )}

                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            className={`${styles.titleFV} ${styles.bottonTitle}`}
                                        >
                                            Para más información comunícate con
                                            nuestro equipo de{" "}
                                            <span>soporte al cliente</span>
                                        </Typography>

                                        <div className={styles.contactInfo}>
                                            <Contact />
                                        </div>
                                    </Box>
                                </Item>
                            </Grid>
                        )}

                    {isMobile && (
                        <Grid
                            item
                            xs={12}
                            className={styles.mainContentParticipants}
                        >
                            <Item
                                className={`${styles.contenItem} ${styles.infoItem} ${styles.textXs}`}
                            >
                                <Typography
                                    variant="h5"
                                    gutterBottom
                                    className={styles.titleFV}
                                >
                                    Bienvenido a <br /> FirmaVirtual{" "}
                                    <span>Autorización notarial de firmas</span>
                                </Typography>
                            </Item>
                        </Grid>
                    )}

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        className={styles.mainContentParticipants}
                    >
                        {!verifiVariable(firmantes) &&
                            !verifiVariable(contrato) &&
                            [...participants].map((item, index) => {
                                return (
                                    <Fragment key={item["email"] + index}>
                                        <Item className={`${styles.cardFirmUser}`}>

                                            <Box>
                                                <UserIcon />
                                            </Box>

                                            <div id="textInfoParticipans">
                                                <Typography variant="h6" gutterBottom>
                                                    Participante {index + 1}
                                                </Typography>

                                                <Typography variant="h6" gutterBottom>
                                                    {item["email"]}
                                                </Typography>

                                                <Typography variant="h6" gutterBottom>
                                                    {item["full_name"].replace(
                                                        /\b\w/g,
                                                        (l) => l.toUpperCase()
                                                    )}
                                                </Typography>

                                                <Typography variant="h6" gutterBottom>
                                                    {item["phoneNumber"]}
                                                </Typography>

                                                <Typography variant="h6" gutterBottom>
                                                    {item["rutId"]}
                                                </Typography>

                                                {!verifiVariable(contrato) && (
                                                    <Box className={styles.roleItem} gutterBottom>
                                                        {
                                                            item['rol'] === '0' && item['authNeed'] === '1' && item['portion'] === '0.00' &&
                                                            <Typography variant="h6">
                                                                Firmante
                                                            </Typography>
                                                        }
                                                        {
                                                            item['rol'] === '0' && item['authNeed'] === '1' && item['portion'] !== '0.00' &&
                                                            <Typography variant="h6">
                                                                Pagador Firmante
                                                            </Typography>
                                                        }
                                                        {
                                                            item['rol'] === '3' && item['authNeed'] === '1' &&
                                                            <Typography variant="h6">
                                                                Pagador
                                                            </Typography>}
                                                        {
                                                            item['rol'] === '0' && item['authNeed'] === '0' && item['portion'] === '0.00' && item["signerLegal"] !== "1" &&
                                                            <Typography variant="h6">
                                                                Aprobador
                                                            </Typography>
                                                        }

                                                        {
                                                            item['rol'] === '0' && item['authNeed'] === '0' && item["signerLegal"] === "1" &&
                                                            <Typography variant="h6">
                                                                Representante Legal
                                                            </Typography>
                                                        }

                                                        {
                                                            item['rol'] === '5' && item['authNeed'] === '1' &&
                                                            <Typography variant="h6">
                                                                Copia
                                                            </Typography>
                                                        }
                                                    </Box>
                                                )}

                                                {item["rol"] === "0" && item['authNeed'] === '1' &&
                                                    [
                                                        "SIGNED",
                                                        "AUTHENTICATED",
                                                        "UPLOADED",
                                                        "NOTIFIED"
                                                    ].includes(item["signStatus"]) && (
                                                        <Tooltip
                                                            title={
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    color={["white.main"]}
                                                                    gutterBottom
                                                                >
                                                                    Si tienes problemas con el video de la voluntad del firmante, te sugerimos solicitar el reenvío del correo de validación de identidad a nuestro equipo de soporte.
                                                                </Typography>
                                                            }
                                                            TransitionComponent={Fade}
                                                            TransitionProps={{ timeout: 600 }}
                                                            arrow
                                                        >
                                                            <ClvUnicaUnaceptedIcon style={{ width: '10rem', marginTop: ".75rem" }} />
                                                        </Tooltip>
                                                    )
                                                }

                                                {
                                                    ["0", "1"].includes(item["rol"])
                                                    && !verifiVariable(item["expressData"])
                                                    && ["SIGNED"].includes(item["expressData"]["sign_status"])
                                                    && ["SIGNED"].includes(item["signStatus"])
                                                    && [
                                                        "SIGNED_PENDING",
                                                        "DELIVERED",
                                                        "IN_NOTARY",
                                                        "FINISHED"
                                                    ].includes(contrato["status"])
                                                    && (<SignedIcon className="sign-status-icon" style={{ marginTop: '1rem' }} />)
                                                }

                                                {
                                                    item["rol"] === "0"
                                                    && item["signStatus"] === "PENDING"
                                                    && (
                                                        <Tooltip
                                                            title={
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    color={["white.main"]}
                                                                    gutterBottom
                                                                >
                                                                    Click para copiar enlace de clave única para este participante
                                                                </Typography>
                                                            }
                                                            TransitionComponent={Fade}
                                                            TransitionProps={{ timeout: 600 }}
                                                            arrow
                                                        >
                                                            <div
                                                                style={{ marginTop: ".75rem" }}
                                                            >
                                                                <ClvUnicaAceptedIcon
                                                                    onClick={() => {
                                                                        copyText(item["authUrl"]);

                                                                        setTextSnackbar(
                                                                            "El link de clave única ha sido copiado, puedes enviárselo a el firmante."
                                                                        );
                                                                        handleClickSnackbar();
                                                                    }}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        width: '10rem'
                                                                    }}
                                                                />
                                                            </div>
                                                        </Tooltip>
                                                    )
                                                }

                                                {
                                                    ["0", "1"].includes(item["rol"]) &&
                                                    !verifiVariable(item["expressData"]) &&
                                                    [
                                                        "NOTHING",
                                                        "NOTIFIED",
                                                        "VIEW",
                                                        "DECLINED",
                                                        "BOUNCED",
                                                        "FORWARDED",
                                                        "CANCELLED",
                                                    ].includes(item["expressData"]["sign_status"]) &&
                                                    !["SIGNED"].includes(item["signStatus"]) &&
                                                    [
                                                        "SIGNED_PENDING",
                                                        "DELIVERED",
                                                        "NOTHING",
                                                    ].includes(contrato["status"]) && (
                                                        <UnsignedIcon className="sign-status-icon" style={{ marginTop: '1rem' }} />
                                                    )
                                                }

                                                {
                                                    contrato["status"] === "SIGNED_PENDING" &&
                                                    item["rol"] === "0" &&
                                                    !verifiVariable(item["expressData"]) &&
                                                    item["expressData"]["sign_status"] !== "SIGNED" &&
                                                    (
                                                        <Tooltip
                                                            title={
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    color={["white.main"]}
                                                                >
                                                                    Reenviar link de firma.
                                                                </Typography>
                                                            }
                                                            TransitionComponent={Fade}
                                                            TransitionProps={{ timeout: 600 }}
                                                            arrow
                                                        >
                                                            <CustomWhatsAppButton
                                                                variant="contained"
                                                                disabled={timer === '00:00:00' ? false : true}
                                                                onClick={() => setEmailSigner(item["email"])}
                                                                startIcon={<WhatsAppIcon className="icon-buttom" />}
                                                            >
                                                                {timer !== '00:00:00' ? `${timer.replace('00:00:', '')} seg` : "Reenviar firma a participante"}
                                                            </CustomWhatsAppButton>
                                                        </Tooltip>
                                                    )
                                                }

                                            </div>

                                        </Item>

                                        <Box className="actionParticipantsButtons">

                                            <Box
                                                className={styles.contentActionParticipants}
                                                sx={{ justifyContent: "space-between" }}
                                            >
                                                {
                                                    ["NOTIFIED", "UPLOADED"].includes(item["signStatus"])
                                                    && !verifiVariable(contratoExpress[0])
                                                    && ["0", "3"].includes(item["rol"])
                                                    && item["portion"] !== "0.00"
                                                    && [
                                                        "PENDING",
                                                        "PARTIALLY_PAID",
                                                    ].includes(contrato["paymentStatus"])
                                                    && (
                                                        <Fragment>

                                                            <Tooltip
                                                                title={
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        color={["white.main"]}
                                                                        gutterBottom
                                                                    >
                                                                        Pagar trámite.
                                                                    </Typography>
                                                                }
                                                                TransitionComponent={Fade}
                                                                TransitionProps={{ timeout: 600 }}
                                                                arrow
                                                            >
                                                                <CustomActionButton
                                                                    className="pay-icon"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            urlPay(
                                                                                !verifiVariable(item["expressData"])
                                                                                    && !verifiVariable(item["expressData"]["token_payment"])
                                                                                    ? item["expressData"]["token_payment"]
                                                                                    : "#########",
                                                                                contrato["contractID"]
                                                                            ), "_blank"
                                                                        );
                                                                    }}
                                                                    startIcon={<PayIcon />}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disabled={
                                                                        !verifiVariable(contratoExpress[0])
                                                                        && [
                                                                            "PARTIALLY_PAID",
                                                                            "PENDING",
                                                                            "AFTER_PAYMENT",
                                                                        ].includes(contratoExpress[0]["sPaymentModal"])
                                                                    }
                                                                />

                                                            </Tooltip>

                                                            <Tooltip
                                                                title={
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        color={["white.main"]}
                                                                        gutterBottom
                                                                    >
                                                                        Copiar link de pago.
                                                                    </Typography>
                                                                }
                                                                TransitionComponent={Fade}
                                                                TransitionProps={{ timeout: 600 }}
                                                                arrow
                                                            >
                                                                <CustomActionButton
                                                                    onClick={async () => {
                                                                        copyText(urlPay(
                                                                            !verifiVariable(item["expressData"])
                                                                                && !verifiVariable(item["expressData"]["token_payment"])
                                                                                ? item["expressData"]["token_payment"]
                                                                                : "##########",
                                                                            contrato["contractID"]
                                                                        ));

                                                                        setTextSnackbar("El link de pago ha sido copiado, puedes enviárselo a el firmante.");

                                                                        handleClickSnackbar();
                                                                    }}
                                                                    startIcon={<FaCopy />}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disabled={
                                                                        !verifiVariable(contratoExpress[0])
                                                                        && [
                                                                            "PARTIALLY_PAID",
                                                                            "PENDING",
                                                                            "AFTER_PAYMENT",
                                                                        ].includes(contratoExpress[0]["sPaymentModal"])
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        </Fragment>
                                                    )}

                                                {
                                                    (item["rol"] === "0" && item["authNeed"] === "1") &&
                                                    ["CREATED", "REVIEW"].includes(contrato["status"]) &&
                                                    /* !["NOTIFIED", "UPLOADED"].includes(item["signStatus"]) && */
                                                    (
                                                        <Tooltip
                                                            title={
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    color={[
                                                                        "white.main",
                                                                    ]}
                                                                    gutterBottom
                                                                >
                                                                    Reenviar correo
                                                                    de clave única
                                                                    para este
                                                                    participante.
                                                                </Typography>
                                                            }
                                                            TransitionComponent={Fade}
                                                            TransitionProps={{ timeout: 600 }}
                                                            arrow
                                                        >
                                                            <CustomActionButton
                                                                onClick={() =>
                                                                    resentUniqClv(item["signerID"])
                                                                }
                                                                startIcon={<IoArrowRedo />}
                                                                variant="contained"
                                                                color="primary"
                                                            />
                                                        </Tooltip>
                                                    )
                                                }

                                                {
                                                    item["rol"] === "0" &&
                                                    ["UPLOADED", "SIGNED", "NOTIFIED"].includes(item["signStatus"]) &&
                                                    item["files"].length !== 0 && (
                                                        <VideoVerifyModal
                                                            fileVideo={
                                                                item["files"].length !== 0
                                                                    ? item["files"][0]["url"]
                                                                    : "#"
                                                            }
                                                        />
                                                    )
                                                }

                                                {
                                                    ["UPLOADED", "SIGNED", "NOTIFIED"].includes(item["signStatus"]) &&
                                                    ["0", "3"].includes(item["rol"]) &&
                                                    item["portion"] !== "0.00" &&
                                                    ["PAIDOUT"].includes(contrato["paymentStatus"]) &&
                                                    (
                                                        <Tooltip
                                                            title={
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    color={["white.main"]}
                                                                    gutterBottom
                                                                >
                                                                    Descargar DTE
                                                                </Typography>
                                                            }
                                                            TransitionComponent={Fade}
                                                            TransitionProps={{ timeout: 600 }}
                                                            arrow
                                                        >
                                                            <DownloadDTEButton
                                                                sx={{
                                                                    color: "white !important",
                                                                    "& svg": { zoom: "2" }
                                                                }}
                                                                className="camera-icon"
                                                                onClick={() =>
                                                                    descargarDTE(
                                                                        item["email"],
                                                                        item["contractID"]
                                                                    )
                                                                }
                                                                variant="contained"
                                                                color="primary"
                                                            >
                                                                <FaCloudArrowDown />
                                                            </DownloadDTEButton>
                                                        </Tooltip>
                                                    )
                                                }
                                            </Box>

                                        </Box>

                                    </Fragment>
                                );
                            })}
                    </Grid>

                    {isMobile && (
                        <Fragment>
                            <Grid
                                item
                                xs={12}
                                className={styles.mainContentParticipants}
                            >
                                <div className={styles.numberTitle} style={{ alignItems: "center" }}>
                                    <Typography
                                        className={styles.stepNumbers}
                                        variant="h5"
                                        gutterBottom
                                    >
                                        1
                                    </Typography>

                                    <CustomTextTitle
                                        variant="h6"
                                        gutterBottom
                                        className={styles.detailTextTitle}
                                    >
                                        Datos personales del solicitante <br />
                                        <span className={styles.detailTextSecondaryTitle}>Mis datos personales</span>
                                    </CustomTextTitle>
                                </div>

                                <Item
                                    className={`${styles.contenItem} ${styles.infoItem} ${styles.cardTypeSignatureXs}`}
                                >

                                    <UserIcon style={{ width: '4rem !important', height: '4rem !important' }} />

                                    <Box className={styles.ownerData_Info_Box}>

                                        <Box className={styles.ownerData_Info_textBox}>
                                            <CustomTextTitle
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Nombre
                                            </CustomTextTitle>

                                            <CustomTextTitle
                                                variant="body1"
                                                gutterBottom
                                                className={styles.ownerData_Info_Box_MainText}
                                            >
                                                {owner?.["full_name"]}
                                            </CustomTextTitle>
                                        </Box>

                                        <Box className={styles.ownerData_Info_textBox}>
                                            <CustomTextTitle
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Email
                                            </CustomTextTitle>

                                            <CustomTextTitle
                                                variant="body1"
                                                gutterBottom
                                                className={styles.ownerData_Info_Box_MainText}
                                            >
                                                {owner?.["email"]}
                                            </CustomTextTitle>
                                        </Box>

                                        <Box className={styles.ownerData_Info_textBox}>
                                            <CustomTextTitle
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Teléfono
                                            </CustomTextTitle>

                                            <CustomTextTitle
                                                variant="body1"
                                                gutterBottom
                                                className={styles.ownerData_Info_Box_MainText}
                                            >
                                                {owner?.["phone"] || 'No especificado'}
                                            </CustomTextTitle>
                                        </Box>

                                    </Box>

                                </Item>

                            </Grid>

                            <TramitDetail
                                tramitID={contratoExpress?.[0]?.autoId || 'No especificado'}
                                tramitIDAnf={contrato?.["numberID"]}
                                tramitType={contrato?.[0]?.["autoId"] || "No especificado"}
                                participantsNumber={firmantes?.length}
                                tramitName={contrato?.["contractName"] || 'No especificado'}
                            />

                            <DocumentInfo
                                sectionTitle={contratoExpress?.[0]?.sStatus === 'FINISHED'}
                                signLog={
                                    contratoExpress?.[0] &&
                                    (contrato && ["FINISHED", "IN_NOTARY", "SIGNED"].includes(contrato["status"]))
                                }
                                isReadyToView={true}
                                isReadyToDownload={true}
                                isReplaceable={isAllUpload}
                                viewDocument={() => {
                                    setDocLog(null);
                                    handleShowDocument(true, 'ANF', false)
                                }}
                                downloadDocument={() => {
                                    setDocLog(null);
                                    handleShowDocument(true, 'ANF', true)
                                }}
                                downloadLogDocument={() => {
                                    setDocLog(true);
                                    handleShowDocument(true, 'ANF', true);
                                }}
                                replaceDocument={() => navigate(to.ANF_REPLACE_DOCUMENT)}
                                documentName={archivosFromExpress?.[0]?.["file_name"] || 'No especificado'}
                            />

                            {!verifiVariable(payInfo) && (
                                <Grid
                                    item
                                    xs={12}
                                    className={styles.mainContentParticipants}
                                >
                                    <Box>
                                        <div className={styles.numberTitle}>
                                            <Typography
                                                className={styles.stepNumbers}
                                                variant="h5"
                                                gutterBottom
                                            >
                                                4
                                            </Typography>

                                            <CustomTextTitle
                                                variant="h6"
                                                gutterBottom
                                                className={styles.detailTextTitle}
                                            >
                                                Resúmen del pedido<br />
                                                <span>
                                                    Total a pagar
                                                </span>
                                            </CustomTextTitle>
                                        </div>
                                        <Box sx={{ backgroundColor: '#fff', padding: '1.34rem' }}>
                                            <Box className={styles.orderInfo}>

                                                <Box className={"service-info"}>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom
                                                    >
                                                        Servicio de notaría
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['notary']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomTextTitle>

                                                </Box>

                                                <br />

                                                <Box className={"service-info"}>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom
                                                    >
                                                        Servicio de firma electrónica
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['signature']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomTextTitle>

                                                </Box>

                                                <br />

                                                <Box className={"service-info"}>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom
                                                    >
                                                        IVA
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['iva']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomTextTitle>

                                                </Box>

                                                <br />

                                                <Box className={"service-info-total"}>

                                                    <CustomTextTitle
                                                        mr={8}
                                                        variant="h6"
                                                        gutterBottom
                                                    >
                                                        Total
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['total']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomTextTitle>

                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <div className={styles.contentFooterXs}>
                                    <CustomTextTitle variant="h6" gutterBottom>
                                        Gracias por usar nuestros servicios
                                    </CustomTextTitle>

                                    <Contact showMsnInfo={true} />
                                </div>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                className={styles.mainContentParticipants}
                            >
                                <br />
                            </Grid>

                            <AppBar className={styles.appBarBottom}>
                                <DeleteContractAlert
                                    showIcon={false}
                                    openFromMobile={deleteContract}
                                    setTexLoadin={(text) => setTextLoading(text)}
                                    contrato={contrato}
                                />
                                <Toolbar>
                                    <Tabs
                                        onChange={handleChangeAppBarBottom}
                                        indicatorColor="secondary"
                                        textColor="inherit"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                    >
                                        <Tab
                                            value={"Resetear"}
                                            disabled
                                            icon={<ResetIcon />}
                                            label="Resetear"
                                        />
                                        <Tab
                                            disabled
                                            /* disabled={
                                                !verifiVariable(contrato) &&
                                                ![
                                                    "REVIEW",
                                                    "CREATED",
                                                    "REJECTED",
                                                ].includes(contrato["status"])
                                            } */
                                            value={"Eliminar"}
                                            icon={<TrashIcon />}
                                            label="Eliminar trámite"
                                        />
                                        <Tab
                                            value={"Actualizar"}
                                            // onClick={() => dataUpdate()}
                                            icon={<UpdateIcon />}
                                            label="Actualizar"
                                        />
                                    </Tabs>
                                </Toolbar>
                            </AppBar>
                        </Fragment>
                    )}
                </Grid>
            </Box>
        </Container>
    );
};

export default ANFDetailProcedure;