
import { Fragment, useState } from 'react';
import { useSelector } from "react-redux";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import CloseIcon from '@mui/icons-material/Close';

import NuevoDisenoBg from "../../assets/img/nuevo-diseno-bg.svg";
import { ReactComponent as NewFormIconModal } from "../../assets/icons/new-form-icon-modal.svg";
import OpenModalToNewPlatform from '../openModalToNewPlatform';
import { api } from '../../utils/enums';

const BootstrapButton = styled(Button)(({ theme }) => ({
    width: "180px",
    textTransform: 'none',
    boxShadow: 'none',
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    fontSize: "1.1rem",
    marginTop: "20px",
    marginBottom: "20px",

    '&:hover': {
        backgroundColor: 'white',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
}));

const BootstrapTypography = styled(Typography)(({ theme }) => ({
    color: "white",
    fontFamily: "PoppinsRegular",
    textAlign: "center",

    "& span": {
        fontFamily: "PoppinsBold",
        color: "white",
        fontSize: "1em"
    }

}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        color: "white",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        borderRadius: "15px",
        overflowY: "unset"
    },
}));

const ModalToNewForm = () => {
    const {
        session: {
            login: { user },
        }
    } = useSelector((state) => state.root);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('sm');


    const dialogContentStyles = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundImage: `url(${NuevoDisenoBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: "auto"
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    // eslint-disable-next-line
    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value,
        );
    };
    // eslint-disable-next-line
    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    /* useEffect(() => {
        handleClickOpen();
    }, []); */

    return (
        <Fragment>

            {!open && (<OpenModalToNewPlatform open={() => handleClickOpen()} />)}

            <BootstrapDialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
            >
                <DialogContent style={dialogContentStyles}>

                    <CloseIcon
                        onClick={handleClose}
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "26px"
                        }}
                    />

                    <NewFormIconModal style={{
                        top: "-9%",
                        position: "absolute",
                        zoom: 0.6,
                    }} />

                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: isMobile ? "43%" : "25%"
                    }}>

                        <BootstrapTypography variant="h6" gutterBottom>
                            ¡Mejora tu experiencia de <span>creación de contratos!</span>
                        </BootstrapTypography>

                        <BootstrapTypography variant="h6" gutterBottom>
                            Descubre nuestra nueva <span>forma fácil</span> y <span>rápida</span> de
                        </BootstrapTypography>

                        <BootstrapTypography variant="h6" gutterBottom>
                            hacerlo desde <span>tu cuenta.</span>
                        </BootstrapTypography>


                        <BootstrapButton
                            variant="contained"
                            onClick={() => {
                                const { token } = user;
                                window.open(
                                    `${api.TO_NEW_PLATFORM}/${token}`,
                                    "_blank"
                                )
                            }}
                        >
                            Comenzar
                        </BootstrapButton>

                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </Fragment>
    );
}

export default ModalToNewForm;