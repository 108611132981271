import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import spy from 'redux-monitor-spy';
import rootReducer from "./reducer";
import permanentReducer from "./permanentReducer";
import logger from "./middleware/logger";
import api from "./middleware/api";
import apiExpress from "./middleware/apiExpress";
import apiAnf from "./middleware/apiAnf";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from 'redux-persist-indexeddb-storage';
import apiMerge from "./middleware/apiMerge";


const persistConfig = {
    key: "root",
    version: 1,
    storage: storage('firmaVirtual1')
};

const nonPurgeablePersistConfig = {
    key: "nonPurgeable",
    storage: storage("permanent")
};

const purgeablePersistedReducer = persistReducer(persistConfig, rootReducer);
const specialPersistedReducer = persistReducer(nonPurgeablePersistConfig, permanentReducer);

const middleware = [
    spy,
    ...getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [
                FLUSH,
                REHYDRATE,
                PAUSE,
                PERSIST,
                PURGE,
                REGISTER,
            ],
        },
    }),
    logger({ destination: "console" }),
    api,
    apiExpress,
    apiMerge,
    apiAnf,
];

const store = configureStore({
    reducer: {
        root: purgeablePersistedReducer,
        special: specialPersistedReducer,
    },
    middleware,
    devTools: true
});

let persistor = persistStore(store);


export const clearCache = () => {
    // persistor.purge({ type: PURGE, key: "root" });
    // persistor.dispatch({ type: PURGE, key: 'root' });
};

export { store, persistor };
