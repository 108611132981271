import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { BiRightArrowAlt } from "react-icons/bi";

import { motion } from "framer-motion";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { errorStepFormSolicitud } from "../../../../store/formSolicitud/formSolicitud";
import { FormularioSolicitudStyles } from "../../index.css";
import { checkRut } from "../../../../utils/validations";

const StepSignerRut = ({ control, trigger, index, useWatch, setValue }) => {
    const styles = FormularioSolicitudStyles();
    const {
        formSolicitud: { signers },
    } = useSelector((state) => state.root);
    const dispatch = useDispatch();
    const refFirstLoad = useRef({});
    const [errorInput, setErrorInput] = useState(undefined);

    const watchSignersRut = useWatch({
        control,
        name: `signers.${index}.rutId`,
    });

    const watchSignerList = useWatch({ control, name: `signers` });

    useEffect(() => {
        if (![undefined].includes(errorInput)) {
            dispatch(errorStepFormSolicitud(true));
        } else {
            dispatch(errorStepFormSolicitud(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorInput]);

    useEffect(() => {
        if (signers[index])
            setValue(`signers?.${index}?.rutId`, signers[index].rutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    refFirstLoad.current = false;

    useEffect(() => {
        trigger();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchSignersRut]);

    return (
        <div className={styles.stepBox}>
            <motion.div
                initial={{ y: "50vh" }}
                animate={{ y: 0 }}
                transition={{ stiffness: 150 }}
            >
                <Grid container xs={12}>
                    <Grid item xs={1} className={styles.boxIndetify}>
                        ?<BiRightArrowAlt />
                    </Grid>

                    <Grid item xs={11}>
                        <Typography
                            variant="h4"
                            className={styles.cssTexto1}
                            gutterBottom
                        >
                            Firmante {index + 1}: RUT (sin puntos y con guión) *
                        </Typography>

                        <Controller
                            name={`signers.${index}.rutId`}
                            control={control}
                            rules={{
                                required: "Este campo es requerido",
                                validate: {
                                    isPassPortOrRut: (value) => {
                                        if ([checkRut(value)].includes(true)) {
                                            return true;
                                        } else {
                                            return "RUT inválido";
                                        }
                                    },

                                    uniqueRut: (value) => {
                                        const list = [
                                            ...watchSignerList.filter(
                                                (item) =>
                                                    ![
                                                        null,
                                                        undefined,
                                                        "",
                                                    ].includes(item["rutId"])
                                            ),
                                        ];

                                        const rutList = [];

                                        for (
                                            let index = 0;
                                            index < list.length;
                                            index++
                                        ) {
                                            const element = list[index];
                                            rutList.push(element["rutId"]);
                                        }

                                        const removeIndex =
                                            rutList.indexOf(watchSignersRut);

                                        if (removeIndex > -1)
                                            rutList.splice(removeIndex, 1);

                                        return rutList.includes(value)
                                            ? "Rut duplicado"
                                            : true;
                                    },
                                },
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                setErrorInput(fieldState.error);
                                return (
                                    <FormControl
                                        fullWidth
                                        error={fieldState.error ? true : false}
                                    >
                                        <TextField
                                            type="text"
                                            error={
                                                fieldState.error ? true : false
                                            }
                                            ref={ref}
                                            onBlur={onBlur}
                                            variant={"standard"}
                                            onChange={onChange}
                                            value={value}
                                            helperText={
                                                fieldState.error
                                                    ? fieldState.error.message
                                                    : null
                                            }
                                            placeholder={"11111111-1"}
                                            color={
                                                fieldState.error
                                                    ? "error"
                                                    : "success"
                                            }
                                            focused={
                                                fieldState.isTouched ||
                                                fieldState.isDirty
                                            }
                                            fullWidth
                                        />
                                    </FormControl>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </motion.div>
        </div>
    );
};

export default StepSignerRut;
