import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ContractListHeader from "../ContractListHeader";
import { getContractsCreated } from "../../../store/contract/contractDetail";
import { Box } from "@mui/system";
import { CustomToobarTableList, GeneralListProced, Spinner } from "../..";
import { setearFecha, to } from "../../../utils/enums";

const ListDelivery = () => {
    const {
        session: {
            login: { user },
        },
        contract: {
            contractCreator: { loading, contractListActive },
        },
    } = useSelector((state) => state.root);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dataGrid, setDataGrid] = useState([]);
    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        const { userId, token, profileId } = user;

        if (![null, undefined].includes(userId)) {
            dispatch(getContractsCreated(userId, token, profileId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        const { status, message } = contractListActive;

        if (["success"].includes(status)) {
            setDataGrid(message);
        }
    }, [contractListActive]);

    const updateData = () => {
        const { userId, token, profileId } = user;

        setSpinner(true);

        if (![null, undefined].includes(userId)) {
            dispatch(getContractsCreated(userId, token, profileId));
        }

        setTimeout(() => {
            setSpinner(false);
        }, 5000);
    };

    const renderList = useCallback(() => {
        const arrayList = dataGrid.filter(
            (item) => item.sStatus === "FINISHED" && item.sProcess === "NORMAL"
        );

        return (
            <Fragment>
                <br />

                <CustomToobarTableList
                    title={"Trámites entregados."}
                    subTitle={
                        "Aqui podrás visualizar todos los trámites que están listos."
                    }
                    onUpdate={() => updateData()}
                    onCreate={() => navigate(to.CONTRACT_CREATION)}
                />

                <div
                    className="container--relative contracts-status-list__container"
                    style={{ height: "auto", width: "100%" }}
                >
                    <div style={{ display: "flex", height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <GeneralListProced
                                dataGrid={[...arrayList].map((item) => {
                                    return {
                                        sProcess: item["sProcess"],
                                        sContractID: item["sContractID"],
                                        autoId: item["autoId"],
                                        sContractName: item["sContractName"],
                                        sTipoContrato: item["sTipoContrato"],
                                        sTipoFirma: item["sTipoFirma"],
                                        sStatus: item["sStatus"],
                                        sPaymentStatus: item["sPaymentStatus"],
                                        dContractDT: setearFecha(
                                            item["dContractDT"]
                                        ),
                                        withDocument: item["withDocument"],
                                        iSignedCount: item["iSignedCount"],
                                    };
                                })}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, loading]);

    return (
        <div className="content-detail__container">
            <ContractListHeader />
            {spinner || loading ? (
                <Box
                    sx={{ height: "150px" }}
                    className="container--relative contracts-status-list__container"
                >
                    <Spinner loading={true} type="bar" opacity={true} />
                </Box>
            ) : (
                renderList()
            )}
        </div>
    );
};

export default ListDelivery;
