import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { BiSubdirectoryLeft } from "react-icons/bi";

import Button from "@material-ui/core/Button";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import ConfirmPage from "../../atoms/confirm";
import { FormularioUachtyles } from "../../index.css";


const TypeForm = ({ children }) => {
  const {
    session: {
      login: {
        user
      }
    },
    formUach
  } = useSelector((state) => state.root);
  const styles = FormularioUachtyles();
  const [fields, setFields] = useState(0);
  children = [...children, <ConfirmPage />];

  const nextField = () => {
    if (fields < children.length) setFields((prev) => prev + 1);
  };

  const prevField = () => {
    if (fields > 0) setFields((prev) => prev - 1);
  };

  return (
    <Fragment>

      <form className={styles.formBox}>

        {children[fields]}

        <Grid container>

          <Grid item xs={1}>

          </Grid>

          {fields !== (children.length - 1) && (<Grid item xs={11} className={styles.buttomBox}>

            {/* !createContract && */ fields < children.length - 1 && fields > 0 && !formUach.addSing && (

              <Button
                variant="contained"
                style={{ backgroundColor: "rgb(245, 162, 38)", marginRight: '15px' }}
                size="large"
                onClick={prevField}
              >
                Atras
              </Button>

            )}

            {user && user.token && !formUach.addSing && (<Fragment>

              <Button
                variant="contained"
                style={{ backgroundColor: "rgb(245, 162, 38)" }}
                size="large"
                onClick={nextField}
                disabled={formUach.errorStep}
              >
                {fields === (children.length - 2) ? 'Enviar' : 'Aceptar'}
              </Button>

              <Typography id="textPulsar" variant="body2" gutterBottom>
                Pulsa <strong>Enter</strong>
              </Typography>

              <BiSubdirectoryLeft />

            </Fragment>)}

          </Grid>)}

        </Grid>

      </form>

    </Fragment>
  );
}

export default TypeForm;
