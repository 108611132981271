import React, { useEffect, useState, Fragment } from "react";
import _ from "lodash";
import { useFieldArray, useForm } from "react-hook-form";
import { BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import {
    contractType,
    procedureType,
    signaturesType,
} from "../../placeholder/contracts";
import {
    addMemeber,
    createContract,
    resetContract,
    getContractsTypes,
    clearCreateContract,
} from "../../store/contract/contractDetail";
import { store } from "../../store/storeConfig";
import { to } from "../../utils/enums";
import { bodyTextStyles, checkRut, searchDuplicate, validatePhoneNumber, validation } from "../../utils/validations";
import {
    getContractList,
    getUserToken,
    getUserInfo,
    getPreviousSignersList,
} from "../storeSelector/storeSelector";
import { PopUp, Notificationbanner } from "../../components";
import Btn from "../buttons/Button";
import Text from "../heading/Text";
import Autocomplete from "../autocomplete/Autocomplete";
import ScreenSizeHook from "../../hooks/ScreenSizeHook";

const Procedurecreationstep2 = () => {
    const userToken = getUserToken();
    const screenSizeHook = ScreenSizeHook();
    // eslint-disable-next-line
    const userInfo = getUserInfo();
    const navigate = useNavigate();
    const contractList = getContractList();
    const [loading, setLoading] = useState(false);
    const [contractSignerSelected, setContractSignerSelected] = useState(null);
    const [nonSignaturesAvailables, setNonSignaturesAvailables] =
        useState(false);
    const [signaturesQuery, setSignaturesQuery] = useState([]);
    const [procedureSelected, setProcedureSelected] = useState("");
    const [contractTypeSelected, setContractTypeSelected] = useState("");
    const [signatureTypeSelected, setSignatureTypeSelected] = useState("");
    const [members, setMembers] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalButtonTitle, setModalButtonTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [payersMembers, setPayersMembers] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState("");
    const [notificationMessage, setNotificationMessage] = useState("");
    const [prevSigners, setPrevSigner] = useState(getPreviousSignersList());
    const payers = payersMembers.filter((payer) => payer.asPayer);
    const maxAvailablePayers = 2;

    useEffect(() => {
        let run = true;
        if (run) {
            store.dispatch(clearCreateContract());

            const unsubscribe = store.subscribe(() => {
                const { contractCreator, signatories } =
                    store.getState().root.contract;

                setPrevSigner(signatories.previousSignatoriesList);

                if (!_.isEmpty(contractCreator.contract)) {
                    setLoading(false);
                    navigate(to.CONTRACT_CREATOR_DOCS);
                }

                if (contractCreator.contractCreated === false) {
                    setLoading(false);
                    setShowModal(true);
                    setModalTitle("¡Ups!, algo ha ocurrido");
                    setModalMessage(
                        "No se ha podido crear el contrato, si lo deseas puedes volver a intentarlo"
                    );
                    setModalButtonTitle("Volver a intentarlo");
                }
            });

            return () => unsubscribe();
        }
        return () => (run = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let run = true;
        if (run) {
            if (Number(members) < payersMembers.length) {
                payersMembers.splice(
                    Number(members),
                    payersMembers.length - Number(members)
                );
            }
        }
        return () => (run = false);
    }, [payersMembers, members]);

    useEffect(() => {//TODO append y remove fields de firmantes

        const validate = members - fields.length;

        if (validate > 0) {

            for (let index = 0; index < validate; index++) {

                append({});

            }

        } else {

            const countDelete = validate * -1;

            const arrayDeleteds = [];

            for (let index = 0; index < countDelete; index++) {

                const objectIndex = fields.indexOf(fields[fields.length - (index + 1)]);

                arrayDeleteds.push(objectIndex);

            }

            remove(arrayDeleteds);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [members]);

    useEffect(() => {
        let run = true;
        if (run) {
            setContractSignerSelected(
                ...signaturesQuery.filter(
                    (signature) => signature.sModal === signatureTypeSelected
                )
            );
        }
        return () => (run = false);
    }, [signatureTypeSelected, signaturesQuery]);

    useEffect(() => {
        let run = true;
        if (run) {
            if (!_.isEmpty(contractList)) {
                const realStateContractsTypes = contractList.filter(
                    (contract) => {
                        let ret;

                        if (
                            contract.sContractType === procedureSelected &&
                            contract.sContractTypeName === contractTypeSelected
                        ) {
                            ret = contract;
                        }

                        return ret;
                    }
                );

                const getContractsByCounter = realStateContractsTypes.filter(
                    (contract) => {
                        if (Number(members) > 1) {
                            return contract.iSignCount === 2;
                        } else {
                            return contract.iSignCount === 1;
                        }
                    }
                );

                setSignaturesQuery(getContractsByCounter);
            } else {
                store.dispatch(getContractsTypes(userToken));
            }
        }
        return () => (run = false);
    }, [
        members,
        contractTypeSelected,
        contractList,
        procedureSelected,
        userToken,
    ]);

    useEffect(() => {
        let run = true;
        if (run) {
            if (
                !_.isEmpty(procedureSelected) &&
                !_.isEmpty(contractTypeSelected) &&
                signaturesQuery.length <= 0
            ) {
                setNonSignaturesAvailables(true);
            } else {
                setNonSignaturesAvailables(false);
            }
        }
        return () => (run = false);
    }, [contractTypeSelected, procedureSelected, signaturesQuery]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        control
    } = useForm();//TODO hook-form

    const { append, remove, fields } = useFieldArray({
        control,
        name: "signers"
    });

    const memberTitle = {
        color: "primary.light",
        fontWeight: "500",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "1.2rem",
    };

    const titleStyles = {
        color: "black.main",
        fontWeight: "500",
        marginBottom: "0",
        fontSize: "1.2rem",
    };

    const labelStyles = {
        color: "black.main",
        fontWeight: "500",
        marginBottom: "0",
        fontSize: "0.95rem",
    };

    const hintStyles = {
        color: "quinary.main",
        fontWeight: "300",
        marginBottom: "0",
    };

    const buttonSubmitStyles = {
        backgroundColor: "primary.light",
        paddingLeft: "10px",
        paddingRight: "10px",
        fontWeight: "500",
        textTransform: "none",
        float: "right",
    };

    const buttonNoSubmitStyles = {
        backgroundColor: "quinary.main",
        padding: "10px 15px",
        borderRadius: "5px",
        color: "white.main",
        fontWeight: "500",
        textTransform: "none",
        float: "right",
    };

    const payoutTitleStyles = {
        color: "primary.light",
        fontWeight: "500",
        marginBottom: "10px",
        fontSize: "0.95rem",
    };

    const payoutMembersStyles = {
        color: "black.main",
        marginBottom: "0",
        fontSize: "0.95rem",
    };

    const errorMessageStyles = {
        color: "white.main",
        fontSize: "0.95rem",
        fontWeight: "500",
    };

    const spliteMembers = (arr, count) => {
        const preOutput = [];
        const output = [];

        while (arr.length) {
            preOutput.push(arr.splice(0, count));
        }

        preOutput[0][0].forEach((element, index) => {

            let portionToPay = "0";

            payers.forEach((payer) => {

                if (element[`memberpayment${index + 1}`]) {
                    payers.length === 2 ? (portionToPay = "50") : (portionToPay = "100");
                }

            });

            output.push({//TODO agregar monto aqui
                full_name: element[`memberfullname${index + 1}`],
                email: element[`memberemail${index + 1}`],
                rutId: element[`memberid${index + 1}`],
                phone: element[`membercellphone${index + 1}`],
                type: "NATURAL",
                payment: "0.00",
                portion: portionToPay
            });

        });

        if (Number(members) < output.length) {
            output.splice(Number(members), output.length - Number(members));
        }

        return output;
    };

    const resetInputs = (event) => {
        const { value } = event.target;

        if (value >= 1 && value <= 10) {
            setMembers(value);

            if (payersMembers.length > Number(value)) {
                setShowNotification(true);
                setNotificationType("warning");
                setNotificationMessage("Haz eliminado uno o más miembros");

                setTimeout(() => {
                    setShowNotification(false);
                    setNotificationType("");
                    setNotificationMessage("");
                }, 3000);
            }
        } else {
            setValue("proceduremembers", "");
            setShowNotification(true);
            setNotificationType("warning");
            setNotificationMessage("Solo se permite un maximo de 10 Firmantes");

            setTimeout(() => {
                setShowNotification(false);
                setNotificationType("");
                setNotificationMessage("");
            }, 3000);
        }
    };

    const getPaymentMembers = (event) => {
        const { name, value } = event.target;
        const arr = name.split("");
        const index = Number(arr.pop()) - 1;
        const fieldName = arr.join("");
        const addedMembers = _.cloneDeep(payersMembers);

        if (fieldName === `signers.${index}.memberpayment`) {
            if (addedMembers[index]) {
                if (event.target.checked) {
                    addedMembers[index].asPayer = true;
                } else {
                    addedMembers[index].asPayer = false;
                }
            }
        } else {
            if (!addedMembers.length || addedMembers.length < index + 1) {
                addedMembers.push({
                    fullName: getValues(`signers.${index}.memberfullname${index + 1}`),
                    email: getValues(`signers.${index}.memberemail${index + 1}`),
                    rutId: getValues(`signers.${index}.memberid${index + 1}`),
                    phone: getValues(`signers.${index}.membercellphone${index + 1}`),
                    index: index,
                    asPayer: false,
                });
            }

            addedMembers.find((member) => member.index === index).fullName =
                fieldName === "memberfullname"
                    ? value
                    : getValues(`signers.${index}.memberfullname${index + 1}`);
            addedMembers.find((member) => member.index === index).email =
                fieldName === "memberemail"
                    ? value
                    : getValues(`signers.${index}.memberemail${index + 1}`);
            addedMembers.find((member) => member.index === index).rutId =
                fieldName === "memberid"
                    ? value
                    : getValues(`signers.${index}.memberid${index + 1}`);
            addedMembers.find((member) => member.index === index).phone =
                fieldName === "membercellphone"
                    ? value
                    : getValues(`signers.${index}.membercellphone${index + 1}`);
        }

        setPayersMembers(addedMembers);
    };

    const onSubmit = (data) => {
        //TODO submit formulario
        // * How many fields of form each member has
        const fieldsQuantity = 5;
        const dataArr = [];
        // setLoading(true);

        for (const member in data) {
            if (
                member !== "proceduretype" &&
                member !== "procedureid" &&
                member !== "proceduremembers" &&
                member !== "proceduresignature"
            ) {
                dataArr.push(data[member]);
            }
        }

        const contractRequestPayload = {
            sOwnerType: "NATURAL",
            sPaymentStatus: "PENDING",
            iPaymentService: 0,
            iContractTypeFeeID: contractSignerSelected.iContractTypeFeeID,
            iSignedCount: contractSignerSelected.iSignCount,
            callback: "",
            iAgreementId: 0,
            iPriceId: 0,
            signers: spliteMembers(dataArr, fieldsQuantity)
        };

        store.dispatch(addMemeber(contractRequestPayload));
        store.dispatch(createContract(contractRequestPayload, userToken));
    };

    const handleModal = () => {
        setShowModal(false);
        store.dispatch(resetContract());
    };

    const displayModal = () => {
        return (
            <PopUp
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                title={modalTitle}
                onBackdropClick={handleModal}
            >
                <Text
                    sx={bodyTextStyles}
                    align="center"
                    variant="body1"
                    paragraph
                >
                    {modalMessage}
                </Text>
                <Btn
                    variant="contained"
                    text={modalButtonTitle}
                    size="lg"
                    color="primary"
                    type="submit"
                    buttonClass="button-submit"
                    onClick={handleModal}
                    fullWidth
                />
            </PopUp>
        );
    };

    const proceduresInputChoiceOptions = procedureType;

    const contractsInputChoiceOptions = contractType.filter((option, index) => {// TODO filtra los tramites a gestionar

        return option.procedureType === procedureSelected
    });

    const signaturesInputChoiceOptions = () => {
        return signaturesType.map((signature) => {
            return signaturesQuery.map((option, index) => {
                let ret;

                if (option.sModal === signature.value) {
                    ret = (
                        <option value={signature.value} key={index}>
                            {signature.name}
                        </option>
                    );
                }

                return ret;
            });
        });
    };

    const signatureMember = () => {//TODO aqui formulario de firmantes
        if (!nonSignaturesAvailables) {
            return fields.map((item, i) => {
                return (
                    <div className="signature-member__form-container" key={i}>
                        <Text sx={memberTitle} align="left">{`Firmante ${i + 1
                            }`}</Text>
                        <div className="input-container">
                            <label>
                                <Text
                                    sx={labelStyles}
                                    align="left"
                                    mgButton
                                    variant="h6"
                                >
                                    Nombre completo
                                </Text>
                            </label>

                            <Autocomplete
                                {...register(`signers.${i}.memberfullname${i + 1}`, {
                                    required: true,
                                    maxLength: 120,
                                    pattern: validation.nombre
                                })}
                                onChangeAutoComplete={getPaymentMembers}
                                indexLabel={"full_name"}
                                freeSolo={true}
                                arrayValue={prevSigners}
                                placeholder={"Gustavo Pernia"}
                                name={`signers.${i}.memberfullname${i + 1}`}
                                id={`signers.${i}.memberfullname${i + 1}`}
                                customClass={
                                    (![null, undefined].includes(errors['signers']) &&
                                        ![null, undefined].includes(errors['signers'][i]) &&
                                        errors['signers'][i][`memberfullname${i + 1}`]) &&
                                    "input-container--error"
                                }
                                onChangeValue={(e) =>
                                    setValue(`signers.${i}.memberfullname${i + 1}`, e)
                                }
                                onSeledted={(e) => {
                                    setValue(
                                        `signers.${i}.memberfullname${i + 1}`,
                                        e["full_name"]
                                    );
                                    setValue(`signers.${i}.memberemail${i + 1}`, e["email"]);
                                    setValue(`signers.${i}.memberid${i + 1}`, e["RUT"]);
                                    setValue(
                                        `signers.${i}.membercellphone${i + 1}`,
                                        e["phone"]
                                    );
                                }}
                                sx={{
                                    background: "#f8faff",
                                    border: "2px solid #b4bacc",
                                    borderRadius: "7px",
                                }}
                            />
                            {(![null, undefined].includes(errors['signers']) &&
                                ![null, undefined].includes(errors['signers'][i])) &&
                                errors['signers'][i][`memberfullname${i + 1}`]?.type === "required" && (
                                    <span className="input--error">
                                        Este campo es obligatorio
                                    </span>
                                )}

                            {(![null, undefined].includes(errors['signers']) &&
                                ![null, undefined].includes(errors['signers'][i])) &&
                                errors['signers'][i][`memberfullname${i + 1}`]?.type === "maxLength" && (
                                    <span className="input--error">
                                        Solo estan permitidos 120 caracteres
                                    </span>
                                )}

                            {(![null, undefined].includes(errors['signers']) &&
                                ![null, undefined].includes(errors['signers'][i])) &&
                                errors['signers'][i][`memberfullname${i + 1}`]?.type === "pattern" && (
                                    <span className="input--error">
                                        No estan permitidos los caracteres especiales
                                    </span>
                                )}
                        </div>
                        <div className="input-container">
                            <label>
                                <Text
                                    sx={labelStyles}
                                    align="left"
                                    mgButton
                                    variant="h6"
                                >
                                    Correo electrónico
                                </Text>
                            </label>
                            <input
                                type="text"
                                className={
                                    (![null, undefined].includes(errors['signers']) &&
                                        ![null, undefined].includes(errors['signers'][i]) &&
                                        errors['signers'][i][`memberemail${i + 1}`]) &&
                                    "input-container--error"
                                }
                                placeholder="tucorreo@mail.com"
                                {...register(`signers.${i}.memberemail${i + 1}`, {
                                    required: true,
                                    pattern: validation.email,
                                    validate: () => {
                                        const duplicateEmail = searchDuplicate(
                                            payersMembers,
                                            "email"
                                        );

                                        const itemIndex = duplicateEmail.length
                                            ? duplicateEmail[
                                            duplicateEmail.length - 1
                                            ]["index"] + 1
                                            : undefined;

                                        return (
                                            !(
                                                duplicateEmail.length > 0 &&
                                                itemIndex === i + 1
                                            ) || "..."
                                        );
                                    },
                                })}
                                onChange={(e) => {
                                    setValue(`signers.${i}.memberemail${i + 1}`, e.target.value.trim());
                                    getPaymentMembers(e)
                                }}
                            />

                            {(![null, undefined].includes(errors['signers']) &&
                                ![null, undefined].includes(errors['signers'][i]) &&
                                errors['signers'][i][`memberemail${i + 1}`]?.type === "required") && (
                                    <span className="input--error">
                                        Este campo es obligatorio
                                    </span>
                                )}

                            {(![null, undefined].includes(errors['signers']) &&
                                ![null, undefined].includes(errors['signers'][i]) &&
                                errors['signers'][i][`memberemail${i + 1}`]?.type === "pattern") && (
                                    <span className="input--error">
                                        Ingresa un correo electrónico válido
                                    </span>
                                )}
                            {(![null, undefined].includes(errors['signers']) &&
                                ![null, undefined].includes(errors['signers'][i]) &&
                                errors['signers'][i][`memberemail${i + 1}`]?.type === "validate") && (
                                    <span className="input--error">
                                        Correo Duplicacdo
                                    </span>
                                )}
                        </div>
                        <div className="input-container">
                            <label>
                                <Text
                                    sx={labelStyles}
                                    align="left"
                                    mgButton
                                    variant="h6"
                                >
                                    RUT (sin puntos y con guión) o Pasaporte (solo números)
                                </Text>
                            </label>
                            <input
                                type="text"
                                className={
                                    (![null, undefined].includes(errors['signers']) &&
                                        ![null, undefined].includes(errors['signers'][i]) &&
                                        errors['signers'][i][`memberid${i + 1}`]) &&
                                    "input-container--error"
                                }
                                placeholder="11111111-1 ó 11111111"
                                {...register(`signers.${i}.memberid${i + 1}`, {
                                    required: true,
                                    validate: {

                                        isPassPortOrRut: () => {

                                            if ([checkRut(payersMembers[i].rutId), validation.passport.test(payersMembers[i].rutId)].includes(true)) {
                                                return true
                                            } else {
                                                return '...'
                                            }
                                        },

                                        rutDupli: () => {

                                            const duplicateRut = searchDuplicate(
                                                payersMembers,
                                                "rutId"
                                            );

                                            const itemIndex = duplicateRut.length
                                                ? duplicateRut[
                                                duplicateRut.length - 1
                                                ]["index"] + 1
                                                : undefined;

                                            return (
                                                !(
                                                    duplicateRut.length > 0 &&
                                                    itemIndex === i + 1
                                                ) || "..."
                                            );
                                        }

                                    },
                                })}
                                onChange={(e) => {
                                    setValue(`signers.${i}.memberid${i + 1}`, e.target.value.trim())
                                    getPaymentMembers(e)
                                }}
                            />

                            <span className="input--hint">Ej: 11111111-1 o 11111111</span>

                            {(![null, undefined].includes(errors['signers']) &&
                                ![null, undefined].includes(errors['signers'][i]) &&
                                errors['signers'][i][`memberid${i + 1}`]?.type === "required") && (
                                    <span className="input--error">
                                        Este campo es obligatorio
                                    </span>
                                )}

                            {(![null, undefined].includes(errors['signers']) &&
                                ![null, undefined].includes(errors['signers'][i]) &&
                                errors['signers'][i][`memberid${i + 1}`]?.type === "isPassPortOrRut") && (
                                    <span className="input--error">
                                        Ingresa un RUT o Pasaporte válido
                                    </span>
                                )}

                            {(![null, undefined].includes(errors['signers']) &&
                                ![null, undefined].includes(errors['signers'][i]) &&
                                errors['signers'][i][`memberid${i + 1}`]?.type === "rutDupli") && (
                                    <span className="input--error">
                                        RUT ó pasaporte Duplicado
                                    </span>
                                )}
                        </div>
                        <div className="input-container">
                            <label>
                                <Text
                                    sx={labelStyles}
                                    align="left"
                                    mgButton
                                    variant="h6"
                                >
                                    Número de celular
                                </Text>
                            </label>
                            <input
                                type="text"
                                className={
                                    (![null, undefined].includes(errors['signers']) &&
                                        ![null, undefined].includes(errors['signers'][i]) &&
                                        errors['signers'][i][`membercellphone${i + 1}`]) &&
                                    "input-container--error"
                                }
                                placeholder="+56912345478"
                                {...register(`signers.${i}.membercellphone${i + 1}`, {
                                    required: true,
                                    validate: {
                                        validPhoneNumber: (value) => validatePhoneNumber(value) || '...'
                                    }
                                })}
                                onChange={(e) => {
                                    setValue(`signers.${i}.membercellphone${i + 1}`, e.target.value.trim());
                                    getPaymentMembers(e)
                                }}
                            />
                            {(![null, undefined].includes(errors['signers']) &&
                                ![null, undefined].includes(errors['signers'][i]) &&
                                errors['signers'][i][`membercellphone${i + 1}`]?.type === "required") && (
                                    <span className="input--error">
                                        Este campo es obligatorio
                                    </span>
                                )}

                            {(![null, undefined].includes(errors['signers']) &&
                                ![null, undefined].includes(errors['signers'][i]) &&
                                errors['signers'][i][`membercellphone${i + 1}`]?.type === "validPhoneNumber") && (
                                    <span className="input--error">
                                        Ingresa un número de teléfono válido
                                    </span>
                                )}
                            {renderCheckboxForPayers(i)}
                        </div>
                    </div>
                );
            });
        }
    };

    const showPayersMembers = () => {
        return ![undefined, null, ''].includes(contractSignerSelected) && (
            <div
                className={`container--fixed ${payers.length
                    ? payers.length > 2
                        ? "signers-payment--show signers-payment__container--error"
                        : "signers-payment--show signers-payment__container"
                    : "signers-payment--hide signers-payment__container"
                    }`}
            >
                <Text sx={payoutTitleStyles} align="left">
                    {payers.length
                        ? "Responsables por el pago de este trámite"
                        : "Trámite sin responsables de pago"}
                </Text>
                <ul>
                    {payers.map((member, index) => {
                        const count = index + 1;

                        if (count > 2) {
                            return (
                                <li className="margin-top--lg messenger__container messenger__message--error">
                                    <Text
                                        sx={errorMessageStyles}
                                    >{`Solo puedes dividir el pago hasta ${maxAvailablePayers} firmantes`}</Text>
                                </li>
                            );
                        } else {
                            return (
                                <li key={count} className="flex--between">
                                    <Text sx={{ ...payoutMembersStyles, marginRight: '30px' }} align="left">
                                        {member.fullName}
                                    </Text>
                                    <Text
                                        sx={payoutMembersStyles}
                                        align="right"
                                    >
                                        {payers.length === 1
                                            ? `Pago total CLP ${parseInt(contractSignerSelected['fAmount'])} (100%)`
                                            : `Pago parcial CLP ${parseInt(contractSignerSelected['fAmount'] / 2)} (50%)`}
                                    </Text>
                                </li>
                            );
                        }
                    })}
                </ul>
            </div>
        );
    };

    const renderCheckboxForPayers = (index) => {
        return (
            <div className="flex--start margin-top--lg">

                <input
                    type="checkbox"
                    className={
                        errors[`memberpayment${index + 1}`] &&
                        "input-container--error"
                    }
                    {...register(`signers.${index}.memberpayment${index + 1}`)}
                    onChange={(e) => { getPaymentMembers(e); setValue(`signers.${index}.memberpayment${index + 1}`, e.target.checked) }}
                />

                <Text sx={labelStyles} align="left" mgButton variant="h6">
                    Este firmante realizara el pago del {Number(members) === 1 ? '100%' : '50% o 100%'} del trámite
                </Text>
            </div>
        );
    };

    const RenderSubmitButton = () => {

        if (payers.length > 2) {
            return (
                <Text
                    sx={buttonNoSubmitStyles}
                >{`Solo puedes dividir la cuenta hasta ${maxAvailablePayers} firmantes`}</Text>
            );
        }

        if (payers.length > 0 && Number(members) >= 1) {
            return (
                <Btn
                    variant="contained"
                    text="Continuar"
                    size="lg"
                    type="submit"
                    loading={loading}
                    endIcon={
                        !loading ? (
                            <BsChevronRight className="search-icon icon--xs icon--white" />
                        ) : null
                    }
                    sx={buttonSubmitStyles}
                />
            );
        } else {
            return (
                <>
                    <Text
                        sx={buttonNoSubmitStyles}
                    >{`Selecciona quienes serán responsables del pago`}</Text>
                    <Text>
                        Selecciona al menos 1 de los firmantes como responsable
                        del pago. Puedes dividir el pago hasta 2 firmantes.
                    </Text>
                </>
            );
        }
    };

    return (
        <Fragment>
            <Notificationbanner
                display={showNotification}
                notificationType={notificationType}
            >
                <strong>{notificationMessage}</strong>
            </Notificationbanner>
            {showPayersMembers()}
            <div className="procedure-creation__form-container">
                <div
                    className={['xs', 'sm'].includes(screenSizeHook) ? null : 'form__container'}
                    style={{ width: '100% !important' }}
                >
                    <div className="form-title__container">
                        <Text sx={titleStyles} align="center">
                            Datos del trámite
                        </Text>
                        <Text sx={hintStyles} align="center">
                            Ingrese los datos solicitados
                        </Text>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="input-container">
                            <label>
                                <Text
                                    sx={labelStyles}
                                    align="left"
                                    mgButton
                                    variant="h6"
                                >
                                    ¿Qué tipo de trámite necesitas hacer?
                                </Text>
                            </label>

                            <Autocomplete
                                {...register("proceduretype", {
                                    required: true,
                                })}
                                indexLabel={"name"}
                                placeholder={"Seleccionar"}
                                arrayValue={proceduresInputChoiceOptions}
                                name={`proceduretype`}
                                id={`proceduretype`}
                                customClass={
                                    errors[`proceduretype`] &&
                                    "input-container--error"
                                }
                                onChangeValue={(e) => { }}
                                onSeledted={(e) => {
                                    setValue("procedureid", "");
                                    setValue("proceduresignature", null);
                                    setValue(`proceduretype`, e["value"]);
                                    setProcedureSelected(e["value"]);
                                }}
                                sx={{
                                    background: "#f8faff",
                                    border: "2px solid #b4bacc",
                                    borderRadius: "7px",
                                }}
                            />

                            {errors.proceduretype?.type === "required" && (
                                <span className="input--error">
                                    Este campo es obligatorio
                                </span>
                            )}
                        </div>
                        <div className="input-container">
                            <label>
                                <Text
                                    sx={labelStyles}
                                    align="left"
                                    mgButton
                                    variant="h6"
                                >
                                    ¿Qué trámite necesitas gestionar?
                                </Text>
                            </label>
                            <Autocomplete
                                {...register("procedureid", { required: true })}
                                indexLabel={"name"}
                                placeholder={"Seleccionar"}
                                arrayValue={contractsInputChoiceOptions}
                                name={`procedureid`}
                                id={`procedureid`}
                                customClass={
                                    errors.procedureid &&
                                    "input-container--error"
                                }
                                onChangeValue={(e) => { }}
                                onSeledted={(e) => {
                                    setValue("proceduresignature", "");
                                    setValue(`procedureid`, e["value"]);
                                    setContractTypeSelected(e["value"]);
                                }}
                                sx={{
                                    background: "#f8faff",
                                    border: "2px solid #b4bacc",
                                    borderRadius: "7px",
                                }}
                            />
                            {errors.procedureid?.type === "required" && (
                                <span className="input--error">
                                    Este campo es obligatorio
                                </span>
                            )}
                        </div>
                        <div className="input-container">
                            <label>
                                <Text
                                    sx={labelStyles}
                                    align="left"
                                    mgButton
                                    variant="h6"
                                >
                                    ¿Cuantas personas firman el documento?
                                </Text>
                            </label>
                            <input
                                type="number"
                                onInput={resetInputs}
                                min={1}
                                max={10}
                                className={
                                    errors.proceduremembers &&
                                    "input-container--error"
                                }
                                {...register("proceduremembers", {
                                    required: true,
                                })}
                            />
                            {errors.proceduremembers?.type === "required" && (
                                <span className="input--error">
                                    Este campo es obligatorio
                                </span>
                            )}
                        </div>
                        <div className="input-container">
                            <label>
                                <Text
                                    sx={labelStyles}
                                    align="left"
                                    mgButton
                                    variant="h6"
                                >
                                    ¿Qué tipo de firma necesitas?
                                </Text>
                            </label>
                            <select
                                type="text"
                                onInput={(event) =>
                                    setSignatureTypeSelected(event.target.value)
                                }
                                className={
                                    errors.proceduresignature &&
                                    "input-container--error"
                                }
                                {...register("proceduresignature", {
                                    required: true,
                                })}
                            >
                                <option value="">Seleccionar</option>
                                {signaturesInputChoiceOptions()}
                            </select>
                            {errors.proceduresignature?.type === "required" && (
                                <span className="input--error">
                                    Este campo es obligatorio
                                </span>
                            )}
                            {nonSignaturesAvailables && (
                                <span className="input--error">
                                    No existen firmas electrónicas para la
                                    cantidad de firmantes en este contrato
                                </span>
                            )}
                        </div>

                        {signatureMember()}

                        <RenderSubmitButton />
                    </form>
                </div>
            </div>
            {displayModal()}
        </Fragment>
    );
};

export default Procedurecreationstep2;
