import { BiCloudUpload } from "react-icons/bi";
import { BsEnvelope } from "react-icons/bs";
import { FaCloudArrowDown } from "react-icons/fa6";
import { FaExclamationCircle } from "react-icons/fa";
import { Fragment, forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NumberFormat from 'react-number-format';

import { FaCopy } from "react-icons/fa";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Contact from "../molecules/contact";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import MuiAlert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Tracker from "../molecules/tracker";
import Typography from "@mui/material/Typography";
import useMediaQuery from '@mui/material/useMediaQuery';

import { ReactComponent as PayIcon } from "../icons/pay-icon.svg";
import { ReactComponent as ResetIcon } from "../icons/reset-icon.svg";
import { ReactComponent as SignedIcon } from "../icons/signed-icon.svg";
import { ReactComponent as TrashIcon } from "../icons/trash-icon.svg";
import { ReactComponent as UnsignedIcon } from "../icons/unsigned-icon.svg";
import { ReactComponent as UpdateIcon } from "../icons/update-icon.svg";
import { ReactComponent as UserIcon } from "../icons/user-icon.svg";
import { ReactComponent as WhatsAppIcon } from "../../../../assets/icons/whatsApp-icon.svg";
import { ReactComponent as DownloadOutlineDetailIcon } from "../../../../assets/icons/icon_download_outline_detail_tramit.svg";

import ScreenSizeHook from "../../../../hooks/ScreenSizeHook";
import { ExpressProcessStyles } from "../index.css";
import {
    clearDetailContract,
    deleteTramit,
    getDetailTramit,
    resetTramit,
    saveTramitExpressInfo,
} from "../../../../store/formExpressTramit";
import Spinner from "../../../spinners/Spinner";
import DeleteContractAlert from "../molecules/deleteContractAlert";
import { api, copyText, /* downloadFile, */ getErrorMsnWs, to, typeUser, verifiVariable } from "../../../../utils/enums";
import {
    downloadCompiledContractDoc,
    downloadCompiledDTE,
    sendEmailSign,
    sendSignerEmail,
} from "../../../../utils/api";
import ResetContractModal from "../molecules/resetContractModal";
import ErrorAlert from "../molecules/errorAlert";
import Btn from "../../../buttons/Button";
import { theme } from "../../../../theme/theme";
import ModalUploadNotaryDoc from "../../../modalUploadNotaryDoc/ModalUploadNotaryDoc";
import ModalContractUploadError from "../../../modalContractUploadError";
import ViewModalDocs from "../../signAuthorization/molecules/viewModalDocs";
import { saveTramitANFInfo } from "../../../../store/formANFTramit";
import { saveDetailsContract } from "../../../../store/contract/contractDetail";
import { Container } from "@mui/material";
import TramitDetail from "../../../tramitDetailData/TramitDetail/TramitDetail";
import DocumentInfo from "../../../tramitDetailData/documentInfo/DocumentInfo";

const stylesStatuSign = {
    marginTop: "6px",
    zoom: "1.6",
};

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomTitle = styled(Typography)((props) => ({
    fontSize: "0.95rem",
    color: `${props.theme.palette.primary.main} !important`,
    lineHeight: "1.2",
    fontFamily: ['"PoppinsBold"'].join(","),
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomWhatsAppButton = styled(Button)((props) => ({
    padding: "9px 15px",
    fontWeight: 600,
    fontFamily: ['"PoppinsRegular"'].join(","),
    textTransform: 'none',
    fontSize: ".6rem",
    borderRadius: 7,

    [theme.breakpoints.only("sm")]: {
        width: "40%",
    },

    [theme.breakpoints.not("sm")]: {
        width: "100%",
    },

    marginBottom: 15,
    marginTop: 15,
    backgroundColor: '#32c143',
    '&:hover': {
        backgroundColor: '#32c143'
    },
    "& svg": {
        marginBottom: "0 !important"
    }
}));

const CustomActionButton = styled(Button)((props) => ({
    width: '3.5rem',
    height: '3.125rem',
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "0.80rem",
    borderRadius: "7px",
    padding: '.813rem 1.25rem',
    backgroundColor: props.theme.palette.primary.light,
    borderColor: props.theme.palette.primary.light,
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        backgroundColor: props.theme.palette.primary.xlight,
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
    "& span.MuiButton-startIcon": {
        color: props.theme.palette.white.main,
        marginRight: "0px",
        marginLeft: "0px",
    },
}));

const CustomPaymentActionButton = styled(CustomActionButton)((props) => ({
    width: '65%',
    fontSize: '.875rem',
    "& span.MuiButton-startIcon": {
        marginRight: "8px"
    },
    [theme.breakpoints.up('md')]: {
        width: '60%',
    },
    [theme.breakpoints.up('lg')]: {
        width: '65%',
    }
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    color: theme.palette.text.secondary,
}));

const CustomTextTitle = styled(Typography)((props) => ({
    color: props.theme.palette.black.main,
    fontSize: "1rem",
    marginBottom: "0px",
    [theme.breakpoints.up('sm')]: {
        fontSize: "1.2rem",
        marginBottom: "0.35em",
    },
}));

const DetailProcedure = () => {
    const {
        login: { user, userInfo }
    } = useSelector((state) => state.root.session);
    const {
        document,
        status,
        detailContract,
        loading,
        //tramitExpressCreated
    } = useSelector((state) => state.root.formExpressTramit);
    const {
        contractCreator: { contractListActive }
    } = useSelector((state) => state.root.contract);
    const { allAnfTramit } = useSelector((state) => state.root.formANFTramit);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const Ref = useRef(null);
    const invalidContract = useRef(false);
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const screenSizeHook = ScreenSizeHook();
    const styles = ExpressProcessStyles({ document, screenSizeHook });
    const [textLoading, setTextLoading] = useState("");
    const [openDeleteContract, setOpenDeleteContract] = useState(0);
    const [openResetContract, setOpenResetContract] = useState(0);
    const [showError, setShowError] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [textSnackbar, setTextSnackbar] = useState("");
    const [downLoadingDoc, setDownLoadingDoc] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertMsn, setAlertMsn] = useState("");
    const [open, setOpen] = useState(false);
    const [loadingReminder, setLoadingReminder] = useState(false);
    const [openModalErrors, setOpenModalErrors] = useState(false);
    const [isDeleteTramit, setIsDeleteTramit] = useState(false);
    const [openModalNotary, setOpenModalNotary] = useState(false);
    const [timer, setTimer] = useState('00:00:00');
    const [payInfo, setPayInfo] = useState(undefined);
    const [docLog, setDocLog] = useState(null);
    const [openModalDocument, setOpenModalDocument] = useState({
        openModal: false,
        tramitType: '',
        download: false
    });

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, hours, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {

            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }

    const clearTimer = (e) => {

        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next    
        setTimer('00:00:60');

        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 60);
        return deadline;
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const ModalNotary = () => {
        const { token } = user;

        return (
            <ModalUploadNotaryDoc
                contract={state["sContractID"]}
                userToken={token}
                inList={true}
                openModalNotary={openModalNotary}
                setOpenModalNotary={setOpenModalNotary}
                hideButoomsModal={true}
                tramitType={'express'}
            />
        );
    };

    const handleChangeAppBarBottom = (event, value) => {
        console.log("event: ", event, "value: " + value);
    };

    const urlPay = (tokenPayment, sContractID) => {
        return `${api.BASE_URI}/webpay/create/${tokenPayment}/${sContractID}`;
    };

    const handleClickSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackbar(false);
    };

    const descargarDTE = async (email, sContractID) => {
        try {
            const { token } = user;

            const {
                data: { status },
            } = await downloadCompiledDTE(sContractID, token, email);

            if (status === "fail") {
                setAlertTitle("Archivo no disponible.");
                setAlertMsn(
                    "No se puede descargar el archivo porque no existe."
                );

                setShowError((e) => 1 + e);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const dataUpdate = () => {
        const { token } = user;

        dispatch(getDetailTramit(token, state["sContractID"]));
    };

    const sendReminder = async () => {
        try {
            setLoadingReminder(true);

            const { token } = user;

            setTextLoading("Enviado recordatorio a todos los firmantes");

            setTextSnackbar(
                "Se ha enviado un recordatorio a todos los firmantes"
            );

            const { status: sendEmailSignStatus } = await sendEmailSign(
                state["sContractID"],
                token
            );

            if ([sendEmailSignStatus].includes("fail")) {
                setAlertTitle(
                    "Ha surgido un problema al reenviar el contrato."
                );
                setAlertMsn(
                    "Podrías intentar nuevamente o comunícate con un administrador del sistema."
                );

                setShowError((e) => 1 + e);
            } else {
                dataUpdate();
                handleClickSnackbar();
            }

            setLoadingReminder(false);
        } catch (error) {
            setAlertTitle("Ha surgido un problema al reenviar el contrato.");
            setAlertMsn(
                "Podrías intentar nuevamente o comunícate con un administrador del sistema."
            );

            setLoadingReminder(false);
            setShowError((e) => 1 + e);
            console.log(error);
        }
    };

    const resetContract = () => {
        const { token } = user;
        dispatch(resetTramit(token, state["sContractID"]));
    };

    const deleteContract = () => {
        const { token } = user;
        dispatch(deleteTramit(token, state["sContractID"]));
    };

    const setEmailSigner = async (email) => {

        try {

            const { token } = user;

            setDownLoadingDoc(true);

            setTextSnackbar(
                "Se ha enviado un recordatorio al firmante"
            );

            setTextLoading("Enviando WhatsApp. El mensaje puede tardar unos minutos en llegar según la demanda. Gracias por tu paciencia.");

            const response = await sendSignerEmail(token, { email, sContractID: state["sContractID"] });

            if (response["status"] === "fail") {
                setAlertTitle("Problemas al enviar WhatsApp.");
                setAlertMsn(getErrorMsnWs(response["message"]["err"]));
                setShowError((e) => 1 + e);
            } else {
                handleClickSnackbar();
                clearTimer(getDeadTime());
            }

            setDownLoadingDoc(false);

        } catch (error) {
            setDownLoadingDoc(false);
            setAlertTitle("Problemas al enviar WhatsApp.");
            setAlertMsn(
                "Podrías intentar nuevamente o comunícate con un administrador del sistema."
            );
            setShowError((e) => 1 + e);
        }

    }

    // eslint-disable-next-line
    const downloadContract = async (open) => {
        try {
            const { token } = user;
            setDownLoadingDoc(true);
            setTextLoading("Descargando documento");

            const response = await downloadCompiledContractDoc(
                state["sContractID"],
                token,
                detailContract["contrato"][0]["sStatus"]
            );
            response && setDownLoadingDoc(false);
        } catch (error) {
            setDownLoadingDoc(false);
            setAlertTitle("Ha surgido un problema al descargar el documento.");
            setAlertMsn(
                "Podrías intentar nuevamente o comunícate con un administrador del sistema."
            );
            setShowError((e) => 1 + e);

            console.log(error);
        }
    };

    const handleShowDocument = (open, tramitType, download) => {
        setOpenModalDocument({
            openModal: open,
            tramitType: tramitType,
            download: download
        });
    };

    useEffect(() => {

        if (!verifiVariable(detailContract)) {

            let total = 0;
            let iva = 0;
            let notary = 0;
            let signature = 0;

            // setDetailContract(contractCreatedDetails);

            // setApproveSigner(contractCreatedDetails['firmantes'].map((item) => item['agree_draft']));

            detailContract["firmantes"].forEach((firmante) => {
                if (firmante.portion !== 0) {
                    total += parseInt(firmante.totalPay);
                    iva += parseInt(firmante.iva);
                    notary += parseInt(firmante.notaryPay);
                    signature += parseInt(firmante.amount);
                }
            });

            setPayInfo({
                total,
                iva,
                notary,
                signature,
            });

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailContract]);

    useEffect(() => {
        if (loading) setTextLoading("Actualizando información");
    }, [loading]);

    useEffect(() => {
        if (status === "fail") {
            setAlertTitle("Ha surgido un problema.");
            setAlertMsn(
                "Podrías intentar nuevamente o comunícate con un administrador del sistema."
            );
            setShowError((e) => 1 + e);
        } else if (status === "success" && isDeleteTramit === true) {
            const { message } = contractListActive;
            const findContract2 = [...allAnfTramit].find((item) => item["contractID"] === state["sContractID"]);
            dispatch(clearDetailContract());

            if (!verifiVariable(message) && typeof message === "object" && [...message].length !== 0) {
                const findContract1 = [...message].find((item) => item["sContractID"] === state["sContractID"]);
                if (!verifiVariable(findContract1)) dispatch(saveDetailsContract({ indexe: "contractListActive", value: { ...contractListActive, message: [...message].filter((item) => item["sContractID"] !== state["sContractID"]) } }));
            }

            if (!verifiVariable(findContract2)) dispatch(saveTramitANFInfo({ indexe: "allAnfTramit", value: [...allAnfTramit].filter((item) => item["contractID"] !== state["sContractID"]) }));

            navigate(to.EXPRESS_DOCUMENT_MANAGE, { state: { list: "EXPRESS" } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, isDeleteTramit]);

    useEffect(() => {

        if (!verifiVariable(state) && !verifiVariable(state["sContractID"])) {
            dataUpdate();
        } else {
            navigate(to.DASHBOARD_WELCOME);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {

        if (!verifiVariable(detailContract)) {

            const { contrato } = detailContract;

            if (verifiVariable(contrato) || contrato.length === 0) {
                invalidContract.current = true;
                setAlertTitle("Trámite no encontrado");
                setAlertMsn("El trámite ha sido eliminado o no existe.");
                setShowError((value) => 1 + value);
            }

        }

        /* return () => {
            dispatch(saveTramitExpressInfo({ indexe: "detailContract", value: undefined }));
        } */

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailContract]);


    return (
        <Container maxWidth={'xl'} className={styles.contentMiu}>
            <Box className={styles.mainContent}>
                <ViewModalDocs
                    docLog={docLog}
                    openModal={openModalDocument.openModal}
                    setOpenModal={setOpenModalDocument}
                    download={openModalDocument.download}
                    tramitType={openModalDocument.tramitType}
                />

                <ErrorAlert
                    msn={alertMsn}
                    title={alertTitle}
                    errors={showError}
                    onClose={() => {
                        if (invalidContract.current === true) {
                            navigate(-1);
                            dispatch(saveTramitExpressInfo({ indexe: "detailContract", value: undefined }));
                        }
                    }}
                />

                <ModalContractUploadError
                    openModalErrors={openModalErrors}
                    setOpenModalErrors={setOpenModalErrors}
                    data={["Este trámite no se puede eliminar porque está en proceso de revisión, pago o firma."]}
                    modalIcon={'warnIcon'}
                />

                {!verifiVariable(state) && !verifiVariable(state["sContractID"]) && (<ModalNotary />)}

                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{
                        horizontal: "center",
                        vertical: "bottom",
                    }}
                >
                    <Alert
                        className={styles.muiAlert}
                        onClose={handleCloseSnackbar}
                        severity="success"
                    >
                        {textSnackbar}
                    </Alert>
                </Snackbar>

                <ResetContractModal
                    openResetContract={openResetContract}
                    resetCurrentContract={() => {
                        setTextLoading("Reseteando Tramite");
                        resetContract();
                    }}
                />

                <DeleteContractAlert
                    openDeleteContract={openDeleteContract}
                    deleteCurrentContract={() => {
                        setTextLoading("Eliminando Tramite");
                        setIsDeleteTramit(true);
                        deleteContract();
                    }}
                />

                {(loading || downLoadingDoc || loadingReminder) && (
                    <Spinner
                        loading={true}
                        type="bar"
                        text={textLoading}
                        opacity={true}
                    />
                )}

                <Dialog
                    fullWidth={true}
                    TransitionComponent={Transition}
                    open={open}
                    onClose={handleClose}
                    maxWidth="xs"
                    className={styles.mainContainerDialog}
                >
                    <DialogTitle sx={{ textAlign: "center" }}>
                        ¿Estás seguro que deseas realizar esta acción?
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            Todos los firmantes recibiran nuevamente el correo de
                            firma.
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions
                        style={{ justifyContent: "center", paddingBottom: "20px" }}
                    >
                        <Btn
                            variant="outlined"
                            text="Cancelar"
                            color="error"
                            onClick={() => handleClose()}
                            sx={{
                                height: "39px",
                                borderRadius: "20px",
                            }}
                        />

                        <Btn
                            variant="outlined"
                            text="Aceptar"
                            color="primary"
                            onClick={() => {
                                sendReminder();
                                handleClose();
                            }}
                            sx={{
                                height: "39px",
                                borderRadius: "20px",
                                marginLeft: "30px !important",
                            }}
                        />
                    </DialogActions>
                </Dialog>

                <Grid container spacing={2} className={styles.mainContent_Container}>
                    {isMobile && (
                        <Grid item xs={12}>
                            {!verifiVariable(detailContract) &&
                                !verifiVariable(detailContract['contrato']) &&
                                !verifiVariable(detailContract['contrato'][0]) && (
                                    <div
                                        className={`${styles.contentTracker} content-detail__container`}
                                    >
                                        <Tracker
                                            status={
                                                detailContract["contrato"][0]["sStatus"]
                                            }
                                            paymentStatus={detailContract["contrato"][0]["sPaymentStatus"]}
                                        />
                                    </div>
                                )}
                        </Grid>
                    )}

                    {!isMobile && (
                        <Grid item xs={12} sm={8}>
                            {!verifiVariable(detailContract) &&
                                !verifiVariable(detailContract['contrato']) &&
                                !verifiVariable(detailContract['contrato'][0]) && (
                                    <div
                                        className={`${styles.contentTracker} content-detail__container`}
                                    >
                                        <Tracker
                                            status={
                                                detailContract["contrato"][0]["sStatus"]
                                            }
                                        />
                                    </div>
                                )}

                            <Item
                                className={`${styles.contenItem} ${styles.formItem}`}
                            >
                                <Box className={styles.spacinContent}>
                                    <Typography
                                        variant="h5"
                                        gutterBottom
                                        className={styles.titleFV}
                                    >
                                        Bienvenido a <br /> FirmaVirtual{" "}
                                        <span>express</span>
                                    </Typography>

                                    <div className={styles.optionsTramit}>
                                        {
                                            /* !verifiVariable(detailContract) &&
                                            !verifiVariable(detailContract['contrato']) &&
                                            !verifiVariable(detailContract['contrato'][0]) &&
                                            !verifiVariable(userInfo) &&
                                            userInfo["iProfileID"] === typeUser.ADMIN &&
                                            ["IN_NOTARY"].includes(detailContract["contrato"][0]["sStatus"]) &&
                                            detailContract["contrato"][0]["withDocument"] === 1 && */
                                            (
                                                <Tooltip
                                                    title={
                                                        <Typography
                                                            variant="subtitle1"
                                                            color={["white.main"]}
                                                            gutterBottom
                                                        >
                                                            Restablecer el contrato permite
                                                            reiniciar el proceso y
                                                            devolverlo a su estado inicial.
                                                            Útil cuando es necesario
                                                            corregir el documento y volver a
                                                            enviarlo a los participantes
                                                            para que lo aprueben.
                                                        </Typography>
                                                    }
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{ timeout: 600 }}
                                                    arrow
                                                >
                                                    <Button
                                                        disabled
                                                        onClick={() =>
                                                            setOpenResetContract(
                                                                (e) => e + 1
                                                            )
                                                        }
                                                        variant="outlined"
                                                        startIcon={<ResetIcon />}
                                                        size="small"
                                                        className={styles.buttonTramit}
                                                        sx={{ margin: .4 }}
                                                    >
                                                        Resetear contrato
                                                    </Button>
                                                </Tooltip>
                                            )
                                        }

                                        {!verifiVariable(detailContract) &&
                                            !verifiVariable(detailContract['contrato']) &&
                                            !verifiVariable(detailContract['contrato'][0]) && (<Tooltip
                                                title={
                                                    <Typography
                                                        variant="subtitle1"
                                                        color={["white.main"]}
                                                        gutterBottom
                                                    >
                                                        Elimina el trámite
                                                        definitivamente. Este no podrá
                                                        ser recuperado
                                                    </Typography>
                                                }
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                arrow
                                            >
                                                <Button
                                                    onClick={() => {
                                                        if (['REVIEW', 'CREATED', 'REJECTED'].includes(detailContract["contrato"][0]["sStatus"])) {
                                                            setOpenDeleteContract(
                                                                (e) => e + 1
                                                            );
                                                        } else {
                                                            setOpenModalErrors(true);
                                                        }
                                                    }}
                                                    variant="outlined"
                                                    disabled={!['REVIEW', 'CREATED', 'REJECTED'].includes(detailContract["contrato"][0]["sStatus"])}
                                                    startIcon={<TrashIcon />}
                                                    size="small"
                                                    className={styles.buttonTramit}
                                                    sx={{ margin: .4 }}
                                                >
                                                    Eliminar trámite
                                                </Button>
                                            </Tooltip>)}

                                        <Button
                                            variant="outlined"
                                            startIcon={<UpdateIcon />}
                                            onClick={() => dataUpdate()}
                                            size="small"
                                            className={styles.buttonTramit}
                                            sx={{ margin: .4 }}
                                        >
                                            Actualizar seguimiento
                                        </Button>

                                        {
                                            !verifiVariable(detailContract) &&
                                            !verifiVariable(detailContract['contrato']) &&
                                            !verifiVariable(detailContract['contrato'][0]) &&
                                            !verifiVariable(userInfo) &&
                                            userInfo["iProfileID"] === typeUser.ADMIN &&
                                            ["IN_NOTARY"].includes(detailContract["contrato"][0]["sStatus"]) &&
                                            detailContract["contrato"][0]["withDocument"] === 1 && (
                                                <Button
                                                    variant="outlined"
                                                    startIcon={<BiCloudUpload />}
                                                    onClick={() => setOpenModalNotary(true)}
                                                    size="small"
                                                    className={styles.buttonTramit}
                                                    sx={{ margin: .4 }}
                                                >
                                                    Subir documento notariado
                                                </Button>
                                            )
                                        }

                                        {
                                            !verifiVariable(detailContract) &&
                                            !verifiVariable(detailContract['contrato']) &&
                                            !verifiVariable(detailContract['contrato'][0]) &&
                                            !verifiVariable(userInfo) &&
                                            userInfo["iProfileID"] === typeUser.ADMIN &&
                                            ["SIGNED_PENDING"].includes(detailContract["contrato"][0]["sStatus"]) &&
                                            detailContract["contrato"][0]["withDocument"] === 1 && (
                                                <Tooltip
                                                    title={
                                                        <Typography
                                                            variant="subtitle1"
                                                            color={["white.main"]}
                                                            gutterBottom
                                                        >
                                                            Reenvia correo de
                                                            firmas, los
                                                            participantes deberán
                                                            realizar el proceso de
                                                            firma nuevamente.
                                                        </Typography>
                                                    }
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{
                                                        timeout: 600,
                                                    }}
                                                    arrow
                                                >
                                                    <Button
                                                        variant="outlined"
                                                        startIcon={<BsEnvelope />}
                                                        onClick={() =>
                                                            handleClickOpen()
                                                        }
                                                        size="small"
                                                        className={
                                                            styles.buttonTramit
                                                        }
                                                        sx={{ margin: .4 }}
                                                    >
                                                        Reenviar firma
                                                    </Button>
                                                </Tooltip>
                                            )}

                                        {
                                            !verifiVariable(detailContract) &&
                                            !verifiVariable(detailContract['contrato']) &&
                                            !verifiVariable(detailContract['contrato'][0]) &&
                                            !verifiVariable(userInfo) &&
                                            /* userInfo["iProfileID"] === typeUser.ADMIN && */
                                            ["FINISHED"].includes(detailContract["contrato"][0]["sStatus"]) &&
                                            detailContract["contrato"][0]["withDocument"] === 1 &&
                                            (
                                                <Tooltip
                                                    title={
                                                        <Typography
                                                            variant="subtitle1"
                                                            color={["white.main"]}
                                                            gutterBottom
                                                        >
                                                            Descargar documento notariado.
                                                        </Typography>
                                                    }
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{
                                                        timeout: 600,
                                                    }}
                                                    arrow
                                                >

                                                    <Button
                                                        variant="outlined"
                                                        startIcon={<DownloadOutlineDetailIcon style={{ width: 23, height: 23 }} />}
                                                        onClick={() => {
                                                            setDocLog(null);
                                                            handleShowDocument(true, 'express', true);
                                                        }}
                                                        size="small"
                                                        className={
                                                            styles.buttonTramit
                                                        }
                                                        sx={{ margin: .4 }}
                                                    >
                                                        Documento Notariado
                                                    </Button>

                                                </Tooltip>
                                            )
                                        }
                                    </div>

                                    <div className={styles.numberTitle}>
                                        <Typography
                                            className={styles.stepNumbers}
                                            variant="h5"
                                            gutterBottom
                                        >
                                            1
                                        </Typography>

                                        <CustomTextTitle
                                            variant="h6"
                                            gutterBottom
                                            className={styles.detailTextTitle}
                                        >
                                            Datos personales del solicitante <br />
                                            <span>Mis datos personales</span>
                                        </CustomTextTitle>
                                    </div>

                                    <div className={styles.infoOwner}>
                                        <UserIcon />

                                        <div>
                                            <CustomTextTitle
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Nombre
                                            </CustomTextTitle>

                                            <CustomTextTitle
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Correo electrónico
                                            </CustomTextTitle>

                                            <CustomTextTitle
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Teléfono
                                            </CustomTextTitle>
                                        </div>

                                        {
                                            !verifiVariable(detailContract) &&
                                            !verifiVariable(detailContract['contrato']) &&
                                            !verifiVariable(detailContract['contrato'][0]) &&
                                            !verifiVariable(
                                                detailContract["owner"]
                                            ) && (
                                                <div>
                                                    <CustomTextTitle
                                                        variant="body1"
                                                        gutterBottom
                                                    >
                                                        {
                                                            detailContract["owner"][
                                                            "full_name"
                                                            ]
                                                        }
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="body1"
                                                        gutterBottom
                                                    >
                                                        {
                                                            detailContract["owner"][
                                                            "email"
                                                            ]
                                                        }
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="body1"
                                                        gutterBottom
                                                    >
                                                        {
                                                            detailContract["owner"][
                                                            "phone"
                                                            ]
                                                        }
                                                    </CustomTextTitle>
                                                </div>
                                            )}
                                    </div>

                                    <TramitDetail
                                        tramitID={detailContract?.["contrato"]?.[0]?.["autoId"]}
                                        tramitType={detailContract?.["contrato"]?.[0]?.["sTipoContrato"] || "No especificado"}
                                        participantsNumber={detailContract?.["firmantes"]?.length}
                                        tramitName={detailContract?.["contrato"]?.[0]?.["sContractName"] || 'No especificado'}
                                    />

                                    <DocumentInfo
                                        sectionTitle={detailContract?.contrato[0]?.sStatus === 'FINISHED'}
                                        signLog={
                                            detailContract?.['contrato']?.[0] &&
                                            ["FINISHED", "IN_NOTARY", "SIGNED"].includes(detailContract["contrato"][0]["sStatus"])
                                        }
                                        isReadyToView={
                                            detailContract?.['contrato']?.[0] &&
                                            !["FINISHED", "IN_NOTARY", "SIGNED"].includes(
                                                detailContract["contrato"][0]["sStatus"]
                                            )
                                        }
                                        isReadyToDownload={detailContract?.['archivos']?.[0]}
                                        isReplaceable={
                                            detailContract?.['contrato']?.[0] &&
                                            (
                                                userInfo.iProfileID === typeUser.ADMIN ||
                                                ['CREATED'].includes(detailContract?.["contrato"]?.[0]?.["sStatus"])
                                            )
                                        }
                                        viewDocument={() => {
                                            setDocLog(null);
                                            handleShowDocument(true, 'express', true);
                                        }}
                                        downloadDocument={() => {
                                            setDocLog(null);
                                            handleShowDocument(true, 'express', true)
                                        }}
                                        downloadLogDocument={() => {
                                            setDocLog(true);
                                            handleShowDocument(true, 'express', true);
                                        }}
                                        replaceDocument={() => navigate(to.EXPRESS_REPLACE_DOCUMENT)}
                                        documentName={detailContract?.["archivos"]?.[0]?.["file_name"] || 'No especificado'}
                                    />

                                    <Box>
                                        <div className={styles.numberTitle}>
                                            <Typography
                                                className={styles.stepNumbers}
                                                variant="h5"
                                                gutterBottom
                                            >
                                                4
                                            </Typography>

                                            <CustomTextTitle
                                                variant="h6"
                                                gutterBottom
                                                className={styles.detailTextTitle}
                                            >
                                                Resúmen del pedido<br />
                                                <span>
                                                    Total a pagar
                                                </span>
                                            </CustomTextTitle>
                                        </div>

                                        {!verifiVariable(payInfo) && (
                                            <Box className={styles.orderInfo}>

                                                <Box className={"service-info"}>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom
                                                    >
                                                        Servicio de notaría
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['notary']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomTextTitle>

                                                </Box>

                                                <br />

                                                <Box className={"service-info"}>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom
                                                    >
                                                        Servicio de firma electrónica
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['signature']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomTextTitle>

                                                </Box>

                                                <br />

                                                <Box className={"service-info"}>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom
                                                    >
                                                        IVA
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['iva']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomTextTitle>

                                                </Box>

                                                <br />

                                                <Box className={"service-info-total"}>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom
                                                        mr={4}
                                                    >
                                                        Total
                                                    </CustomTextTitle>

                                                    <CustomTextTitle
                                                        variant="h6"
                                                        gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['total']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomTextTitle>

                                                </Box>

                                            </Box>
                                        )}
                                    </Box>

                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className={`${styles.titleFV} ${styles.bottonTitle}`}
                                    >
                                        Para más información comunícate con nuestro
                                        equipo de <span>soporte al cliente</span>
                                    </Typography>

                                    <div className={styles.contactInfo}>
                                        <Contact />
                                    </div>
                                </Box>
                            </Item>
                        </Grid>
                    )}

                    {isMobile && (
                        <Grid
                            item
                            xs={12}
                            className={styles.mainContentParticipants}
                        >
                            <Item
                                className={`${styles.contenItem} ${styles.infoItem} ${styles.textXs}`}
                            >
                                <Typography
                                    variant="h5"
                                    gutterBottom
                                    className={styles.titleFV}
                                >
                                    Bienvenido a <br /> FirmaVirtual{" "}
                                    <span>express</span>
                                </Typography>
                            </Item>
                        </Grid>
                    )}

                    <Grid
                        item
                        xs={12}
                        md={4}
                        className={styles.mainContentParticipants}
                    >
                        {(!verifiVariable(detailContract) &&
                            !verifiVariable(detailContract['contrato']) &&
                            !verifiVariable(detailContract['contrato'][0])
                            ? [...detailContract["firmantes"]]
                            : []
                        ).map((item, index) => {
                            return (
                                <Fragment key={item["email"] + index}>
                                    <Item className={` ${styles.cardFirmUser}`}>
                                        <Box>
                                            <UserIcon />
                                        </Box>

                                        <div id="textInfoParticipans">
                                            <Typography variant="h6" gutterBottom>
                                                Participante {index + 1}
                                            </Typography>

                                            <Typography className="textEmail" variant="h6" gutterBottom>
                                                {item["email"]}
                                            </Typography>

                                            <Typography variant="h6" gutterBottom>
                                                {item["full_name"].replace(
                                                    /\b\w/g,
                                                    (l) => l.toUpperCase()
                                                )}
                                            </Typography>

                                            <Typography variant="h6" gutterBottom>
                                                {item["phone"]}
                                            </Typography>

                                            <Typography variant="h6">
                                                {item["RUT"]}
                                            </Typography>

                                            <Box className={styles.roleItem} gutterBottom>
                                                {item['rol'] === 0 && item['portion'] === '0.00' &&
                                                    <Typography variant="h6">
                                                        Firmante
                                                    </Typography>}
                                                {item['rol'] === 0 && item['portion'] !== '0.00' &&
                                                    <Typography variant="h6">
                                                        Pagador Firmante
                                                    </Typography>}
                                                {item['rol'] === 3 &&
                                                    <Typography variant="h6">
                                                        Pagador
                                                    </Typography>}
                                                {item['rol'] === 5 &&
                                                    <Typography variant="h6">
                                                        Copia
                                                    </Typography>}
                                            </Box>

                                            {item["portion"] !== "0.00" && (
                                                <Fragment>
                                                    {item["payment"] ===
                                                        "PENDING" && (
                                                            <div
                                                                className={styles.statusPay}
                                                            >
                                                                <FaExclamationCircle className="icon--blue" />

                                                                <CustomTitle>
                                                                    Pendiente de pago
                                                                </CustomTitle>
                                                            </div>
                                                        )}

                                                    {item["payment"] ===
                                                        "PAYOUT" && (
                                                            <Fragment>
                                                                <div
                                                                    className={styles.statusPay}
                                                                >
                                                                    <FaExclamationCircle className="icon--blue-light" />

                                                                    <CustomTitle>
                                                                        Pagado
                                                                    </CustomTitle>
                                                                </div>

                                                                <Tooltip
                                                                    title={
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            color={["white.main"]}
                                                                            gutterBottom
                                                                        >
                                                                            Descargar DTE
                                                                        </Typography>
                                                                    }
                                                                    TransitionComponent={Fade}
                                                                    TransitionProps={{ timeout: 600 }}
                                                                    arrow
                                                                >

                                                                    <Button
                                                                        size="small"
                                                                        sx={{
                                                                            fontFamily: "PoppinsBold",
                                                                            textTransform: "none",
                                                                            marginTop: ".625rem",
                                                                            height: '3.125rem',
                                                                            backgroundColor: theme.palette.primary.light,
                                                                            borderRadius: '7px',
                                                                            fontSize: ".8rem",
                                                                            fontWeight: 600,

                                                                            [theme.breakpoints.only("sm")]: {
                                                                                width: "40%",
                                                                            },

                                                                            [theme.breakpoints.not("sm")]: {
                                                                                width: "75%",
                                                                            },

                                                                            span: {
                                                                                svg: { fill: "white !important" },
                                                                            },
                                                                        }}
                                                                        variant="contained"
                                                                        endIcon={
                                                                            <FaCloudArrowDown className="icon--blue-light" />
                                                                        }
                                                                        onClick={() => {
                                                                            descargarDTE(
                                                                                item[
                                                                                "email"
                                                                                ],
                                                                                detailContract[
                                                                                "contrato"
                                                                                ][0][
                                                                                "sContractID"
                                                                                ]
                                                                            );
                                                                        }}
                                                                    >
                                                                        Descargar DTE
                                                                    </Button>
                                                                </Tooltip>
                                                            </Fragment>
                                                        )}
                                                </Fragment>
                                            )}

                                            {[1, 0].includes(item["rol"]) && (
                                                <Fragment>

                                                    {
                                                        !verifiVariable(detailContract)
                                                        && [
                                                            "NOTHING",
                                                            "SIGNED_PENDING",
                                                            "DELIVERED",
                                                            "IN_NOTARY",
                                                            "FINISHED",
                                                        ].includes(detailContract["contrato"][0]["sStatus"])
                                                        &&
                                                        item["sign_status"] === "SIGNED" && (
                                                            <div
                                                                className="flex--center"
                                                                style={stylesStatuSign}
                                                            >
                                                                <SignedIcon style={{ width: '9rem' }} />
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        [
                                                            "NOTHING",
                                                            "DELIVERED",
                                                            "SIGNED_PENDING",
                                                        ].includes(detailContract["contrato"][0]["sStatus"])
                                                        && [
                                                            "NOTHING",
                                                            "NOTIFIED",
                                                            "VIEW",
                                                            "DECLINED",
                                                            "BOUNCED",
                                                            "FORWARDED",
                                                            "CANCELLED",
                                                        ].includes(item["sign_status"]
                                                        ) && (
                                                            <div
                                                                className="flex--center"
                                                                style={
                                                                    stylesStatuSign
                                                                }
                                                            >
                                                                <UnsignedIcon style={{ width: '9rem' }} />
                                                            </div>
                                                        )
                                                    }

                                                </Fragment>
                                            )}

                                            {detailContract["contrato"][0]["sStatus"] === "SIGNED_PENDING" &&
                                                item["rol"] === 0 &&
                                                item["sign_status"] !== "SIGNED" &&
                                                (
                                                    <Tooltip
                                                        title={
                                                            <Typography
                                                                variant="subtitle1"
                                                                color={["white.main"]}
                                                                gutterBottom
                                                            >
                                                                Reenviar link de firma.
                                                            </Typography>
                                                        }
                                                        TransitionComponent={Fade}
                                                        TransitionProps={{ timeout: 600 }}
                                                        arrow
                                                    >
                                                        <CustomWhatsAppButton
                                                            disabled={timer === '00:00:00' ? false : true}
                                                            variant="contained"
                                                            onClick={() => setEmailSigner(item["email"])}
                                                            startIcon={<WhatsAppIcon />}
                                                        >
                                                            {timer !== '00:00:00' ? `${timer.replace('00:00:', '')} seg` : "Reenviar firma a participante"}
                                                        </CustomWhatsAppButton>
                                                    </Tooltip>
                                                )
                                            }
                                        </div>

                                    </Item>

                                    <Box className="actionParticipantsButtons">

                                        <Box
                                            className={styles.contentActionParticipants}
                                            sx={{ justifyContent: "space-between" }}
                                        >
                                            {
                                                !verifiVariable(detailContract)
                                                && !verifiVariable(detailContract["contrato"][0])
                                                && [0, 3].includes(item["rol"])
                                                && item["portion"] !== "0.00"
                                                && item["payment"] !== 'PAYOUT'
                                                && detailContract["contrato"][0]["sPaymentModal"] !== "AFTER_PAYMENT"
                                                && (
                                                    <Fragment>
                                                        <Tooltip
                                                            title={
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    color={["white.main"]}
                                                                >
                                                                    Pagar trámite.
                                                                </Typography>
                                                            }
                                                            TransitionComponent={Fade}
                                                            TransitionProps={{ timeout: 600 }}
                                                            arrow
                                                        >
                                                            <CustomPaymentActionButton
                                                                className="pay-icon"
                                                                onClick={() => {
                                                                    window.open(
                                                                        urlPay(
                                                                            item["token_payment"],
                                                                            detailContract["contrato"][0]
                                                                            ["sContractID"]
                                                                        ),
                                                                        "_blank"
                                                                    );
                                                                }}
                                                                startIcon={<PayIcon />}
                                                                variant="contained"
                                                            >
                                                                Pagar
                                                            </CustomPaymentActionButton>
                                                        </Tooltip>

                                                        <Tooltip
                                                            title={
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    color={["white.main"]}
                                                                >
                                                                    Copiar link de pago.
                                                                </Typography>
                                                            }
                                                            TransitionComponent={Fade}
                                                            TransitionProps={{ timeout: 600 }}
                                                            arrow
                                                        >
                                                            <CustomActionButton
                                                                onClick={() => {
                                                                    copyText(urlPay(
                                                                        item["token_payment"],
                                                                        detailContract["contrato"][0]
                                                                        ["sContractID"]
                                                                    ))
                                                                    setTextSnackbar("El link de pago ha sido copiado, puedes enviárselo a el firmante.");
                                                                    handleClickSnackbar();
                                                                }}
                                                                startIcon={<FaCopy />}
                                                                variant="contained"
                                                                color="primary"
                                                            />

                                                        </Tooltip>
                                                    </Fragment>
                                                )}
                                        </Box>

                                    </Box>

                                </Fragment>

                            );
                        })}
                    </Grid>

                    {isMobile && (
                        <Fragment>

                            <Grid
                                item
                                xs={12}
                                className={styles.mainContentParticipants}
                            >

                                <div className={styles.numberTitle}>
                                    <Typography
                                        className={styles.stepNumbers}
                                        variant="h5"
                                        gutterBottom
                                    >
                                        1
                                    </Typography>

                                    <CustomTextTitle
                                        variant="h6"
                                        gutterBottom
                                        className={styles.detailTextTitle}
                                    >
                                        Datos personales  del solicitante<br />
                                        <span className={styles.detailTextSecondaryTitle}>Mis datos personales</span>
                                    </CustomTextTitle>
                                </div>

                                <Item
                                    className={`${styles.contenItem} ${styles.infoItem} ${styles.cardTypeSignatureXs}`}
                                >

                                    <UserIcon style={{ width: '4rem !important', height: '4rem !important' }} />

                                    <Box className={styles.ownerData_Info_Box}>
                                        <Box className={styles.ownerData_Info_textBox}>
                                            <CustomTextTitle
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Nombre
                                            </CustomTextTitle>

                                            <CustomTextTitle
                                                variant="body1"
                                                gutterBottom
                                                className={styles.ownerData_Info_Box_MainText}
                                            >
                                                {detailContract?.owner?.full_name || 'No especificado'}
                                            </CustomTextTitle>
                                        </Box>

                                        <Box className={styles.ownerData_Info_textBox}>
                                            <CustomTextTitle
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Correo electrónico
                                            </CustomTextTitle>

                                            <CustomTextTitle
                                                variant="body1"
                                                gutterBottom

                                                className={styles.ownerData_Info_Box_MainText}
                                            >
                                                {detailContract?.owner?.email || 'No especificado'}
                                            </CustomTextTitle>
                                        </Box>

                                        <Box className={styles.ownerData_Info_textBox}>
                                            <CustomTextTitle
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Teléfono
                                            </CustomTextTitle>

                                            <CustomTextTitle
                                                variant="body1"
                                                gutterBottom
                                                className={styles.ownerData_Info_Box_MainText}
                                            >
                                                {detailContract?.owner?.phone || 'No especificado'}
                                            </CustomTextTitle>
                                        </Box>

                                    </Box>

                                </Item>
                            </Grid>

                            <TramitDetail
                                tramitID={detailContract?.["contrato"]?.[0]?.["autoId"]}
                                tramitType={detailContract?.["contrato"]?.[0]?.["sTipoContrato"] || "No especificado"}
                                participantsNumber={detailContract?.["firmantes"]?.length}
                                tramitName={detailContract?.["contrato"]?.[0]?.["sContractName"] || 'No especificado'}
                            />

                            <DocumentInfo
                                sectionTitle={detailContract?.contrato[0]?.sStatus === 'FINISHED'}
                                signLog={
                                    detailContract?.['contrato']?.[0] &&
                                    ["FINISHED", "IN_NOTARY", "SIGNED"].includes(detailContract["contrato"][0]["sStatus"])
                                }
                                isReadyToView={
                                    detailContract?.['contrato']?.[0] &&
                                    !["FINISHED", "IN_NOTARY", "SIGNED"].includes(
                                        detailContract["contrato"][0]["sStatus"]
                                    )
                                }
                                isReadyToDownload={detailContract?.['archivos']?.[0]}
                                isReplaceable={
                                    detailContract?.['contrato']?.[0] &&
                                    (
                                        userInfo.iProfileID === typeUser.ADMIN ||
                                        ['CREATED'].includes(detailContract?.["contrato"]?.[0]?.["sStatus"])
                                    )
                                }
                                viewDocument={() => {
                                    setDocLog(null);
                                    handleShowDocument(true, 'express', true);
                                }}
                                downloadDocument={() => {
                                    setDocLog(null);
                                    handleShowDocument(true, 'express', true);
                                }}
                                replaceDocument={() => { navigate(to.EXPRESS_REPLACE_DOCUMENT) }}
                                downloadLogDocument={() => {
                                    setDocLog(true);
                                    handleShowDocument(true, 'express', true);
                                }}
                                documentName={detailContract?.["archivos"]?.[0]?.["file_name"] || 'No especificado'}
                            />

                            <Grid
                                item
                                xs={12}
                                className={styles.mainContentParticipants}
                            >

                                <div className={styles.numberTitle}>
                                    <Typography
                                        className={styles.stepNumbers}
                                        variant="h5"
                                        gutterBottom
                                    >
                                        4
                                    </Typography>

                                    <CustomTextTitle
                                        variant="h6"
                                        gutterBottom
                                        className={styles.detailTextTitle}
                                    >
                                        Resumen del pedido <br />
                                        <span className={styles.detailTextSecondaryTitle}>Total a pagar</span>
                                    </CustomTextTitle>
                                </div>

                                <Item
                                    className={`${styles.contenItem} ${styles.infoItem}`}
                                >
                                    {!verifiVariable(payInfo) && (
                                        <Box className={styles.orderInfo}>

                                            <Box className={"service-info"}>

                                                <CustomTextTitle
                                                    variant="h6"
                                                    gutterBottom
                                                    className={styles.ownerData_Info_Box_MainText}
                                                >
                                                    Servicio de notaría
                                                </CustomTextTitle>

                                                <CustomTextTitle
                                                    variant="h6"
                                                    gutterBottom
                                                    className={styles.ownerData_Info_Box_MainText}
                                                >
                                                    <NumberFormat
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        prefix={"$"}
                                                        value={payInfo['notary']}
                                                        displayType={"text"}
                                                        renderText={(value, props) => value}
                                                    />
                                                </CustomTextTitle>

                                            </Box>

                                            <br />

                                            <Box className={"service-info"}>

                                                <CustomTextTitle
                                                    variant="h6"
                                                    gutterBottom
                                                    className={styles.ownerData_Info_Box_MainText}
                                                >
                                                    Servicio de firma electrónica
                                                </CustomTextTitle>

                                                <CustomTextTitle
                                                    variant="h6"
                                                    gutterBottom
                                                    className={styles.ownerData_Info_Box_MainText}
                                                >
                                                    <NumberFormat
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        prefix={"$"}
                                                        value={payInfo['signature']}
                                                        displayType={"text"}
                                                        renderText={(value, props) => value}
                                                    />
                                                </CustomTextTitle>

                                            </Box>

                                            <br />

                                            <Box className={"service-info"}>

                                                <CustomTextTitle
                                                    variant="h6"
                                                    gutterBottom
                                                    className={styles.ownerData_Info_Box_MainText}
                                                >
                                                    IVA
                                                </CustomTextTitle >

                                                <CustomTextTitle
                                                    variant="h6"
                                                    gutterBottom
                                                    className={styles.ownerData_Info_Box_MainText}
                                                >
                                                    <NumberFormat
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        prefix={"$"}
                                                        value={payInfo['iva']}
                                                        displayType={"text"}
                                                        renderText={(value, props) => value}
                                                    />
                                                </CustomTextTitle>

                                            </Box>

                                            <br />

                                            <Box className={"service-info-total"}>

                                                <CustomTextTitle
                                                    variant="h6"
                                                    gutterBottom
                                                    className={styles.ownerData_Info_Box_MainText}
                                                >
                                                    Total
                                                </CustomTextTitle>

                                                <CustomTextTitle
                                                    variant="h6"
                                                    gutterBottom
                                                    className={styles.ownerData_Info_Box_MainText}
                                                >
                                                    <NumberFormat
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        prefix={"$"}
                                                        value={payInfo['total']}
                                                        displayType={"text"}
                                                        renderText={(value, props) => value}
                                                    />
                                                </CustomTextTitle>

                                            </Box>

                                        </Box>
                                    )}
                                </Item>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.contentFooterXs}>
                                    <CustomTextTitle variant="h6" gutterBottom>
                                        Gracias por usar nuestros servicios
                                    </CustomTextTitle>

                                    <Contact showMsnInfo={true} />
                                </div>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                className={styles.mainContentParticipants}
                            >
                                <br />
                            </Grid>

                            <AppBar className={styles.appBarBottom}>
                                <Toolbar>
                                    <Tabs
                                        // value={value}
                                        onChange={handleChangeAppBarBottom}
                                        indicatorColor="secondary"
                                        textColor="inherit"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                    >
                                        <Tab
                                            disabled
                                            onClick={() =>
                                                setOpenResetContract((e) => e + 1)
                                            }
                                            icon={<ResetIcon />}
                                            label="Resetear"
                                        />
                                        <Tab
                                            disabled
                                            onClick={() =>
                                                setOpenDeleteContract((e) => e + 1)
                                            }
                                            icon={<TrashIcon />}
                                            label="Eliminar trámite"
                                        />
                                        <Tab
                                            onClick={() => dataUpdate()}
                                            icon={<UpdateIcon />}
                                            label="Actualizar"
                                        />
                                    </Tabs>
                                </Toolbar>
                            </AppBar>
                        </Fragment>
                    )}
                </Grid>
            </Box >
        </Container >
    );
};

export default DetailProcedure;