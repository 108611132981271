import { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import NumberFormat from 'react-number-format';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import dayjs from 'dayjs';

import { DataPreviewStyles } from "./index.css";
import { to, verifiVariable } from "../../../utils/enums";
import Spinner from "../../spinners/Spinner";
import { Context } from "../../../context/utilsContext";
import { createSchedule, createTramitSchedule, saveDirecction } from "./services";
import { clearForm } from "../../../store/scheduling";

const CustomTextTitle = styled(Typography)((props) => ({
    color: props.theme.palette.black.main,
    fontSize: '1.2rem',
    fontWeight: 900
}));

/* const CustomSubtitlePayInfoText = styled(Typography)((props) => ({
    color: props.theme.palette.gray.main,
    fontSize: '1.1rem',
    fontWeight: 100,
    marginTop: '30px',
    lineHeight: 0.5
}));

const CustomSubtitlePayInfoValue = styled(Typography)((props) => ({
    color: props.theme.palette.gray.main,
    fontSize: '1.1rem',
    fontWeight: 900,
    marginTop: '30px',
    textAlign: 'end',
    lineHeight: 0.5
})); */

const CustomSubtitle = styled(Typography)((props) => ({
    color: props.theme.palette.gray.main,
    fontSize: '1.2rem',
    fontWeight: 900,
    marginTop: '30px',
    lineHeight: 0.5
}));

const CustomText = styled(Typography)((props) => ({
    color: props.theme.palette.gray.main,
    fontSize: '1.2rem',
    fontWeight: 100
}));

/* const EditCustomButtom = styled(Button)((props) => ({
    borderRadius: '7px',
    fontStyle: 'normal',
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: 'none',
    fontSize: 16,
    marginTop: '11px',
    marginLeft: '37px',
    background: props.theme.palette.primary.light,
    '&:hover': {
        background: props.theme.palette.primary.light
    },
    fontFamily: [
        '"PoppinsBold"'
    ].join(','),
})); */

const SchedulePay = () => {
    const { nextPage, setNextPage, toResumen, setToResumen } = useContext(Context);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const styles = DataPreviewStyles();
    const {
        session: {
            login: {
                user,
                userInfo
            }
        },
        scheduling: {
            fourthStep: {
                date,
                hours,
                commune,
                jurisdiction,
                directions
            },
            thirdStep: {
                docPdfBase64
            },
            firstStep: {
                procedureid,
                contractTypeFee
            },
            secondStep,
            notaryList
        }
    } = useSelector((state) => state.root);
    // eslint-disable-next-line
    const [firmantes, setFirmante] = useState(![null, undefined, ''].includes(secondStep) ? secondStep : []);
    const [loading, setLoading] = useState(false);

    const verifyRol = (rol, owner) => {

        const { sRUT } = userInfo;

        if (rol.length === 1) {

            if (rol.includes('Firmante')) {
                return sRUT === owner ? 1 : 0;
            } else if (rol.includes('Pagador')) {
                return 3;
            } else if (rol.includes('Pre Aprobador')) {
                return 2;
            }

        } else {

            if (rol.includes('Firmante') && rol.includes('Pagador')) {
                return 0;
            } else if (rol.includes('Pagador') && rol.includes('Pre Aprobador')) {
                return 4;
            }

        }

    }

    const createTramit = async () => {

        try {

            setLoading(true);

            const { token, } = user;

            const { iUserID } = userInfo;

            const notary = notaryList.find((item) => jurisdiction['id'] === item['juridiction_id']);

            const contractRequestPayload = {
                sOwnerType: "NATURAL",
                sPaymentStatus: "PENDING",
                iPaymentService: 0,
                iContractTypeFeeID: contractTypeFee['iContractTypeID'],
                iSignedCount: 1,
                callback: "",
                iPriceId: 0,
                signers: firmantes.map((item) => {

                    const firmante = {
                        ...item,
                        order: 1,
                        rol: verifyRol(item['rol'], item['rutId']),
                        payment: '0.00',
                        type: 'NATURAL',
                        portion: item['pay']
                    };

                    delete firmante['pay'];

                    return firmante;
                }),
                document: {
                    content: docPdfBase64,// eliminar cabecera
                    fileName: "comprobante-de-factuara.pdf"

                }
            }

            const response = await createTramitSchedule(contractRequestPayload, iUserID, token);

            const scheduleData = {
                sContractID: response['data']['message']['contract']['contractId'],
                notary_id: notary['id'],
                notario_id: 1,
                commune_id: commune['id'],
                juridiction_id: jurisdiction['id'],
                date: date,
                start_hour: hours,
                end_hour: dayjs(`${date}T${hours}`).add(1, 'hour').format('HH:mm'),
                type: "client"
            }

            const scheduleReq = await createSchedule(scheduleData, token);

            directions.forEach(async (element) => {
                await saveDirecction({ scheduled_id: scheduleReq['data'], direction: element['direction'] }, token);
            });

            dispatch(clearForm());

            setLoading(false);

            navigate(to.MANAGE_SCHEDULE_HISTORY);


        } catch (error) {
            console.log('error: ', error);
            setLoading(false);
        }

    }

    useEffect(() => {

        if (nextPage !== 0) createTramit();

        return () => {
            setNextPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextPage]);

    useEffect(() => {

        if (!verifiVariable(toResumen)) navigate(toResumen);

        return () => {
            setToResumen(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toResumen]);

    return (
        <div className="procedure-creation__form-container">

            {loading && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={"Creando Trámite, por favor espere"}
                    opacity={true}
                />
            )}

            <div className={`${styles.formContainer} form__container`}>

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>

                        <CustomTextTitle variant="h6" gutterBottom>
                            Datos del agendamiento
                        </CustomTextTitle>


                        <CustomSubtitle variant="h6" gutterBottom>
                            Fecha
                        </CustomSubtitle>

                        <CustomText variant="h6" gutterBottom>
                            {!verifiVariable(date) ? `${dayjs(date).format('DD')} de ${dayjs(date).format('MMMM')} de ${dayjs(date).format('YYYY')}` : ''}
                        </CustomText>


                        <CustomSubtitle variant="h6" gutterBottom>
                            Hora
                        </CustomSubtitle>

                        <CustomText variant="h6" gutterBottom>
                            {(!verifiVariable(date) && !verifiVariable(hours)) ? dayjs(`${date}T${hours}`).format('hh:mm A') : ''}
                        </CustomText>


                        <CustomSubtitle variant="h6" gutterBottom>
                            Comuna
                        </CustomSubtitle>

                        <CustomText variant="h6" gutterBottom>
                            {!verifiVariable(commune) ? commune['name'] : ''}
                        </CustomText>


                        <CustomSubtitle variant="h6" gutterBottom>
                            Jurisdicción
                        </CustomSubtitle>

                        <CustomText variant="h6" gutterBottom>
                            {!verifiVariable(jurisdiction) ? jurisdiction['name'] : ''}
                        </CustomText>


                        {
                            !verifiVariable(directions) && directions.map((item, index) => {
                                return (
                                    <Fragment key={item + index}>
                                        <CustomSubtitle variant="h6" gutterBottom>
                                            Dirección {index + 1}
                                        </CustomSubtitle>

                                        <CustomText variant="h6" gutterBottom>
                                            {item['direction']}
                                        </CustomText>
                                    </Fragment>
                                )
                            })
                        }

                    </Grid>

                    <Grid item xs={12} sm={6}>

                        <CustomTextTitle variant="h6" gutterBottom>
                            Datos del trámite
                        </CustomTextTitle>

                        <CustomSubtitle variant="h6" gutterBottom>
                            ¿Qué trámite necesitas gestionar?
                        </CustomSubtitle>

                        <CustomText variant="h6" gutterBottom>
                            {!verifiVariable(procedureid) ? procedureid.name : ''}
                        </CustomText>

                        <CustomSubtitle variant="h6" gutterBottom>
                            ¿Qué tipo de firma necesitas?
                        </CustomSubtitle>

                        <CustomText variant="h6" gutterBottom>
                            {!verifiVariable(contractTypeFee) ? contractTypeFee.label : ''}
                        </CustomText>

                        <CustomTextTitle variant="h6" gutterBottom>
                            Participantes
                        </CustomTextTitle>

                        {firmantes.map((item) => (
                            <Fragment key={item.rutId} >

                                <CustomSubtitle variant="h6" gutterBottom>
                                    Nombre
                                </CustomSubtitle>

                                <CustomText variant="h6" gutterBottom>
                                    {item.full_name}
                                </CustomText>


                                <CustomSubtitle variant="h6" gutterBottom>
                                    RUT (sin puntos y con guión) o Pasaporte (solo números)
                                </CustomSubtitle>

                                <CustomText variant="h6" gutterBottom>
                                    {item.rutId}
                                </CustomText>

                                <CustomSubtitle variant="h6" gutterBottom>
                                    Correo electrónico
                                </CustomSubtitle>

                                <CustomText variant="h6" gutterBottom>
                                    {item.email}
                                </CustomText>

                                <CustomSubtitle variant="h6" gutterBottom>
                                    Número de celular
                                </CustomSubtitle>

                                <CustomText variant="h6" gutterBottom>
                                    {item.phone}
                                </CustomText>

                            </Fragment>
                        ))}


                        {/* <Grid container spacing={2} className={styles.contentMainPayInfo}>

                            <Grid item xs={12}>

                                <CustomSubtitle variant="h6" gutterBottom color={['black.main']}>
                                    Notaría a domicilio
                                </CustomSubtitle>

                            </Grid>


                            <Grid item xs={6}>

                                <CustomSubtitlePayInfoText variant="h6" gutterBottom>
                                    Firmante x {!verifiVariable(secondStep) ? secondStep.length : 0}
                                </CustomSubtitlePayInfoText>

                            </Grid>

                            <Grid item xs={6}>

                                <CustomSubtitlePayInfoValue variant="h6" gutterBottom>
                                    <NumberFormat
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={"$"}
                                        value={!verifiVariable(contractTypeFee) ? contractTypeFee['price'] : 0}
                                        displayType={"text"}
                                        renderText={(value, props) => value}
                                    />
                                </CustomSubtitlePayInfoValue>

                            </Grid>

                            <Grid item xs={6}>

                                <CustomSubtitlePayInfoText variant="h6" gutterBottom>
                                    Subtotal
                                </CustomSubtitlePayInfoText>

                            </Grid>


                            <Grid item xs={6}>

                                <CustomSubtitlePayInfoValue variant="h6" gutterBottom>
                                    <NumberFormat
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={"$"}
                                        value={!verifiVariable(contractTypeFee) ? contractTypeFee['price'] : 0}
                                        displayType={"text"}
                                        renderText={(value, props) => value}
                                    />
                                </CustomSubtitlePayInfoValue>

                            </Grid>

                            <Grid item xs={6}>

                                <CustomSubtitlePayInfoText variant="h6" gutterBottom>
                                    Iva
                                </CustomSubtitlePayInfoText>

                            </Grid>

                            <Grid item xs={6} style={{ marginBottom: '55px' }}>

                                <CustomSubtitlePayInfoValue variant="h6" gutterBottom>
                                    <NumberFormat
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={"$"}
                                        value={!verifiVariable(contractTypeFee) ? contractTypeFee['iva'] : 0}
                                        displayType={"text"}
                                        renderText={(value, props) => value}
                                    />
                                </CustomSubtitlePayInfoValue>

                            </Grid>

                            <Grid item xs={6}>

                                <CustomTextTitle variant="h6" gutterBottom textAlign={'end'}>
                                    Total
                                </CustomTextTitle>

                            </Grid>

                            <Grid item xs={6}>

                                <CustomTextTitle variant="h6" gutterBottom textAlign={'end'}>
                                    $ 4.200
                                </CustomTextTitle>

                            </Grid>

                        </Grid> */}

                    </Grid>

                </Grid>

            </div>

        </div>
    );
}

export default SchedulePay;