import { forwardRef, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { useNavigate } from "react-router-dom";

import { ReactComponent as TrashIcon } from "../../icons/trash-icon.svg";

import { SignAuthorizationStyles } from "../../index.css";
import Btn from "../../../../buttons/Button";
import {
    clearContractsDetail,
    clearStatusAnf,
    deleteAnfTramit,
    // FIR-777 deleteExpressTramit,
} from "../../../../../store/formANFTramit";
import { to } from "../../../../../utils/enums";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteContractAlert = ({
    contrato,
    setTexLoadin,
    openFromMobile,
    showIcon,
}) => {
    const styles = SignAuthorizationStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        formANFTramit: { status },
        session: {
            login: { user },
        },
    } = useSelector((state) => state.root);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        dispatch(clearStatusAnf());
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteCurrentContract = async () => {
        const { token } = user;

        setTexLoadin("Eliminando trámite por favor espere.");

        dispatch(deleteAnfTramit(token, contrato["contractID"]));
        // FIR-777 dispatch(deleteExpressTramit(token, contrato["contractID"]));
    };

    useEffect(() => {
        if (status === "success" && open === true) {
            dispatch(clearContractsDetail());
            navigate(to.ANF_MANAGE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, status]);

    useEffect(() => {
        if (openFromMobile > 0) handleClickOpen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openFromMobile]);

    return (
        <Fragment>
            {showIcon && (
                <Tooltip
                    title={
                        <Typography
                            variant="subtitle1"
                            color={["white.main"]}
                            gutterBottom
                        >
                            Elimina el trámite definitivamente. Este no podrá
                            ser recuperado
                        </Typography>
                    }
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    arrow
                >
                    <Button
                        variant="outlined"
                        startIcon={<TrashIcon />}
                        size="small"
                        onClick={handleClickOpen}
                        className={styles.buttonTramit}
                        disabled
                    /*disabled={
                        !["REVIEW", "CREATED", "REJECTED"].includes(
                            contrato["status"]
                        )
                    }*/
                    >
                        Eliminar trámite
                    </Button>
                </Tooltip>
            )}

            <Dialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                className={styles.mainContainerDialog}
            >
                <DialogTitle sx={{ textAlign: "center" }}>
                    ¿Deseas eliminar este contrato?
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Typography>
                            Si eliminas este contrato, no podrás recuperarlo.
                        </Typography>
                    </DialogContentText>
                </DialogContent>

                <DialogActions
                    style={{ justifyContent: "center", paddingBottom: "20px" }}
                >
                    <Btn
                        variant="outlined"
                        text="Cancelar"
                        color="error"
                        onClick={handleClose}
                        sx={{
                            height: "39px",
                            borderRadius: "20px",
                        }}
                    />

                    <Btn
                        variant="outlined"
                        text="Aceptar"
                        color="primary"
                        onClick={() => deleteCurrentContract()}
                        sx={{
                            height: "39px",
                            borderRadius: "20px",
                            marginLeft: "30px !important",
                        }}
                    />
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default DeleteContractAlert;
