import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Container, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import { Btn, Spinner } from "../..";
import { styles } from "../../../helpers";
import { getAgreementsStorage, getStandarAgreementsStorage } from "../../../store/agreement";
import { userInfo } from "../../../store/auth/userLogin";
import { getContractsCreated } from "../../../store/contract/contractDetail";
import { getAllTramitStorage, getTypeTramitStorage } from "../../../store/contract/tramites";
import { icons, to, typeUser, verifiVariable } from "../../../utils/enums";
import { getUserInfo } from "../../storeSelector/storeSelector";
import { getAllAgreementsStorage } from "../../../store/organization";
import ScreenSizeHook from "../../../hooks/ScreenSizeHook";
import { getPreviusSigners } from "../../../store/contract/signatoriesList";
import { getAllAnfTramit } from "../../../store/formANFTramit";
import InfoMessage from "./molecules/infoMessage";
import CardSteps from "./molecules/cardSteps";
import ProceduresHistory from "../../proceduresHistory";
import ModalToNewForm from "../../modalToNewForm";

const CustomText = styled(Typography)((props) => ({
    fontWeight: 900,
    textAlign: "center",
}));

const ContentWelcome = () => {
    const userInfoStatus = getUserInfo();
    const screenSizeHook = ScreenSizeHook();
    const {
        session: {
            login: { user, userInfo: userInfoRedux },
        },
        contract: {
            contractCreator: { loading },
            signatories: { loading: loadingSigners },
        }
    } = useSelector((state) => state.root);

    const dispatch = useDispatch();

    const [cardMenu, setCardMenu] = useState('express');
    const [cardMenuActive, setCardMenuActive] = useState(undefined);
    const [manangmentTypeBtnActive, setManangmentBtnTypeActive] = useState(1);
    const [infoActive, setInfoActive] = useState(undefined);

    const arrayTramits = [
        {
            step: 1,
            tramitType: 'SIGNATURE_ANF',
            description: "Máxima seguridad",
            title: "Autorización notarial de firmas (ANF)",
            icon: icons.MAX_SECURITY_ICON,
            url: to.ANF_CREATE,
            typeDescription: "ANF: Autoriza el documento y la validación de identidad de las partes. <br />Ideal para contratos de arriendo protegidos bajo la nueva ley de arriendo 'Devuélveme mi Casa'"
        },
        {
            step: 2,
            tramitType: 'SIGNATURE_PROT_NOTARIAL',
            description: "Alta seguridad",
            title: "Protocolización notarial",
            icon: icons.HIGH_SECURITY_ICON,
            url: {
                express: to.EXPRESS_CONTRACT_CREATION,
                approvers: to.CONTRACT_CREATION
            },
            typeDescription: "Protocolización notarial: Legalización con número de repertorio. Queda un registro público para su validez legal."
        },
        {
            step: 3,
            tramitType: 'SIGNATURE_CERT_NOTARIAL',
            description: "Más seguridad",
            title: "Certificación notarial",
            icon: icons.MORE_SECURITY_ICON,
            url: {
                express: to.EXPRESS_CONTRACT_CREATION,
                approvers: to.CONTRACT_CREATION
            },
            typeDescription: "Certificación notarial: Copia legalizada del documento por notario público."
        },
        {
            step: 4,
            tramitType: 'SIGNATURE',
            description: "Buena seguridad",
            title: "Firma electrónica (Sin notaría)",
            icon: icons.GOOD_SECURITY_ICON,
            url: {
                express: to.EXPRESS_CONTRACT_CREATION,
                approvers: to.CONTRACT_CREATION
            },
            typeDescription: "FES: Solo firma electrónica simple del documento, sin notaría."
        }
    ];

    const manangmentType = [
        {
            id: 1,
            text: '¿Necesitas aprobar tu trámite?',
            value: 'express'
        },
        {
            id: 2,
            text: '¿Necesitas aprobar tu trámite?',
            value: 'approvers'
        }
    ];

    const handleCardsMenu = (btnId, value) => {
        setCardMenu(value);
        setCardMenuActive(undefined);
        setManangmentBtnTypeActive(btnId);
        setInfoActive(undefined)
    };

    /* const getNotaries = async (token) => {
        try {
            const response = await getAllNotaries(token);

            if (response["status"] === "Success")
                dispatch(
                    saveSchedulingInfo({
                        indexe: "notarieList",
                        value: response["data"],
                    })
                );
        } catch (error) {
            console.log(error);
        }
    };

    const getNotarys = async (token) => {
        try {
            const response = await getAllNotarys(token);

            if (response["status"] === "Success")
                dispatch(
                    saveSchedulingInfo({
                        indexe: "notaryList",
                        value: response["data"],
                    })
                );
        } catch (error) {
            console.log(error);
        }
    };

    const getJuridictions = async (token) => {
        try {
            const response = await getAllJuridiction(token);

            if (response["status"] === "Success")
                dispatch(
                    saveSchedulingInfo({
                        indexe: "juridictionList",
                        value: response["data"],
                    })
                );
        } catch (error) {
            console.log(error);
        }
    }; */

    /* const getCommunes = async (token) => {
        try {
            const response = await getAllCommune(token);

            if (response["status"] === "Success")
                dispatch(
                    saveSchedulingInfo({
                        indexe: "communeList",
                        value: response["data"],
                    })
                );
        } catch (error) {
            console.log(error);
        }
    }; */

    useEffect(() => {
        const { token, userId } = user;

        //getCommunes(token);

        if (!verifiVariable(token)) {

            dispatch(getAllTramitStorage(token));

            dispatch(getTypeTramitStorage(token));

            dispatch(userInfo(token, userId));

            dispatch(getContractsCreated(userId, token, userInfoStatus.profileId));

            dispatch(getAgreementsStorage(token, userId));

            dispatch(getStandarAgreementsStorage(token));

            dispatch(getPreviusSigners(token));

            dispatch(getAllAnfTramit(token));

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (!verifiVariable(userInfoRedux)) {
            const { token } = user;

            const { iProfileID } = userInfoRedux;

            if (!verifiVariable(iProfileID) && iProfileID === typeUser.ADMIN && !verifiVariable(token))
                dispatch(getAllAgreementsStorage(token));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfoRedux]);

    useEffect(() => {
        console.log({ screenSizeHook })
    }, [screenSizeHook])

    /* useEffect(() => {
        const { token } = user;

        if (!verifiVariable(communeList) && communeList.length === 0)
            getCommunes(token);

        if (!verifiVariable(notarieList) && notarieList.length === 0)
            getNotaries(token);

        if (!verifiVariable(juridictionList) && juridictionList.length === 0)
            getJuridictions(token);

        if (!verifiVariable(notaryList) && notaryList.length === 0)
            getNotarys(token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); */

    return (
        <Container maxWidth={'xl'}>
            <div
                className="detail__container"
                style={{ padding: `1.5rem 0 0 0` }}
            >

                <ModalToNewForm />

                {(loading || loadingSigners) && (
                    <Spinner
                        loading={true}
                        type="bar"
                        text={"Cargando contratos, por favor espera"}
                        opacity={true}
                    />
                )}
                <Grid container>

                    <ProceduresHistory />

                    <Grid style={styles.mainContent_welcome}>

                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                align="center"
                                fontSize={screenSizeHook === "xs" ? "1.5rem" : "2.1rem"}
                                fontWeight={"700"}
                            >
                                Bienvenido a FirmaVirtual
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <div style={styles.subTitle_welcome}>
                                <span
                                    style={{ fontSize: "1.2rem" }}
                                >{`${user.firstName} ${user.lastName}`}</span>
                                , ya puedes firmar electrónicamente tus documentos de manera 100% online. Simplemente ingresa a la opción que más te acomode y gestiona tus contratos.
                            </div>
                        </Grid>

                        <Grid item xs={12} style={styles.choiseBox}>
                            <CustomText style={styles.choiseBox_title}>
                                ¿Cómo quieres realizar tu trámite?
                            </CustomText>

                            {cardMenu === 'approvers'
                                ? (
                                    <InfoMessage
                                        icon={'info'}
                                        text={"Si necesitas aprobación de documentos en un proceso notarial o de firma, ofrecemos la opción con aprobadores."}
                                    />
                                )
                                : (
                                    <InfoMessage
                                        icon={'info'}
                                        text={"Si tu trámite no requiere la aprobación de tus firmantes, considera la opción de trámite express."}
                                    />
                                )
                            }

                            <Box sx={styles.buttonsContainer}>
                                {manangmentType.map(ele => (
                                    <Btn
                                        key={ele.id}
                                        sx={ele.id === manangmentTypeBtnActive
                                            ? styles.choiseBox_button_active
                                            : styles.choiseBox_button}
                                        text={ele.text}
                                        onClick={() => handleCardsMenu(ele.id, ele.value)}
                                    />
                                ))}
                            </Box>

                        </Grid>

                        <Grid container style={styles.infoMessageBox_container}>
                            {
                                arrayTramits
                                    .filter(ele => ele.step === cardMenuActive)
                                    .map(filterEle =>
                                        <InfoMessage
                                            icon={'info'}
                                            text={filterEle.typeDescription}
                                            close={cardMenuActive}
                                            setClose={setCardMenuActive}
                                            setActive={setInfoActive}
                                        />
                                    )
                            }
                        </Grid>

                        <Grid
                            style={!['xs', 'sm', 'md'].includes(screenSizeHook) ?
                                styles.card_welcome :
                                {}}
                        >
                            <CardSteps
                                data={arrayTramits}
                                cardMenu={cardMenu}
                                setCardMenuActive={setCardMenuActive}
                                cardMenuActive={cardMenuActive}
                                infoActive={infoActive}
                                setInfoActive={setInfoActive}
                            />
                        </Grid>

                        <Grid item xs={12} sx={{ margin: '1.5rem 0 0 0' }}>
                            <CustomText
                                color={["primary.main"]}
                                fontSize={"1.1rem"}
                                marginBottom={"30px"}
                            >
                                Gracias por usar nuestros servicios
                            </CustomText>
                        </Grid>

                        <Grid item xs={12}>
                            <CustomText fontSize={"1rem"} marginBottom={"35px"}>
                                Para más información, comunícate con nuestro equipo de
                                soporte al cliente:
                            </CustomText>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                textAlign={"center"}
                                marginBottom={"20px"}
                            >
                                Operaciones: +56 2 3210 3824 &nbsp; &nbsp; / &nbsp; &nbsp; Soporte API: +56 2 3210
                                1199 &nbsp; &nbsp; / &nbsp; &nbsp; Ventas: +56 2 3210 3927
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="body1" textAlign={"center"}>
                                contacto@firmavirtual.legal
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
};

export default ContentWelcome;