import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { PDFDocument } from "pdf-lib";

import Logo from "../../../../assets/logo/uach-logo.png";
import { FormularioUachtyles } from "../../index.css";
import {
    addMemeber,
    createContract,
} from "../../../../store/contract/contractDetail";
import {
    contractStoreFilesRequest,
    mergeDocs,
    sendContractEmail,
} from "../../../../utils/api";
import ErrorDocumentAlert from "../../../errorDocumentAlert";
import { asyncFileToBase64 } from "../../../../utils/enums";

const ConfirmPage = () => {
    const {
        session: {
            login: { user },
        },
        contract: {
            contractCreator: {
                contract: { detail },
            },
        },
        formUach: { fullName, rut, email, phone, tramitPdf, fileName },
    } = useSelector((state) => state.root);
    const dispatch = useDispatch();
    const styles = FormularioUachtyles();
    const [documentFail, setDocumentFail] = useState(0);

    useEffect(() => {
        const { token } = user;

        // eslint-disable-next-line
        const contractRequestPayload = {
            sOwnerType: "NATURAL",
            sPaymentStatus: "PENDING",
            iPaymentService: 0,
            iContractTypeFeeID: 73,
            iSignedCount: 1,
            callback: "",
            iAgreementId: 0,
            iPriceId: 0,
            signers: [
                {
                    full_name: fullName,
                    email: email,
                    rutId: rut,
                    phone: phone,
                    type: "NATURAL",
                    portion: "100",
                    payment: "0.00",
                },
            ],
        };

        dispatch(addMemeber(contractRequestPayload));
        dispatch(createContract(contractRequestPayload, token));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (![undefined, null, ""].includes(detail)) {
                    const { token } = user;
                    const pdfDoc = await PDFDocument.load(tramitPdf);
                    const { sContractID: contractID } = detail;

                    const pdfBytes = await pdfDoc.save();

                    const filePdf = new File([pdfBytes], fileName, {
                        type: "application/pdf",
                    });

                    const fileBase64 = await asyncFileToBase64(filePdf);

                    await contractStoreFilesRequest(
                        contractID,
                        fileBase64.replace("data:application/pdf;base64,", ""),
                        token
                    );

                    await mergeDocs(contractID, token);

                    await sendContractEmail(contractID, token);

                    window.location.replace(
                        "https://firmavirtual.legal/gracias-por-tu-solicitud"
                    );
                }
            } catch (error) {
                setDocumentFail((e) => e + 1);
                console.error(error);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                alignContent: "flex-start",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <ErrorDocumentAlert errors={documentFail} />

            <img src={Logo} className={styles.logo} alt="" />

            <h1 style={{ fontSize: "calc(25px + 1vw)" }}>
                Su solicitud ha sido enviada con éxito.
            </h1>

            <br />

            <CircularProgress size={200} />

            <h1 style={{ fontSize: "calc(25px + 1vw)" }}>Redirigiendo...</h1>
        </Box>
    );
};

export default ConfirmPage;
