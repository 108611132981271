import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiSubdirectoryLeft } from "react-icons/bi";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { ReactComponent as Logo } from "../../assets/logo/logo.svg";

import { FormularioSolicitudStyles } from "./index.css";
import Stepsper from "./molecules/stepsper";
import { api } from "../../utils/enums";
import { endSession, logSession } from "../../store/auth/userLogin";
import { getContractsTypes } from "../../store/contract/contractDetail";
import { purgeSigners } from "../../store/contract/signatoriesList";
import { clearFormSolicitud } from "../../store/formSolicitud/formSolicitud";
// import WhatsappIconSpeedDial from "../whatsappIconSpeedDial";
// import HelpIconSpeedDial from "../helpIconSpeedDial";

const FormularioSolicitud = () => {
    const {
        session: {
            login: { user },
        },
    } = useSelector((state) => state.root);

    const dispatch = useDispatch();
    const styles = FormularioSolicitudStyles();

    const [showSteps, setShowSteps] = useState(false);

    useEffect(() => {
        endSession();
        dispatch(purgeSigners());
        dispatch(clearFormSolicitud());

        dispatch(
            logSession({
                login: api.USUARIO_FIRMA_VIRTUAL,
                password: api.PASSWORD_USER_DEMO,
            })
        );
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const { token } = user;

        if (![null, undefined, ""].includes(token))
            dispatch(getContractsTypes(token));
        // eslint-disable-next-line
    }, [user]);

    return (
        <Fragment>
            {!showSteps && (
                <Grid className={styles.textContent} container>
                    <Logo className={styles.logo} />

                    <Typography
                        variant="h4"
                        style={{ color: "black" }}
                        gutterBottom
                    >
                        ¡Hola!
                    </Typography>

                    <Typography
                        className={styles.texto1}
                        variant="body2"
                        gutterBottom
                    >
                        Favor completa este formulario, para procesar tu
                        solicitud de firma.
                    </Typography>

                    <br />

                    <Typography
                        className={styles.texto1}
                        variant="body2"
                        gutterBottom
                    >
                        Cuando nos adjuntes tus archivos, favor subir al menos
                        el documento a firmar y la cédula de identidad de ambos
                        lados de todos los firmantes. Para personas jurídicas,
                        favor incluir adicionalmente el E-RUT o certificado
                        vigente de poder de cada firmante.
                    </Typography>

                    <br />

                    <Typography
                        className={styles.texto2}
                        variant="body2"
                        gutterBottom
                    >
                        <strong>
                            {" "}
                            Todos JUNTOS en sólo 1 archivo Word o PDF (revisa
                            acá el paso a paso)
                        </strong>
                        . Puedes usar{" "}
                        <a
                            href="https://firmavirtual.web.app/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {" "}
                            esta herramienta (https://firmavirtual.web.app/
                        </a>
                        ), para lograrlo (<strong>orden</strong>: 1. documento a
                        firmar 2. anexos si aplica 3.cédula de identidad de
                        ambos lados o pasaporte de todos los firmantes 4. E-RUT
                        de personas jurídicas si aplica).
                    </Typography>

                    <br />

                    <Typography
                        className={styles.texto2}
                        variant="body2"
                        gutterBottom
                    >
                        <strong>
                            Los correos electrónicos de cada firmante, deben ser
                            personales como por ejemplo de Gmail, Hotmail,
                            Yahoo, etc. y no corporativos, sobre todo si
                            corresponden a entidades gubernamentales o
                            financieras.
                        </strong>
                    </Typography>

                    <br />

                    <Typography
                        className={styles.texto1}
                        variant="body2"
                        gutterBottom
                    >
                        En caso de cualquier duda, nos puedes contactar:
                    </Typography>

                    <br />

                    <Typography
                        className={styles.texto3}
                        variant="body2"
                        gutterBottom
                    >
                        <a
                            href="mailto:contacto@firmavirtual.legal"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            contacto@firmavirtual.legal
                        </a>
                    </Typography>

                    <Typography
                        className={styles.texto3}
                        variant="body2"
                        gutterBottom
                    >
                        <a
                            href="https://wa.me/56945227304"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            +56 9 4522 7304
                        </a>
                    </Typography>

                    <Typography
                        className={styles.texto3}
                        variant="body2"
                        gutterBottom
                    >
                        <a
                            href="https://wa.me/56975635393"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            +56 9 7563 5393
                        </a>
                    </Typography>

                    <Typography
                        className={styles.texto3}
                        variant="body2"
                        gutterBottom
                    >
                        <a
                            href="https://wa.me/56997862790"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            +56 9 9786 2790
                        </a>
                    </Typography>

                    <br />

                    <Typography
                        className={styles.texto1}
                        variant="body2"
                        gutterBottom
                    >
                        (el horario de soporte es de Lunes a Viernes de 9
                        -19hrs, exceptuando feriados)
                    </Typography>

                    <br />

                    <Typography
                        className={styles.texto1}
                        variant="body2"
                        gutterBottom
                    >
                        Gracias.
                    </Typography>

                    <br />

                    <Typography
                        className={styles.texto1}
                        variant="body2"
                        gutterBottom
                    >
                        Saludos cordiales,
                    </Typography>

                    <br />

                    <Typography
                        className={styles.texto3}
                        variant="body2"
                        gutterBottom
                    >
                        <a
                            href="https://firmavirtual.legal/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Equipo FirmaVirtual
                        </a>
                    </Typography>

                    <br />

                    <Typography
                        className={styles.texto3}
                        variant="body2"
                        gutterBottom
                    >
                        <a
                            href="https://firmavirtual.legal/terminos-y-condiciones-politica-de-privacidad/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Términos y Condiciones & Política de Privacidad
                        </a>
                    </Typography>

                    <br />

                    <br />

                    <Grid
                        container
                        className={styles.buttomBox}
                        style={{ justifyContent: "center" }}
                    >
                        <Button
                            variant="contained"
                            color="customFormButtomSolicitud"
                            size="large"
                            onClick={() => setShowSteps(true)}
                        >
                            COMENZAR
                        </Button>

                        <Typography
                            id="textPulsar"
                            variant="body2"
                            gutterBottom
                        >
                            Pulsa <strong>Enter</strong>
                        </Typography>

                        <BiSubdirectoryLeft />
                    </Grid>

                    <br />

                    <br />
                </Grid>
            )}

            {showSteps && <Stepsper />}

            {/* <WhatsappIconSpeedDial /> */}

            {/* <HelpIconSpeedDial /> */}
        </Fragment>
    );
};

export default FormularioSolicitud;
