import { forwardRef, Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as MailIcon } from "../../../../img/mail-icon.svg";
import Btn from "../../../../../../buttons/Button";
import { sendUserContractEmail } from "../../../../../../../utils/api";
import Spinner from "../../../../../../spinners/Spinner";
import Notificationbanner from "../../../../../NotificationBanner";
import { Context } from "../../../../../../../context/utilsContext";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ContractDetailStyles = makeStyles(() => {
    return createStyles({
        mainContainerDialog: {
            '& div.MuiPaper-root': {
                borderRadius: '10px'
            }
        },
        muiAlert: {
            width: "100%",
            /* '& svg, div': {
                color: 'white !important',
            } */
        }
    });
});

const ResendUserContract = ({ contractMembers, contractFiles, member }) => {
    const {
        session: {
            login: {
                user
            }
        }
    } = useSelector((state) => state.root);
    const { setTimer, timer } = useContext(Context);
    const { state } = useLocation();
    const styles = ContractDetailStyles();
    const [open, setOpen] = useState(false);
    const [reminderSpinner, setReminderSpinner] = useState(false);
    const [showReminderMessage, setShowReminderMessage] = useState(false);
    const [signers, setSigners] = useState(undefined);
    const [alertType, setAlertType] = useState("");
    const [alertMessage, setAlertMessage] = useState("");


    const sendUserReminder = async () => {
        try {

            setReminderSpinner(true);

            const { token } = user;

            const response = await sendUserContractEmail(member['RUT'], state, signers, token);
            if (response) {
                setReminderSpinner(false);
                setShowReminderMessage(true);
                setAlertType("success");
                setAlertMessage(
                    "El recordatorio ha sido enviado correctamente."
                );

                setTimeout(() => {
                    setShowReminderMessage(false);
                    setAlertType("");
                    setAlertMessage("");
                }, 4000);

                setTimer();
            }
        } catch (error) {
            setReminderSpinner(false);
            console.log(error);
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {

        const data = { signers: [] };

        contractMembers.forEach(element => {
            data.signers.push({
                full_name: element['full_name'],
                email: element['email'],
                rutId: element['RUT'],
                phone: element['phone'],
                type: "NATURAL",
                portion: element['portion'],
                payment: "0.00"
            });

        });

        setSigners(data);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractMembers]);

    return (
        <Fragment>

            {reminderSpinner && (
                <Spinner
                    type="clip"
                    loading={true}
                    opacity={false}
                />
            )}

            <Notificationbanner
                display={showReminderMessage}
                notificationType={alertType}
            >
                <strong>{alertMessage}</strong>
            </Notificationbanner>

            <IconButton aria-label="delete" onClick={handleClickOpen} disabled={timer === '00:00:00' ? false : true}>
                {
                    timer === '00:00:00' ? (
                        <MailIcon
                            className='icon--lg'
                        />
                    ) : (
                        <div style={{ color: 'white' }}>{timer.replace('00:00:', '')}</div>
                    )
                }

            </IconButton>

            <Dialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                maxWidth='xs'
                className={styles.mainContainerDialog}
            >

                <DialogTitle sx={{ textAlign: 'center' }}>
                    ¿Estás seguro que deseas realizar esta acción?
                </DialogTitle>

                <DialogContent>

                    <DialogContentText>
                        Reenviar contrato para {member['full_name']}.
                    </DialogContentText>

                </DialogContent>

                <DialogActions style={{ justifyContent: 'center', paddingBottom: '20px' }}>

                    <Btn
                        variant="outlined"
                        text="Cancelar"
                        color="error"
                        onClick={() => handleClose()}
                        sx={{
                            height: '39px',
                            borderRadius: '20px'
                        }}
                    />

                    <Btn
                        variant="outlined"
                        text="Aceptar"
                        color="primary"
                        onClick={() => { sendUserReminder(member['RUT'], signers); handleClose() }}
                        sx={{
                            height: '39px',
                            borderRadius: '20px',
                            marginLeft: '30px !important'
                        }}
                    />

                </DialogActions>

            </Dialog>

        </Fragment>
    );

}

export default ResendUserContract;