import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRegGrinWink, FaRegSadCry, FaRegSurprise } from "react-icons/fa";

import Spinner from "../../../../spinners/Spinner";

import { BsTrash } from "react-icons/bs";
import { ModalContractDeleteStyles } from "./ModalContractDelete.css";
import { Context } from "../../../../../context/utilsContext";
import { deleteSchedule } from "../../services";

const ModalContractDelete = ({ contract }) => {
    const { setReload } = useContext(Context);
    const {
        session: {
            login: { user },
        },
    } = useSelector((state) => state.root);
    const styles = ModalContractDeleteStyles();
    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [description, setDescription] = useState("");
    const [texButtonGoback, setTexButtonGoback] = useState("");
    const [restStatus, setRestStatus] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setModalTitle("Eliminar Agendamiento");
        setDescription("Seguro desea eliminar el agendamiento?.");
        setTexButtonGoback("Volver");
        setRestStatus(undefined);
        setLoading(false);
        setOpen(true);
    };

    const handleClose = () => {
        if (loading) return;

        setOpen(false);
    };

    const deleteDocs = async () => {
        try {
            const { token } = user;

            setLoading(true);

            const response = await deleteSchedule(contract, token);

            if (response['status'] === 'Success') {
                setRestStatus(true);
                setTexButtonGoback("Volver");
                setModalTitle("Agendamiento eliminado");
                setDescription("Su agendamiento ha sido eliminado de forma exitosa");
            } else {
                setTexButtonGoback("Volver");
                setModalTitle("Problemas al borrar");
                setDescription(
                    "Su agendamiento no ha podido ser eliminado, intente nuevamente o comunquese con un administrador del sistema"
                );
                setRestStatus(false);
            }

            setLoading(false);
            setReload(true);

            setTimeout(() => {
                setReload(false);
            }, 15000);

        } catch (error) {
            setTexButtonGoback("Volver");
            setModalTitle("Problemas al borrar");
            setDescription(
                "Su agendamiento no ha podido ser eliminado, intente nuevamente o comunquese con un administrador del sistema"
            );
            setRestStatus(false);
            setLoading(false);
            setReload(false);
        }
    };

    return (
        <React.Fragment>
            <BsTrash
                onClick={() => handleClickOpen()}
                className="icon--xs icon--blue-light icon-pointer"
            />

            <Dialog open={open} onClose={handleClose} maxWidth={"xs"}>
                {restStatus === undefined && (
                    <Stack className={styles.buttomsTopContent}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={handleClose}
                        >
                            <AiOutlineCloseCircle
                                fontSize="inherit"
                                size={20}
                            />
                        </IconButton>
                    </Stack>
                )}

                {restStatus === true && (
                    <Stack className={styles.buttomsTopSuccessContent}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={handleClose}
                        >
                            <FaRegGrinWink
                                fontSize="inherit"
                                size={150}
                                className="icon--red"
                            />
                        </IconButton>
                    </Stack>
                )}

                {restStatus === false && (
                    <Stack className={styles.buttomsTopSuccessContent}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={handleClose}
                        >
                            <FaRegSadCry
                                fontSize="inherit"
                                size={150}
                                className="icon--red"
                            />
                        </IconButton>
                    </Stack>
                )}

                <DialogTitle className={styles.constentDialogTitle}>
                    {modalTitle}
                </DialogTitle>

                <DialogContent align="center">
                    <DialogContentText align="center">
                        {description}
                    </DialogContentText>

                    <br />

                    {restStatus === undefined && (
                        <FaRegSurprise
                            fontSize="inherit"
                            size={150} /* className="icon--red" */
                        />
                    )}

                    {loading && (
                        <Spinner loading={true} type="bar" opacity={true} />
                    )}
                </DialogContent>

                <DialogActions>
                    {restStatus === undefined && (
                        <Stack className={styles.constentDialogActionSend}>
                            <Button
                                variant="contained"
                                className={styles.goBackButton}
                                onClick={() => deleteDocs()}
                            >
                                Borrar
                            </Button>
                        </Stack>
                    )}

                    {restStatus !== undefined && (
                        <Stack className={styles.constentDialogActionGoBack}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleClose();
                                }}
                                className={styles.goBackButton}
                            >
                                {texButtonGoback}
                            </Button>
                        </Stack>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ModalContractDelete;
