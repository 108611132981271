import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Box } from "@mui/system";

import AddProvider from "./molecules/AddProvider";
import { Spinner } from "../..";
import { to } from "../../../utils/enums";
import {
    getAllAnfTramit,
} from "../../../store/formANFTramit";
import GeneralListCupons from "../general-list-cupons";
import CustomToobarCuponsList from "./molecules/customToobarCuponsList/index";
import { couponsTypeSaveInfo, getAllCouponsType } from "../../../store/coupons/couponsType";
import ErrorAlert from "../expressProcess/molecules/errorAlert";
import CouponsStatsHeader from "../../couponsStatsHeader";

const ManageCouponsType = () => {
    const {
        session: {
            login: { user },
        },
        coupons: {
            couponsType: {
                allCouponsTypes,
                loading: couponsTypeloading,
                //message: couponsTypeMessage,
                status: couponsTypeStatus
            }
        }
    } = useSelector((state) => state.root);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [openModal, setOpenModal] = useState({});
    const [openForm, setOpenForm] = useState(false);
    const [dataGrid, setDataGrid] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [headerData, setHeaderData] = useState([]);

    const getHeaderData = (data) => {

        const newHeaderData = [
            {
                text: 'Todos los tipos cupones',
                count: data.length || 0
            },
            {
                text: 'Tipos de cupones activos',
                count: data.filter(element => element.coupon_type_status === 'ACTIVE').length || 0
            },
            {
                text: 'Tipos de cupones inactivos',
                count: data.filter(element => element.coupon_type_status === 'INACTIVE').length || 0
            },
        ];

        setHeaderData(newHeaderData);
    };

    useEffect(() => {

        const { token } = user;

        dispatch(getAllCouponsType(token));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        const clearTypeCouponsState = () => {
            dispatch(couponsTypeSaveInfo({
                indexe: 'status',
                value: ''
            }));
        };

        if (couponsTypeStatus === 'success') {

            if (allCouponsTypes?.length > 0) {
                const newData = [];

                allCouponsTypes.forEach(element => {
                    newData.push({
                        autoId: element.coupon_type_id,
                        cuponName: element.coupon_type_name,
                        useNumber: element.coupon_apply_count,
                        discountValue: element.discount,
                        discountTypeValue: element.discount_type,
                        status: element.coupon_type_status
                    });
                });

                setDataGrid(newData);

                getHeaderData(allCouponsTypes);
            };

            clearTypeCouponsState();
        } else if (couponsTypeStatus === 'fail') {
            setOpenModal({
                open: 1,
                title: 'Ha surgido un problema consultando los tipos de cupones',
                message: 'Podrías intentar nuevamente o comunícate con un administrador del sistema.'
            });
        };

        const timer = setTimeout(() => {
            clearTypeCouponsState();
        }, 5000);

        return () => {
            if (timer) {
                clearTimeout(timer);
            };
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [couponsTypeStatus]);

    const updateData = () => {
        const { userId, token } = user;

        setSpinner(true);

        if (![null, undefined].includes(userId)) {
            dispatch(getAllAnfTramit(token));
        }

        setTimeout(() => {
            setSpinner(false);
        }, 5000);
    };

    const renderList = useCallback(() => {
        return (
            <Fragment>
                <br />

                <CustomToobarCuponsList
                    title={"Gestión de tipo de cupones"}
                    subTitle={
                        "Aquí podrás visualizar el registro de los cupones ingresados"
                    }
                    onUpdate={() => console.log("Update") /* updateData() */}
                    onCreate={() => navigate(to.COUPONS_TYPE_ADD)}
                />

                <div
                    className="container--relative contracts-status-list__container"
                    style={{ height: "auto", width: "100%" }}
                >
                    <div style={{ display: "flex", height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <GeneralListCupons
                                updateList={() => updateData()}
                                dataGrid={[...dataGrid].map((item) => {
                                    return {
                                        autoId: item['autoId'],
                                        cuponName: item['cuponName'],
                                        useNumber: item['useNumber'],
                                        discountValue: item['discountValue'],
                                        discountTypeValue: item['discountTypeValue'],
                                        status: item['status']
                                    };
                                })}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, couponsTypeloading, state]);

    return (
        <div className="content-detail__container">
            <ErrorAlert msn={openModal.message} title={openModal.title} errors={openModal.openModal} />
            <AddProvider
                openForm={openForm}
                closeForm={(e) => setOpenForm(e)}
            />
            <CouponsStatsHeader data={headerData} />

            {spinner || couponsTypeloading ? (
                <Box
                    sx={{ height: "150px" }}
                    className="container--relative contracts-status-list__container"
                >
                    <Spinner loading={true} type="bar" opacity={true} />
                </Box>
            ) : (
                renderList()
            )}
        </div>
    );
};

export default ManageCouponsType;