import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, ListItem, List, Link } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { BiCheck } from "react-icons/bi";
import {
    to,
    apiMessage,
    externalLink,
    /* optionsSteps, */
    asyncFileToBase64,
} from "../../utils/enums";
import {
    contractStoreFilesRequest,
    mergeDocs,
    sendContractEmail,
} from "../../utils/api";
import { PopUp, Notificationbanner, PositionFirm } from "../../components";
import { store } from "../../store/storeConfig";
import {
    getContractDetail,
    resetContract,
} from "../../store/contract/contractDetail";
import { ReactComponent as FileUploadIcon } from "../../assets/icons/fileupload.svg";
import { getUserToken } from "../storeSelector/storeSelector";
import Spinner from "../spinners/Spinner";
import Text from "../heading/Text";
import Btn from "../buttons/Button";
import { bodyTextStyles } from "../../utils/validations";
import ScreenSizeHook from "../../hooks/ScreenSizeHook";
/* import {
    stepsTramit3_2,
    stepsTramit3_3,
} from "../toursSteps/tourTramitStep3/inde"; */
// import { Steps } from "intro.js-react";
import { useDispatch, useSelector } from "react-redux";

const Procedurecreationstep3 = () => {
    const {
        contract: {
            contractCreator: { contractCreatedDetails },
        },
    } = useSelector((state) => state.root);
    const dispatch = useDispatch();
    const screenSizeHook = ScreenSizeHook();
    const { state } = useLocation();
    const navigate = useNavigate();
    const userToken = getUserToken();
    const [displayForm, setDisplayForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [runSpinner, setRunSpinner] = useState(false);
    const [docNames, setDocNames] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [modalButtonTitle, setModalButtonTitle] = useState("");
    const [contractID, setContractID] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const [filePdf, setFilePdf] = useState(null);
    /* const [stepsEnabled3_1, setStepsEnabled3_1] = useState(false);
    const [stepsEnabled3_2, setStepsEnabled3_2] = useState(false); */

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const titleHintStyle = {
        color: "quinary.xdark",
        fontWeight: "500",
        fontSize: "1.1rem",
        marginTop: "21px",
    };

    const titleStyles = {
        color: "black.main",
        fontWeight: "500",
        marginBottom: "0",
        fontSize: "1.2rem",
    };

    const hintStyles = {
        color: "black.main",
        fontWeight: "300",
        marginBottom: "0",
    };

    const fileNameStyles = {
        color: "black.main",
        fontWeight: "500",
    };

    const buttonSubmitStyles = {
        backgroundColor: "primary.light",
        paddingLeft: "10px",
        paddingRight: "10px",
        fontWeight: "500",
        textTransform: "none",
        float: "right",
    };

    const hintContainer = {
        py: "15px",
        px: "25px",
        marginRight: "10px",
        paddingBottom: "35px",
        backgroundColor: "primary.xlight",
        borderRadius: "5px",
        flex: "1",
    };

    const hintBodyLinkText = {
        mx: "3px",
    };

    const hintListContainer = {
        my: "10px",
    };

    const hintListItem = {
        display: "block",
    };

    const hintBodyButton = {
        backgroundColor: "primary.light",
        px: "10px",
        fontWeight: "500",
        textTransform: "none",
        float: "left",
        marginTop: "10px",
    };

    const hintBodyButtonText = {
        fontWeight: "500",
        textTransform: "none",
        float: "left",
        marginTop: "10px",
        "&:hover": {
            color: "black.main",
            backgroundColor: "transparent",
        },
    };

    const fileFormContainer = {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: ["xs", "sm"].includes(screenSizeHook) ? "column" : "row",
    };

    const fileForm = {
        flex: "1",
    };

    const notificationBodyText = {
        color: "black.main",
    };

    useEffect(() => {
        if (![null, undefined, ""].includes(contractID))
            dispatch(getContractDetail(contractID, userToken));
        // eslint-disable-next-line
    }, [userToken, contractID]);

    useEffect(() => {
        let run = true;
        if (run) {
            const { contract } = store.getState();
            state && setContractID(state);
            if (
                contract.contractCreator !== undefined &&
                contract.contractCreator.contract.detail !== undefined
            ) {
                setContractID(
                    contract.contractCreator.contract.detail.sContractID
                );
            }
        }
        return () => (run = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (data) => {
        const contractID =
            contractCreatedDetails !== null
                ? contractCreatedDetails.contrato[0]["sContractID"]
                : "";

        setLoading(true);

        if (contractID) {
            if (!docNames.length) {
                setLoading(false);
                setModalTitle("Adjunta los documentos");
                setModalMessage(
                    "No has adjuntado ningún documento, por favor adjunta los documentos necesarios de todos los participantes"
                );
                setModalButtonTitle("Volver a intentar");
                setShowModal(true);
            } else {
                try {
                    const response = await mergeDocs(contractID, userToken);

                    if (response.status === apiMessage.SUCCESS) {
                        const sendNotificationResponse =
                            await sendContractEmail(contractID, userToken);

                        if (
                            sendNotificationResponse.status ===
                            apiMessage.SUCCESS
                        ) {
                            setLoading(false);
                            setModalTitle("Excelente");
                            setModalMessage(
                                "Tu contrato ha sido creado con éxito, por favor revise su correo para continuar con el proceso de firma, certificación o protocolización según sea el caso."
                            );
                            setModalButtonTitle("Ver mis contratos");
                            setShowModal(true);
                            store.dispatch(resetContract());
                        } else {
                            setLoading(false);
                            setModalTitle("Ups! algo ha pasado");
                            setModalMessage(
                                "Al parecer ha ocurrido un error, por favor vuelve a adjuntar tus archivos"
                            );
                            setModalButtonTitle("Volver a intentar");
                            setShowModal(true);
                        }
                    } else {
                        setLoading(false);
                        setModalTitle("Ups! algo ha pasado");
                        setModalMessage(
                            "Al parecer ha ocurrido un error, por favor vuelve a adjuntar tus archivos"
                        );
                        setModalButtonTitle("Volver a intentar");
                        setShowModal(true);
                    }
                } catch (err) {
                    setLoading(false);
                    setModalTitle("Ups! algo ha pasado");
                    setModalMessage(
                        "Al parecer ha ocurrido un error, por favor vuelve a adjuntar tus archivos"
                    );
                    setModalButtonTitle("Volver a intentar");
                    setShowModal(true);
                }
            }
        }
    };

    const resetFile = () => {
        setFilePdf(null);
    };

    const upFile = async (file) => {
        try {
            setRunSpinner(true);
            resetFile();
            const contractID =
                contractCreatedDetails !== null
                    ? contractCreatedDetails.contrato[0]["sContractID"]
                    : "";

            const fileBase64 = await asyncFileToBase64(file);

            const response = await contractStoreFilesRequest(
                contractID,
                fileBase64.replace("data:application/pdf;base64,", ""),
                userToken
            );

            setRunSpinner(false);

            if (response.status === apiMessage.SUCCESS) {
                setDocNames([...docNames, file.name]);
            }
        } catch (err) {
            setModalTitle("Ups, algo ha salido mal");
            setModalMessage(
                "Ha ocurrido un error, por favor intentalo de nuevo"
            );
            setModalButtonTitle("Volver a intentar");
            setShowModal(true);
            console.log(err);
        }
    };

    const onInput = (event) => {
        setFilePdf(event.target.files[0]);
    };

    const handleModal = () => {
        setModalTitle("");
        setModalMessage("");
        setModalButtonTitle("");

        if (docNames.length) {
            setShowModal(!showModal);
            navigate(to.CONTRACT_STATUS);
        } else {
            setShowModal(!showModal);
        }
    };

    const displayModal = () => {
        return (
            <PopUp
                height={"330px"}
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                title={modalTitle}
                onBackdropClick={handleModal}
            >
                <Text
                    sx={bodyTextStyles}
                    align="center"
                    variant="body1"
                    paragraph
                >
                    {modalMessage}
                </Text>
                <Btn
                    sx={{ marginTop: "10px" }}
                    variant="contained"
                    text={modalButtonTitle}
                    size="lg"
                    color="primary"
                    type="submit"
                    buttonClass="button-submit"
                    onClick={handleModal}
                />
            </PopUp>
        );
    };

    const displayDocNames = () => {
        if (docNames.length > 0) {
            return docNames.map((name, index) => {
                return (
                    <li key={index} id={name} className="file-name__container">
                        <Text sx={fileNameStyles} align="left">{`${index + 1
                            }. ${name}`}</Text>
                        <BiCheck className="icon--md icon--black" />
                    </li>
                );
            });
        }
    };

    const handleDisplayForm = () => {
        setDisplayForm(!displayForm);
        // if(!displayForm) setStepsEnabled3_2(true);
    };

    const handleNotification = () => {
        setShowNotification(true);

        setTimeout(() => {
            setShowNotification(false);
        }, 4000);
    };

    return (
        <Fragment>
            {/* <Steps
                enabled={stepsEnabled3_1}
                steps={stepsTramit3_2}
                initialStep={0}
                onExit={() => setStepsEnabled3_1(false)}
                options={optionsSteps}
            /> */}

            {/* <Steps
                enabled={stepsEnabled3_2}
                steps={stepsTramit3_3}
                initialStep={0}
                onExit={() => setStepsEnabled3_2(false)}
                options={optionsSteps}
            /> */}

            <Notificationbanner display={showNotification}>
                <Text sx={notificationBodyText}>
                    Ya haz adjuntado un archivo
                </Text>
            </Notificationbanner>

            <div className="procedure-creation__form-container">
                <div
                    className={
                        ["xs", "sm"].includes(screenSizeHook)
                            ? null
                            : "form__container"
                    }
                    style={{ width: "100% !important" }}
                >
                    <div
                        className="form-title__container"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <Text sx={titleStyles} align="center">
                            {[null, undefined, ""].includes(filePdf)
                                ? "Documentos"
                                : "Asignar firma en documento"}
                        </Text>
                        <Text sx={hintStyles} align="center">
                            {[null, undefined, ""].includes(filePdf)
                                ? "Ingrese los datos solicitados"
                                : "Haga clic en el documento para empezar a asignar en donde quiere que firmen los participantes de este tramite."}
                        </Text>

                        {![null, undefined, ""].includes(filePdf) && (
                            <Text sx={hintStyles} align="center">
                                Nota: Recuerde asignar la firma de los
                                participantes en cada cédula ademas del espacio
                                en donde tiene que firmar.
                            </Text>
                        )}
                    </div>

                    {/* TODO cargar documentos*/}

                    {[null, undefined, ""].includes(filePdf) ? (
                        <Box sx={fileFormContainer}>
                            <Box sx={hintContainer}>
                                <Text sx={titleStyles} align="left">
                                    Antes de continuar, por favor sigue estas
                                    instruciones, una vez adjunto, esta acción
                                    es irreversible. Debes adjuntar el documento
                                    de la siguiente manera:
                                </Text>
                                <List sx={hintListContainer}>
                                    <ListItem
                                        sx={hintListItem}
                                        disableGutters
                                        divider
                                    >
                                        Todos los documentos solicitados (cédula
                                        de identidad, pasaporte, contrato(s),
                                        documento(s) de texto, etc) deben estar
                                        ordenados y ser parte de un (1) archivo
                                        PDF
                                    </ListItem>
                                    <ListItem
                                        sx={hintListItem}
                                        disableGutters
                                        divider
                                    >
                                        Si todos tus archivos están separados,
                                        puedes usar{" "}
                                        <Link
                                            href={externalLink.FV_APP_PDF}
                                            sx={hintBodyLinkText}
                                            target="_blank"
                                        >
                                            esta herramienta
                                        </Link>{" "}
                                        para ordenarlos y crear un PDF
                                    </ListItem>
                                    <ListItem
                                        sx={hintListItem}
                                        disableGutters
                                        divider
                                    >
                                        Solo puedes adjuntar un (1) solo archivo
                                        PDF
                                    </ListItem>
                                    <ListItem sx={hintListItem} disableGutters>
                                        <Link
                                            href={externalLink.FV_APP_PDF}
                                            target="_blank"
                                            className="tool"
                                        >
                                            <Btn
                                                variant="contained"
                                                text="Generar PDF con mis archivos"
                                                size="md"
                                                sx={hintBodyButton}
                                            />
                                        </Link>
                                        <Btn
                                            buttonClass="option-load"
                                            variant="text"
                                            text="Ya cuento con un archivo PDF"
                                            size="md"
                                            sx={hintBodyButtonText}
                                            onClick={handleDisplayForm}
                                        />
                                    </ListItem>
                                </List>
                            </Box>

                            {displayForm && (
                                <Box sx={fileForm}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="input-container">
                                            <div className="input-file__container input-load-doc">
                                                <input
                                                    type="file"
                                                    multiple={false}
                                                    onInput={
                                                        docNames.length > 0 ||
                                                            docNames.length === 1
                                                            ? handleNotification
                                                            : onInput
                                                    }
                                                    accept=".pdf"
                                                    className={
                                                        errors.proceduredocs &&
                                                        "input-container--error"
                                                    }
                                                    {...register(
                                                        "proceduredocs"
                                                    )}
                                                />
                                                <div className="input-file-hint__container">
                                                    <FileUploadIcon />
                                                    <Text
                                                        sx={titleHintStyle}
                                                        align="center"
                                                    >
                                                        Haz click o arrastra a
                                                        esta área para subir tu
                                                        PDF
                                                    </Text>
                                                </div>
                                                {runSpinner && (
                                                    <Spinner
                                                        loading={true}
                                                        type="bar"
                                                        opacity={true}
                                                    />
                                                )}
                                            </div>
                                            <ul className="file-list__container">
                                                {displayDocNames()}
                                            </ul>
                                        </div>
                                        <Btn
                                            buttonClass="finish"
                                            variant="contained"
                                            text="Finalizar"
                                            // disabled={docNames.length === 0 ? true : false}
                                            size="lg"
                                            type="submit"
                                            loading={loading}
                                            endIcon={
                                                !loading && (
                                                    <BsChevronRight className="search-icon icon--xs icon--white" />
                                                )
                                            }
                                            sx={buttonSubmitStyles}
                                        />
                                    </form>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <PositionFirm
                            custonSigner={undefined}
                            filePdf={filePdf}
                            resetFile={() => resetFile()}
                            editedFile={(file) => upFile(file)}
                        />
                    )}
                </div>
            </div>

            {displayModal()}
        </Fragment>
    );
};

export default Procedurecreationstep3;
