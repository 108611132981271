import { useState, useEffect, useCallback, Fragment } from "react";
import { gridColumns } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import {
    getTramitsStorage,
    getTypeTramitStorage,
} from "../../../store/contract/tramites";
import Spinner from "../../spinners/Spinner";
import { CustomToobarTableList, GeneralListProced } from "../..";
import { getUsersStorage } from "../../../store/users/userList";
import { setearFecha } from "../../../utils/enums";
import ModalAlert from "../../floats/ModalAlert";

const ManageProcedures = () => {
    const {
        session: {
            login: { user },
        },
        users: {
            listUsers,
            loading,
            status: requestStatusUsersList,
            message: resquestUserListMsg,
        },
        contract: {
            tramites: {
                tramList,
                typeTramList,
                loadingTramits,
                loadingTypeTramits,
                status,
                message,
            },
        },
    } = useSelector((state) => state.root);

    const dispatch = useDispatch();

    const [textSpinner, setTextSpinner] = useState("");
    const [dataGrid, setDataGrid] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalStatus, setModalStatus] = useState({});

    const getContracts = () => {
        const { token } = user;

        dispatch(getUsersStorage(token));

        dispatch(getTramitsStorage(token));

        dispatch(getTypeTramitStorage(token));
    };

    useEffect(() => {
        if (![null, undefined, "success", ""].includes(status)) {

            setModalStatus({
                title: "¡Ups!, Algo ha pasado",
                message: message,
                buttonText: "Cerrar",
                modalIcon: "warnIcon"
            });
            setShowModal(true);
        } else if (
            ![null, undefined, "success", ""].includes(requestStatusUsersList)
        ) {
            setModalStatus({
                title: "¡Ups!, Algo ha pasado",
                message: resquestUserListMsg,
                buttonText: "Cerrar",
                modalIcon: "warnIcon"
            });
            setShowModal(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, requestStatusUsersList]);

    useEffect(() => {
        setTextSpinner("Cargando listado de trámites...");
        const tramites = ![null, undefined, "string", ""].includes(
            typeof tramList
        )
            ? tramList.map((tramite) => {
                listUsers.forEach((user) => {
                    if (tramite.iContractOwner === user.iUserID) {
                        tramite = { ...tramite, user };
                    }
                });

                typeTramList.forEach((type) => {
                    if (tramite.iContractTypeFeeID === type.iContractTypeID) {
                        tramite = {
                            ...tramite,
                            type,
                        };
                    }
                });

                return tramite;
            })
            : [];

        setDataGrid(tramites);
    }, [tramList, listUsers, typeTramList]);

    useEffect(() => {
        getContracts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleModal = () => {
        setShowModal(!showModal);
        setModalStatus({
            title: "",
            message: "",
            buttonText: "",
            modalIcon: ""
        });
    };

    const renderList = useCallback(() => {
        return (
            <Fragment>
                <br />

                <CustomToobarTableList
                    title={"Historial de trámites."}
                    subTitle={"Resumen de historial de trámites."}
                    onUpdate={() => {
                        setTextSpinner("Actualizando listado de trámites...");
                        getContracts();
                    }}
                    onCreate={() => ""}
                    onDisableCreate={true}
                />

                <div
                    className="container--relative contracts-status-list__container"
                    style={{ height: "auto", width: "100%" }}
                >
                    <div style={{ display: "flex", height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <GeneralListProced
                                dataGrid={[...dataGrid].map((item) => {
                                    return {
                                        sProcess: item["sProcess"],
                                        sContractID: item["sContractID"],
                                        autoId: item["autoId"],
                                        sContractName: item["sContractName"],
                                        sTipoContrato: item["sTipoContrato"],
                                        sTipoFirma: item["sTipoFirma"],
                                        sStatus: item["sStatus"],
                                        sPaymentStatus: item["sPaymentStatus"],
                                        dContractDT: setearFecha(
                                            item["dContractDT"]
                                        ),
                                        withDocument: item["withDocument"],
                                        iSignedCount: item["iSignedCount"],
                                    };
                                })}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, loading, loadingTramits, loadingTypeTramits, gridColumns]);

    return (
        <div className="content-detail__container">
            {(loading || loadingTramits || loadingTypeTramits) && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={textSpinner}
                    opacity={true}
                />
            )}

            <ModalAlert
                buttonText={modalStatus.buttonText}
                modalIcon={modalStatus.modalIcon}
                onAcept={handleModal}
                openModal={showModal}
                setOpenModal={setShowModal}
                subTitle={modalStatus.message}
                title={modalStatus.title}
            />

            {renderList()}
        </div>
    );
};

export default ManageProcedures;
