import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
    Grid,
    TextField,
} from "@mui/material";

import { FirmaVirtualAvanzadaStyles } from "../../FirmaVirtualAvanzadaStyles.css";
import { addClvUnicaDatosUsuario } from "../../../../../store/fvavanzada/fvavanzada";
import { nextStep, validation } from "../../../../../utils/validations";
import ModalAlert from "../../../../floats/ModalAlert";

const options = [
    { value: "Soltero(a)", label: "Soltero(a)" },
    { value: "Casado(a)", label: "Casado(a)" },
    { value: "Viudo(a)", label: "Viudo(a)" },
    { value: "Separado(a)", label: "Separado(a)" },
    { value: "Divorciado(a)", label: "Divorciado(a)" },
];

const CompletarDatosUsuario = () => {
    const styles = FirmaVirtualAvanzadaStyles();
    const {
        fvavanzada: { clvUnicadAtosUsuario },
    } = useSelector((state) => state.root);
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [modalStatus, setModalStatus] = useState({});
    const { handleSubmit, control, setValue, fieldState } = useForm({
        defaultValues: {
            numeroDocumento: "",
            telefono: "",
            estadoCivil: "",
            email: "",
            fechaNacimiento: "",
            pinFirma: "",
        },
    });

    const onSubmit = (data) => {
        console.log("submit", data);
        console.log(fieldState);
        const dataRedux = { ...clvUnicadAtosUsuario };

        dispatch(
            addClvUnicaDatosUsuario(
                Object.assign(dataRedux, { formData: data })
            )
        );
        setModalStatus({
            title: "¡Felicitaciones!",
            message: `Ha concluido con la sección de clave unica, ${nextStep}`,
            buttonText: "Cerrar",
            modalIcon: 'successIcon'
        });
        setShowModal(true);

    };

    const handleModal = () => {
        setShowModal(!showModal);
        setModalStatus({
            title: "",
            message: "",
            buttonText: "",
            modalIcon: ""
        });
    };

    useEffect(() => {
        if (
            ![null, undefined].includes(clvUnicadAtosUsuario) &&
            ![null, undefined].includes(clvUnicadAtosUsuario["formData"])
        ) {
            setValue("email", clvUnicadAtosUsuario["formData"]["email"]);
            setValue(
                "estadoCivil",
                clvUnicadAtosUsuario["formData"]["estadoCivil"]
            );
            setValue(
                "fechaNacimiento",
                clvUnicadAtosUsuario["formData"]["fechaNacimiento"]
            );
            setValue(
                "numeroDocumento",
                clvUnicadAtosUsuario["formData"]["numeroDocumento"]
            );
            setValue("pinFirma", clvUnicadAtosUsuario["formData"]["pinFirma"]);
            setValue("telefono", clvUnicadAtosUsuario["formData"]["telefono"]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clvUnicadAtosUsuario]);

    return (
        <>

            <ModalAlert
                buttonText={modalStatus.buttonText}
                modalIcon={modalStatus.modalIcon}
                onAcept={handleModal}
                openModal={showModal}
                setOpenModal={setShowModal}
                subTitle={modalStatus.message}
                title={modalStatus.title}
            />

            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ marginTop: "10px"/* , width: "100%" */ }}
            >

                <Typography
                    variant="h6"
                    sx={{
                        marginBottom: "10px",
                        fontSize: "1em",
                        textAlign: 'center'
                    }}
                >
                    Cargue su informacion de usuario al momento de realizar el
                    inicio de sesion en clave unica.
                </Typography>
                <Grid container spacing={2} /* style={{ width: "100%" }} */>
                    <Grid item xs={12} sm={6}>
                        <Typography variant={""}>
                            Número de Documento
                        </Typography>
                        <Controller
                            name="numeroDocumento"
                            control={control}
                            rules={{
                                required: "El Número de Documento es requerido",
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                return (
                                    <TextField
                                        type="number"
                                        error={fieldState.error ? true : false}
                                        className={styles.inputs}
                                        ref={ref}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error.message
                                                : null
                                        }
                                        placeholder={"75847558"}
                                        color={
                                            fieldState.error
                                                ? "error"
                                                : "success"
                                        }
                                        focused={
                                            fieldState.isTouched ||
                                            fieldState.isDirty
                                        }
                                        fullWidth
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant={""}>Teléfono</Typography>
                        <Controller
                            control={control}
                            name="telefono"
                            rules={{
                                required: "El Teléfono es requerido",
                            }}
                            render={({
                                field: { onChange, value, ref },
                                fieldState,
                            }) => {
                                return (
                                    <TextField
                                        type="number"
                                        error={fieldState.error ? true : false}
                                        className={styles.inputs}
                                        ref={ref}
                                        onBlur={(e) => {
                                            if (!validation.phone.test(e.target.value))
                                                setValue('telefono', '')
                                        }}
                                        onChange={onChange}
                                        value={value}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error.message
                                                : null
                                        }
                                        placeholder={
                                            "+56912345478"
                                        }
                                        color={
                                            fieldState.error
                                                ? "error"
                                                : "success"
                                        }
                                        focused={
                                            fieldState.isTouched ||
                                            fieldState.isDirty
                                        }
                                        fullWidth
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant={""}>Estado Civil</Typography>
                        <Controller
                            control={control}
                            defaultValue={"Soltero(a)"}
                            name="estadoCivil"
                            render={({
                                field: { onBlur, value, ref },
                                fieldState,
                            }) => (
                                <Select
                                    inputRef={ref}
                                    classNamePrefix="addl-class"
                                    options={options}
                                    value={options.find(
                                        (c) => c.value === value
                                    )}
                                    onBlur={onBlur}
                                    onChange={(e) =>
                                        setValue("estadoCivil", e.value)
                                    }
                                    defaultValue={""}
                                    helperText={
                                        fieldState.error
                                            ? fieldState.error.message
                                            : null
                                    }
                                    color={
                                        fieldState.error ? "error" : "success"
                                    }
                                    focused={
                                        fieldState.isTouched ||
                                        fieldState.isDirty
                                    }
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant={""}>Correo</Typography>
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: "El Correo es requerido",
                            }}
                            render={({
                                field: { onChange, value, ref },
                                fieldState,
                            }) => {
                                return (
                                    <TextField
                                        type="email"
                                        error={fieldState.error ? true : false}
                                        className={styles.inputs}
                                        ref={ref}
                                        onBlur={(e) => {
                                            if (!validation.email.test(e.target.value))
                                                setValue('email', '')
                                        }}
                                        onChange={onChange}
                                        value={value}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error.message
                                                : null
                                        }
                                        placeholder={
                                            "contacto@firmavirtual.com"
                                        }
                                        color={
                                            fieldState.error
                                                ? "error"
                                                : "success"
                                        }
                                        focused={
                                            fieldState.isTouched ||
                                            fieldState.isDirty
                                        }
                                        fullWidth
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant={""}>
                            Fecha de nacimiento
                        </Typography>
                        <Controller
                            name="fechaNacimiento"
                            control={control}
                            rules={{
                                required: "La Fecha de Nacimiento es requerida",
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                return (
                                    <TextField
                                        type="date"
                                        error={fieldState.error ? true : false}
                                        className={styles.inputs}
                                        ref={ref}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error.message
                                                : null
                                        }
                                        placeholder={"00/00/0000"}
                                        color={
                                            fieldState.error
                                                ? "error"
                                                : "success"
                                        }
                                        focused={
                                            fieldState.isTouched ||
                                            fieldState.isDirty
                                        }
                                        fullWidth
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant={""}>Pin Firma</Typography>
                        <Controller
                            name="pinFirma"
                            control={control}
                            rules={{
                                required: "El Pin de Firma es requerido y 4 digito",
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                return (
                                    <TextField
                                        type="number"
                                        error={fieldState.error ? true : false}
                                        className={styles.inputs}
                                        ref={ref}
                                        onBlur={(e) => {
                                            if (e.target.value.length < 4)
                                                setValue('pinFirma', '');
                                        }}
                                        onChange={onChange}
                                        value={value}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error.message
                                                : null
                                        }
                                        placeholder={"8754"}
                                        color={
                                            fieldState.error
                                                ? "error"
                                                : "success"
                                        }
                                        focused={
                                            fieldState.isTouched ||
                                            fieldState.isDirty
                                        }
                                        fullWidth
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit">Completar</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default CompletarDatosUsuario;
