import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiRightArrowAlt } from "react-icons/bi";

import { motion } from "framer-motion";

import { Box, ListItem, List, Link } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

import { FormularioUachtyles } from "../../index.css";
import { errorStepForm, saveFormInfo } from "../../../../store/formUach/formUach";
import { ReactComponent as FileUploadIcon } from "../../../../assets/icons/fileupload.svg";
import Text from "../../../heading/Text";
import { Button } from "@mui/material";
import { externalLink, fileToBase64, openBase64NewTab } from "../../../../utils/enums";
import Btn from "../../../buttons/Button";
import PositionFirm from "../../../forms/positionFirm";


const StepFive = ({ watch, trigger, setValue }) => {
    const styles = FormularioUachtyles();
    const {
        formUach: {
            fullName,
            tramitPdf
        }
    } = useSelector((state) => state.root);

    const dispatch = useDispatch();
    const [errorInput, setErrorInput] = useState(undefined);
    const [displayForm, setDisplayForm] = useState(false);
    const [filePdf, setFilePdf] = useState(null);
    const nameInput = 'tramitPdf';
    const watchTramitPdf = watch(nameInput);

    const resetFile = () => {
        setFilePdf(null);
        dispatch(saveFormInfo({ indexe: 'addSing', value: false }));
    }

    const handleDisplayForm = () => {
        setDisplayForm(!displayForm);
        // if(!displayForm) setStepsEnabled3_2(true);
    };

    useEffect(() => {
        if (![undefined, null, ''].includes(errorInput)) {
            dispatch(errorStepForm(false));
        } else {
            dispatch(errorStepForm(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorInput]);

    useEffect(() => {
        setValue(nameInput, tramitPdf);
        if (![undefined, null, ''].includes(tramitPdf)) dispatch(errorStepForm(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tramitPdf]);

    useEffect(() => {

        trigger();

        if (!['', null, undefined].includes(watchTramitPdf) && watchTramitPdf !== tramitPdf) dispatch(saveFormInfo({ indexe: nameInput, value: watchTramitPdf }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchTramitPdf]);

    return (
        <div className={styles.stepBox}>

            <motion.div
                initial={{ y: "50vh" }}
                animate={{ y: 0 }}
                transition={{ stiffness: 150 }}>

                <Grid container xs={12}>

                    <Grid item={true} xs={1} className={styles.boxIndetify}>

                        5<BiRightArrowAlt color="rgb(0, 79, 159)" />

                    </Grid>

                    {!filePdf ? (<Grid item={true} xs={11}>

                        <div className={styles.fileFormContainer}>


                            <Box className={styles.hintContainer}>

                                <Text className={styles.titleStyles} align="left">
                                    Antes de continuar, por favor sigue estas
                                    instruciones, una vez adjunto, esta acción es
                                    irreversible. Debes adjuntar el documento de la
                                    siguiente manera:
                                </Text>

                                <List className={styles.hintListContainer}>

                                    <ListItem
                                        className={styles.hintListItem}
                                        disableGutters
                                        divider
                                    >
                                        Todos los documentos solicitados (cédula de
                                        identidad, pasaporte, contrato(s),
                                        documento(s) de texto, etc) deben estar
                                        ordenados y ser parte de un (1) archivo PDF
                                    </ListItem>

                                    <ListItem
                                        className={styles.hintListItem}
                                        disableGutters
                                        divider
                                    >
                                        Si todos tus archivos están separados,
                                        puedes usar{" "}
                                        <Link
                                            href={externalLink.FV_APP_PDF}
                                            className={styles.hintBodyLinkText}
                                            target="_blank"
                                        >
                                            esta herramienta
                                        </Link>{" "}
                                        para ordenarlos y crear un PDF
                                    </ListItem>

                                    <ListItem
                                        className={styles.hintListItem}
                                        disableGutters
                                        divider
                                    >
                                        Solo puedes adjuntar un (1) solo archivo PDF
                                    </ListItem>

                                    <ListItem className={styles.hintListItem} disableGutters>
                                        <Link
                                            href={externalLink.FV_APP_PDF}
                                            target="_blank"
                                            className="tool"
                                        >
                                            <Btn
                                                variant="contained"
                                                text="Generar PDF con mis archivos"
                                                size="md"
                                                sx={styles.hintBodyButton}
                                            />
                                        </Link>
                                        <Btn
                                            buttonClass="option-load"
                                            variant="text"
                                            text="Ya cuento con un archivo PDF"
                                            size="md"
                                            sx={styles.hintBodyButtonText}
                                            onClick={handleDisplayForm}
                                        />
                                    </ListItem>

                                </List>

                            </Box>

                            {displayForm && (<Fragment>

                                <div className="input-file__container">

                                    <FormControl
                                        fullWidth
                                    >

                                        <input
                                            type="file"
                                            multiple={false}
                                            onChange={(event) => {
                                                setFilePdf(event.target.files[0]);
                                                dispatch(saveFormInfo({ indexe: 'addSing', value: true }));
                                            }}
                                            accept=".pdf"
                                        />

                                        <div className="input-file-hint__container">
                                            <FileUploadIcon />
                                            <Text sx={styles.titleHintStyle} align="center">
                                                Haz click o arrastra a esta área para
                                                subir tu PDF
                                            </Text>
                                        </div>

                                    </FormControl>
                                </div>

                                {[watchTramitPdf].includes(undefined) && (
                                    <span className="input--error">
                                        Debes adjuntar los documentos
                                        correspondientes
                                    </span>
                                )}

                            </Fragment>)}

                        </div>

                        <br />


                        <div className={styles.containerShowCancel}>



                            {(![undefined, null, ''].includes(tramitPdf)) && (

                                <Button
                                    color="secondary"
                                    variant="contained"
                                    size="large"
                                    onClick={() => {
                                        handleDisplayForm();
                                        dispatch(saveFormInfo({ indexe: nameInput, value: undefined }));
                                    }}
                                >
                                    Cancelar Documento
                                </Button>
                            )}

                            {(![watchTramitPdf].includes(undefined)) && (
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={() => openBase64NewTab(watchTramitPdf)}
                                >
                                    Ver Documento cargado
                                </Button>
                            )}

                        </div>


                    </Grid>) : <Grid item={true} xs={11}>
                        <PositionFirm
                            custonSigner={[
                                {
                                    full_name: fullName

                                }
                            ]}
                            filePdf={filePdf}
                            resetFile={() => resetFile()}
                            editedFile={(file) => {
                                fileToBase64(file, (err, result) => {
                                    if (result) {

                                        const docPdfBase64 = result.replace('data:application/pdf;base64,', '');

                                        dispatch(saveFormInfo({ indexe: nameInput, value: docPdfBase64 }));
                                        dispatch(saveFormInfo({ indexe: 'fileName', value: file.name }));
                                        handleDisplayForm();
                                    }
                                });
                                resetFile();
                                setErrorInput(false);
                            }}
                        />
                    </Grid>}

                </Grid>

            </motion.div>

        </div>
    );
}

export default StepFive;