import React, { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/material";

import { ModalDetailAgreementStyles } from "./ModalDetailAgreement.css";
import Spinner from "../../../../spinners/Spinner";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import { useDispatch, useSelector } from "react-redux";
import { BsEye } from "react-icons/bs";
import GridDataAgreement from "../GridDataAgreement";
import { getAgreementStorage } from "../../../../../store/organization";
import ContractType from "../../hooks";

const DetailAgreement = ({ agreement }) => {
    const {
        contract: {
            tramites: {
                allTramitAgreements
            }
        },
        organizations: {
            loading,
            status,
            agreement: detailAgreement
        },
        session: {
            login: { user },
        },
    } = useSelector((state) => state.root);
    const dispatch = useDispatch();
    const screenSizeHook = ScreenSizeHook();
    const styles = ModalDetailAgreementStyles();
    const { typeSignature } = ContractType();

    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [description, setDescription] = useState("");
    const [texButtonGoback, setTexButtonGoback] = useState("");
    const [textSpinner, setTextSpinner] = useState("");
    const [agreementList, setAgreementList] = useState([]);

    const handleClickOpen = async () => {
        const { token } = user;

        setTexButtonGoback("Cerrar");
        setDescription("Detalles de convenios");
        setTextSpinner("Cargando convenios ...");
        setOpen(true);

        dispatch(getAgreementStorage(token, agreement['agreementId']));

    };

    const handleClose = () => {
        if (loading) return;
        setOpen(false);
    };

    useEffect(() => {

        if (![null, undefined, ''].includes(detailAgreement)) {

            const agreements = [...detailAgreement['agreement_detail']];

            const findAgreements = [];

            agreements.forEach((agreement) => {
                const tramit = allTramitAgreements.find((tramitAgreement) => tramitAgreement['iContractTypeFeeID'] === agreement['iContractTypeFeeID']);

                if (![null, undefined, ''].includes(tramit)) findAgreements.push(Object.assign({ ...tramit, agreement: detailAgreement['agreement'], tipoFirma: typeSignature(agreement['iContractTypeFeeID'], allTramitAgreements) }, agreement));

            });

            setModalTitle(`Rut: ${agreement['rut']} Nombre: ${agreement['agreement']}`);
            setAgreementList(findAgreements);
        } else {
            setModalTitle('Error cargando convenio');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailAgreement]);

    return (
        <Fragment>
            <BsEye
                className="icon--xs icon--blue-light icon-pointer"
                onClick={() => handleClickOpen()}
                style={{ marginRight: "10px" }}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={["xs"].includes(screenSizeHook) ? "xs" : "xl"}
                fullWidth={true}
            >
                <DialogTitle className={styles.constentDialogTitle}>
                    {modalTitle}
                </DialogTitle>

                <DialogContent>
                    {loading && (
                        <Spinner
                            loading={true}
                            type="bar"
                            text={textSpinner}
                            opacity={true}
                        />
                    )}

                    <DialogContentText align="center">
                        {description}
                    </DialogContentText>

                    <br />

                    <GridDataAgreement tramitsAgreement={agreementList} />

                </DialogContent>

                <DialogActions style={{ justifyContent: "center" }}>
                    {status !== undefined && (
                        <Stack className={styles.constentDialogActionGoBack}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleClose();
                                }}
                                className={styles.goBackButton}
                            >
                                {texButtonGoback}
                            </Button>
                        </Stack>
                    )}
                </DialogActions>

            </Dialog>

        </Fragment>
    );
};

export default DetailAgreement;
