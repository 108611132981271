import React, {useState, useEffect, Fragment} from "react";
import _ from "lodash";
import { getContractFilesList } from "../../store/contract/contractDetail";
import { getUserToken } from "../storeSelector/storeSelector";
import { store } from "../../store/storeConfig";
import { setNewDocsOrder, deleteDocs, mergeDocs } from "../../utils/api";
import { ListCarditem, Btn, Gobackbutton } from ".."
import { apiMessage } from "../../utils/enums";
import { BsArrowRight, BsTrash } from "react-icons/bs";
import { MdClear } from "react-icons/md";
import { PopUp, Text } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../spinners/Spinner";
import Tooltip from '@mui/material/Tooltip';
import { bodyTextStyles } from "../../utils/validations";

const Board = () => {
    const { state } = useLocation();
    const userToken = getUserToken();
    const navigation = useNavigate();
    const [board, setBoard] = useState([]);
    const [contractFiles, setContractFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [contentSpinner, setContentSpinner] = useState(false);
    const [newKey, setNewKey] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [modalButtonTitle, setModalButtonTitle] = useState("");
    const [sortedSuccess, setSortedSuccess] = useState(false);

    const buttonStatusMenu = {
        backgroundColor: "primary.main",
        color: "white.main",
        textTransform: "none",
        fontSize: "1rem",
        borderRadius: "5px",
        padding: "6px 10px",
        fontWeight: "500",
        "&:hover": {
            color: "white.main",
        }
    };

    useEffect(() => {
        let run = true;
        if(run) {
            setContentSpinner(true);
            store.dispatch(getContractFilesList(state, userToken));

            const unsubscribe = store.subscribe(() => {
                const {contractCreator} = store.getState().root.contract;
    
                if(!_.isEmpty(contractCreator)) {
                    if(contractCreator.contractCreatedDetails !== null) {
                        setContractFiles(contractCreator.contractCreatedDetails.files);
                        setContentSpinner(false);
                    }
                }
            })

            return () => unsubscribe();
        }
        return () => run = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newKey])

    const displayModal = () => {
        return (
            <PopUp
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                title={modalTitle}
                onBackdropClick={handleModal}
            >
                <Text
                    sx={bodyTextStyles}
                    align="center"
                    variant="body1"
                    paragraph
                >
                    {modalMessage}
                </Text>
                <Btn
                    variant="contained"
                    text={modalButtonTitle}
                    size="lg"
                    color="primary"
                    type="submit"
                    buttonClass="button-submit"
                    onClick={handleModal}
                    fullWidth
                />
            </PopUp>
        );
    };

    const getFiles = () => {
        return(
            <div className="height--full width--full flex--1 padding-x--lg">
                <h3 className="info-header__title margin-bottom--lg">Documentos adjuntos</h3>
                <ul className="container--relative height--full width--full">
                    {spinner && <Spinner loading={true} type="bar" opacity={true} />}
                    {contractFiles.map((file, index) => {
                        return (
                            <Tooltip title="Haz click en la flecha para añadir este archivo al nuevo orden" arrow={true} key={index}>
                                <li className="flex--between sort-list-item margin-bottom--lg padding-x--lg padding-y--md">
                                    <ListCarditem id={file.orden} file={file} />
                                    <div className="flex--start">
                                        <BsTrash className="icon-pointer margin-left--sm" onClick={() => deleteFiles(file)}/>
                                        <BsArrowRight className="icon-pointer margin-left--sm" onClick={() => sortItem(file.document)}/>
                                    </div>
                                </li>
                            </Tooltip>
                        )
                    })}
                </ul>
            </div>
        )
    }

    const renderBoard = () => {
        return (
            <div className="height--full sort-list-container width--full flex--1 padding--xl">
                <h3 className="info-header__title margin-bottom--lg">Reordena tus documentos aquí</h3>
                <ol>
                    {board.map((file, index) => {
                        return (
                            <li className="flex--between padding-y--md margin-bottom--sm" key={index}>
                                <ListCarditem id={file.orden} file={file} />
                                <div className="flex--start">
                                    <MdClear className="icon-pointer margin-left--sm" onClick={() => removeItemFromBoard(file.document)}/>
                                </div>
                            </li>
                        )
                    })}
                </ol>
                <div className="flex--end margin-top--lg sort-list-submit">
                    <Btn
                        variant="contained"
                        text="Aceptar"
                        sx={buttonStatusMenu}
                        onClick={containerToggler}
                        loading={loading}
                    />
                </div>
            </div>
        )
    }

    const deleteFiles = async(file) => {
        setSpinner(true);

        try {
            const response = await deleteDocs(state, [{document: file.document}], userToken);

            if(response.status === apiMessage.SUCCESS) {
                if(response.message.length) {
                    try {
                        const mergeFilesResponse = await mergeDocs(state, userToken);
        
                        if(mergeFilesResponse.status === apiMessage.SUCCESS) {
                            setSpinner(false);
                            setNewKey(Math.random());
                            window.location.reload(false);
                        } else {
                            setSpinner(false);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    navigation(-1);
                }
            } else {
                setSpinner(false);
                setNewKey(Math.random());
            }

        } catch(error) {
            setSpinner(false);
            setNewKey(Math.random());
            console.log(error);
        }

    }

    const sortItem = (file) => {
        const currentOrderCopy = _.cloneDeep(board);
        const newBoardItem = contractFiles.filter(item => item.document === file);
        currentOrderCopy.push(newBoardItem[0]);
        const finalBoard = _.uniqBy(currentOrderCopy, "document");

        setBoard(finalBoard);
    }

    const removeItemFromBoard = (documentId) => {
        const currentOrderCopy = _.cloneDeep(board);
        _.remove(currentOrderCopy, (item) => {
            return item.document === documentId;
        });

        setBoard(currentOrderCopy);
    }

    const handleModal = () => {
        setModalTitle("");
        setModalMessage("");
        setModalButtonTitle("");

        if(sortedSuccess) {
            setBoard([]);
            navigation(-1);
            setShowModal(!showModal);
        } else {
            setShowModal(!showModal);
        }
    };

    const containerToggler = async () => {
        if(board.length !== contractFiles.length) {
            setShowModal(true);
            setModalTitle("¡Espera!");
            setModalMessage("Debes reordenar todos los documentos para continuar");
            setModalButtonTitle("¡OK!");
        } else {
            setLoading(true);
            const payload = board.map((file, index) => {
                return {
                    document: file.document,
                    orden: index,
                }
            })
            
            try {
                const response = await setNewDocsOrder(state, payload, userToken);
    
                if(response.status === apiMessage.SUCCESS) {
                    const mergeFilesResponse = await mergeDocs(state, userToken);

                    if(mergeFilesResponse.status === apiMessage.SUCCESS) {
                        setSortedSuccess(true);
                        setLoading(false);
                        setShowModal(true);
                        setModalTitle("¡Listo!");
                        setModalMessage("Tus documentos se han reordenado correctamente. Recuerda reenviar el contrato para que los firmantes puedan verificar estos recientes cambios");
                        setModalButtonTitle("Continuar");
                    }
                } else {
                    setLoading(false);
                    console.log(response);
                }
            } catch (err) {
                setLoading(false);
                console.log(err);
            }
        }
    }

    return (
        <Fragment>
            {contentSpinner && <Spinner loading={true} type="bar" opacity={true} />}
            <div className="content-detail__container" key={newKey}>
                <div className="contract-details__main">
                    <div className="contract-detail__info-container">
                        <div className="contract-details__header">
                            <div className="breadcrumbs-navigation">
                                <div className="flex--start width--full margin-bottom--lg"><Gobackbutton gobackTitle="Volver"/></div>
                            </div>
                        </div>
                        <div className="board__container">
                            <div className="contract-details__container">
                                <div className="height--full width--full">
                                    <div className="sort-fields__container width--full flex--between">
                                        { !_.isEmpty(contractFiles) ? getFiles() : null }
                                        {renderBoard()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {displayModal()}
        </Fragment>
    );
}

export default Board;
