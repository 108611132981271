import { Navigate, Outlet } from "react-router-dom";
import { to } from "../../utils/enums";
import { getSessionAuth } from "../storeSelector/storeSelector";

const Authsession = (props) => {
    const { storeState } = props;
    const auth = getSessionAuth();

    return auth ? <Outlet context={storeState} /> : <Navigate to={to.HOME} />;
};

export default Authsession;
