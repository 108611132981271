import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart } from "../actions/api";
import { store } from "../storeConfig";
import { path, apiMessage } from "../../utils/enums";
import moment from "moment";

const slice = createSlice({
    name: "login",
    initialState: {
        user: {},
        userInfo: null,
        loading: false,
        lastFetch: null,
        message: "",
    },
    reducers: {

        PURGE_USER_INFO_STATE: (status, action) => {
            status.loading = false;
            status.userInfo = action.payload;
        },

        USER_INFO_REQUESTED: (status, action) => {
            status.message = "";
            status.loading = true;
            status.userInfo = null;
        },
        USER_INFO_SUCCESS: (status, action) => {
            if (action.payload.status === apiMessage.SUCCESS) {
                status.userInfo = action.payload.message;
            } else {
                status.userInfo = null;
                status.message = action.payload.message;
            }
            status.loading = false;
        },

        USER_INFO_FAIL: (status, action) => {
            status.message = action.payload;
            status.loading = false;
            status.userInfo = null;
        },

        PURGE_USER_STATE: (status, action) => {
            let data = { ...status.user }

            delete data["token"];

            status.loading = false;
            status.user = data;
        },
        LOGIN_USER_REQUESTED: (status, action) => {
            status.message = "";
            status.loading = true;
            status.user = {};
        },
        LOGIN_USER_REQUESTED_FAIL: (status, action) => {
            status.message = "";
            status.loading = false;
        },
        LOGIN_USER_RECIVED: (status, action) => {
            status.loading = false;
            status.lastFetch = Date.now();
        },
        LOGIN_USER_SUCCESS: (status, action) => {
            if (action.payload.status === apiMessage.SUCCESS) {
                status.user = {
                    status: action.payload.status,
                    firstName: action.payload.message.firstName,
                    lastName: action.payload.message.lastName,
                    userId: action.payload.message.userId,
                    token: action.payload.message.Authorization,
                    profileId: action.payload.message.profileId,
                    logged: true
                };
            } else {
                status.user = {
                    logged: false,
                };
                status.message = action.payload.message;
            }
            status.loading = false;
        },
        LOGIN_USER_FAIL: (status, action) => {
            status.message = action.payload;
            status.loading = false;
            status.user.logged = false;
        }
    }
});

export const {
    PURGE_USER_STATE,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAIL,
    LOGIN_USER_REQUESTED,
    LOGIN_USER_REQUESTED_FAIL,
    LOGIN_USER_RECIVED,
    PURGE_USER_INFO_STATE,
    USER_INFO_SUCCESS,
    USER_INFO_REQUESTED,
    USER_INFO_FAIL


} = slice.actions;

export const endSession = () => {

    store.dispatch({ type: PURGE_USER_STATE, payload: null });

    /* const keys = Object.keys(localStorage);
    if (keys?.length !== 0) {
        keys.forEach((ele) => {
            ele !== 'loginData' && localStorage.removeItem(ele)
        });
    }
    //localStorage.clear()
    return (
        persistor.purge(),
        persistor.flush(),
        store.dispatch({ type: PURGE_USER_STATE, payload: { logged: false } }),
        store.dispatch({ type: PURGE_USER_INFO_STATE, payload: null }),
        store.dispatch({ type: PURGE_STATE })
    ); */
};

export const userInfo = (token, userID) => {
    return apiCallStart({
        url: `/api/v1${path.USER_INFO}/${userID}`,
        method: "GET",
        data: null,
        onStart: USER_INFO_REQUESTED.type,
        onSuccess: USER_INFO_SUCCESS.type,
        onError: USER_INFO_FAIL.type,
        authorization: token
    });
};

export const logSession = (user) => {
    return apiCallStart({
        url: path.ACCOUNT_LOGIN,
        method: "POST",
        data: user,
        onStart: LOGIN_USER_REQUESTED.type,
        onSuccess: LOGIN_USER_SUCCESS.type,
        onError: LOGIN_USER_FAIL.type
    });
};

export const loadUserFromLocalStorage = (payload) => {
    store.dispatch({ type: LOGIN_USER_SUCCESS, payload });
};

export const loadLoggedSession = () => (dispatch, getState) => {
    const { lastFetch } = getState().session.login;

    const diffMinutes = moment().diff(moment(lastFetch), "minutes");

    if (diffMinutes < 10) return;
    dispatch(
        apiCallStart({
            url: path.ACCOUNT_LOGIN,
            onStart: LOGIN_USER_REQUESTED.type,
            onSuccess: LOGIN_USER_RECIVED.type,
            onError: LOGIN_USER_REQUESTED_FAIL.type,
        }),
    );
};

export default slice.reducer;

// Selectors
