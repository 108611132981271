import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useMediaQuery from '@mui/material/useMediaQuery';

import Slider from "react-slick";

import { CounterByStatus } from "..";
import { theme } from '../../theme/theme';

/**
 * Component in charge of showing, list of process counters
 * @param  props properties required for the component
 * @returns 
 */

const ContractListHeader = () => {
    const {
        contract: {
            contractCreator: {
                contractListActive
            }
        },
        formANFTramit: { allAnfTramit }
    } = useSelector(state => state.root);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [WidgetsData, setWidgetsData] = useState([]);


    const fields = [
        {
            color: "secondary",
            title: "Trámites en revisión",
            value: "REVIEW",
            counter: WidgetsData.filter(contract => ["CREATED", "REVIEW", "REJECTED"].includes(contract.sStatus)).length
        },
        {
            color: "senary",
            title: "Trámites pendientes de pago",
            value: "sPaymentStatus",
            counter: WidgetsData.filter(contract => contract.sStatus === "CREATED" && ["PENDING", "PARTIALLY_PAID"].includes(contract.sPaymentStatus)).length
        },
        {
            color: "tertiary",
            title: "Trámites por firmar",
            value: "SIGNED_PENDING",
            counter: WidgetsData.filter(contract => ["SIGNED_PENDING", "DELIVERED"].includes(contract.sStatus)).length,
        },
        {
            color: "quaternary",
            title: "Trámites en notaría",
            value: "IN_NOTARY",
            counter: WidgetsData.filter(contract => contract.sStatus === "IN_NOTARY").length
        },
        {
            color: "primary",
            title: "Trámites Entregados",
            value: "FINISHED",
            counter: WidgetsData.filter(contract => contract.sStatus === "FINISHED").length
        }
    ];

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
        console.log("REJECTED: ", WidgetsData.filter(contract => ["REJECTED"].includes(contract.sStatus)).length);
        console.log("REVIEW: ", WidgetsData.filter(contract => ["REVIEW"].includes(contract.sStatus)).length);
        console.log("CREATED: ", WidgetsData.filter(contract => ["CREATED"].includes(contract.sStatus)).length);
        console.log("SIGNED_PENDING: ", WidgetsData.filter(contract => ["SIGNED_PENDING"].includes(contract.sStatus)).length);
        console.log("DELIVERED: ", WidgetsData.filter(contract => ["DELIVERED"].includes(contract.sStatus)).length);
        console.log("IN_NOTARY: ", WidgetsData.filter(contract => ["IN_NOTARY"].includes(contract.sStatus)).length);
        console.log("PAY PENDING: ", WidgetsData.filter(contract => contract.sStatus === "APPROVED" && ["PENDING"].includes(contract.sPaymentStatus)).length);
        console.log("PAY PARTIALLY_PAID: ", WidgetsData.filter(contract => contract.sStatus === "APPROVED" && ["PARTIALLY_PAID"].includes(contract.sPaymentStatus)).length);
        console.log("FINISHED: ", WidgetsData.filter(contract => ["FINISHED"].includes(contract.sStatus)).length);
    }, [WidgetsData]);

    useEffect(() => {

        const { status, message } = contractListActive;

        if (["success"].includes(status) || allAnfTramit.length > 0) {

            if (Array.isArray(message) && Array.isArray(allAnfTramit)) {
                setWidgetsData([...allAnfTramit.map((item) => {
                    return { sStatus: item['status'], sPaymentStatus: item['paymentStatus'] }
                }), ...message]);
            }

        }

    }, [contractListActive, allAnfTramit]);

    const convertCount = (count) => {
        if (count >= 1000 && count < 1000000) {
            return (count / 1000).toFixed(1) + "K";
        } else if (count >= 1000000) {
            return (count / 1000000).toFixed(1) + "M";
        } else {
            return count;
        }
    }

    return (
        <div className="slider-container cards__container">

            {
                isMobile ?
                    (
                        <Slider className='custom-class-slider' {...settings}>

                            {fields && fields.map((field, index) => {
                                return (
                                    <CounterByStatus
                                        key={index}
                                        bgColor={field.color}
                                        count={convertCount(field.counter)}
                                        procedure={field.title}
                                        avatar={field.avatar}
                                        imageAvatar={field.img}
                                        value={field.value}
                                    />
                                )
                            })}

                        </Slider>
                    ) :
                    fields && fields.map((field, index) => {
                        return (
                            <CounterByStatus
                                key={index}
                                bgColor={field.color}
                                count={convertCount(field.counter)}
                                procedure={field.title}
                                avatar={field.avatar}
                                imageAvatar={field.img}
                                value={field.value}
                            />
                        )
                    })
            }

        </div>
    );
}

export default ContractListHeader;
