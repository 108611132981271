import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRegGrinWink, FaRegSadCry } from "react-icons/fa";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import Btn from "../../../../buttons/Button";
import { ModalAddProviderStyles } from "./ModalAddProvider.css";
import Spinner from "../../../../spinners/Spinner";
import { styles as stylesHelpers } from "../../../../../helpers";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import { validatePhoneNumber, validation } from "../../../../../utils/validations";
import { createProvider } from "../../../../../utils/api-providers";
import { addProviders } from "../../../../../store/providers/providers";



const ModalAddProvider = () => {
    const {
        providers: {
            providers
        },
        session: {
            login: { user },
        },
    } = useSelector((state) => state.root);
    const dispatch = useDispatch();
    const screenSizeHook = ScreenSizeHook();
    const styles = ModalAddProviderStyles();

    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            rut: '',
            notaryName: '',
            ownerName: '',
            phone: '',
            email: ''
        }
    });

    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [description, setDescription] = useState('');
    const [texButtonGoback, setTexButtonGoback] = useState('');
    const [restStatus, setRestStatus] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setModalTitle('Registrar Proveedor');
        setDescription('Formulario de registro de proveedores.');
        setTexButtonGoback('Volver');
        setRestStatus(undefined);
        setLoading(false);
        setOpen(true);
    };

    const handleClose = () => {
        if (loading) return;
        reset();
        setOpen(false);
    };

    const onSubmit = async (data) => {

        const { token } = user;
        try {
            setLoading(true);

            const arrayList = [...providers];

            const restAddProdv = await createProvider(data, token);

            if (restAddProdv['status'] === 'success') {

                arrayList.push(restAddProdv['message']);
                setTexButtonGoback('Cerrar');
                setModalTitle('Registro Exitoso');
                setDescription('Sus datos fueron guardados correctamente');
                dispatch(addProviders(arrayList));
                reset();
                setRestStatus(true);
                setOpen(false);

            } else {

                setTexButtonGoback('Cerrar');
                setModalTitle('Registro Fallido');
                setDescription(restAddProdv['message']['msg']);
                setRestStatus(false);
                setOpen(false);
            }

            setLoading(false);

        } catch (error) {
            setTexButtonGoback('Cerrar');
            setModalTitle('Registro Fallido');
            setDescription('Hubo un problema al guardar sus datos, intentelo nuevamente o comunícate con un administrador del sistema');
            setRestStatus(false);
            setLoading(false);
        }
    };

    return (
        <React.Fragment>

            <Btn
                variant="contained"
                text="Registrar Proveedor"
                sx={stylesHelpers.buttonGoToCreatorMenu}
                onClick={() => handleClickOpen()}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={['xs'].includes(screenSizeHook) ? 'xs' : 'xl'}
            >

                {(restStatus === undefined && <Stack className={styles.buttomsTopContent}>

                    <IconButton aria-label="delete" size="small" onClick={handleClose}>
                        <AiOutlineCloseCircle fontSize="inherit" size={20} />
                    </IconButton>

                </Stack>)}


                {restStatus === true && (<Stack className={styles.buttomsTopSuccessContent}>

                    <IconButton aria-label="delete" size="small" onClick={handleClose}>
                        <FaRegGrinWink fontSize="inherit" size={150} className="icon--red" />
                    </IconButton>

                </Stack>)}

                {restStatus === false && (<Stack className={styles.buttomsTopSuccessContent}>

                    <IconButton aria-label="delete" size="small" onClick={handleClose}>
                        <FaRegSadCry fontSize="inherit" size={150} className="icon--red" />
                    </IconButton>

                </Stack>)}

                <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">

                    <DialogTitle className={styles.constentDialogTitle}>
                        {modalTitle}
                    </DialogTitle>

                    <DialogContent>

                        {loading && <Spinner loading={true} type="bar" opacity={true} />}

                        <DialogContentText align="center">
                            {description}
                        </DialogContentText>

                        <br />

                        {restStatus === undefined && (<Grid container spacing={1} className={styles.gridContainer}>

                            <Grid item xs={12} sm={6}>

                                <Grid item xs={12}>
                                    <Typography variant={''}>RUT</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Controller
                                        name="rut"
                                        control={control}
                                        rules={{
                                            required: 'El RUT es requerido',
                                            pattern: {
                                                value: validation.rut,
                                                message: 'RUT invalido'
                                            }
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (
                                                <TextField
                                                    error={fieldState.error ? true : false}
                                                    className={styles.inputs}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={fieldState.error ? fieldState.error.message : null}
                                                    placeholder={'RUT'}
                                                    color={fieldState.error ? 'error' : 'success'}
                                                    focused={fieldState.isTouched || fieldState.isDirty}
                                                    fullWidth
                                                />
                                            );
                                        }}
                                    />
                                </Grid>

                            </Grid>

                            <Grid item xs={12} sm={6}>

                                <Grid item xs={12}>
                                    <Typography variant={''}>Nombre de la notaría</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Controller
                                        name="notaryName"
                                        control={control}
                                        rules={{
                                            required: 'El nombre de la notaría es requerido'
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (
                                                <TextField
                                                    error={fieldState.error ? true : false}
                                                    className={styles.inputs}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={fieldState.error ? fieldState.error.message : null}
                                                    placeholder={'Nombre Notaria'}
                                                    color={fieldState.error ? 'error' : 'success'}
                                                    focused={fieldState.isTouched || fieldState.isDirty}
                                                    fullWidth
                                                />
                                            );
                                        }}
                                    />
                                </Grid>

                            </Grid>

                            <Grid item xs={12} sm={6}>

                                <Grid item xs={12}>
                                    <Typography variant={''}>Responsable</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Controller
                                        name="ownerName"
                                        control={control}
                                        rules={{
                                            required: 'El Responsable es requerido'
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (
                                                <TextField
                                                    error={fieldState.error ? true : false}
                                                    className={styles.inputs}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={fieldState.error ? fieldState.error.message : null}
                                                    placeholder={'Responsable'}
                                                    color={fieldState.error ? 'error' : 'success'}
                                                    focused={fieldState.isTouched || fieldState.isDirty}
                                                    fullWidth
                                                />
                                            );
                                        }}
                                    />
                                </Grid>

                            </Grid>

                            <Grid item xs={12} sm={6}>

                                <Grid item xs={12}>
                                    <Typography variant={''}>Teléfono</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Controller
                                        name="phone"
                                        control={control}
                                        rules={{
                                            required: 'El Teléfono es requerido',
                                            validate:{
                                                validPhoneNumber: (value) => validatePhoneNumber(value) || 'Formato de numero telefonico invalido'
                                            }
                                            // pattern: {
                                            //     value: validation.phone,
                                            //     message: 'Formato de Teléfono invalido'
                                            // }
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (
                                                <TextField
                                                    error={fieldState.error ? true : false}
                                                    className={styles.inputs}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={fieldState.error ? fieldState.error.message : null}
                                                    placeholder={'Teléfono'}
                                                    color={fieldState.error ? 'error' : 'success'}
                                                    focused={fieldState.isTouched || fieldState.isDirty}
                                                    fullWidth
                                                />
                                            );
                                        }}
                                    />
                                </Grid>

                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant={''}>Correo</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: 'El Correo es requerido',
                                        pattern: {
                                            value: validation.email,
                                            message: 'Formato de Correo invalido'
                                        }
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                        return (
                                            <TextField
                                                error={fieldState.error ? true : false}
                                                className={styles.inputs}
                                                ref={ref}
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                value={value}
                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                placeholder={'Correo'}
                                                color={fieldState.error ? 'error' : 'success'}
                                                focused={fieldState.isTouched || fieldState.isDirty}
                                                fullWidth
                                            />
                                        );
                                    }}
                                />
                            </Grid>

                        </Grid>)}

                    </DialogContent>

                    <DialogActions style={{ justifyContent: 'center' }}>

                        {restStatus === undefined && (<Stack className={styles.constentDialogActionSend}>
                            <Button onClick={handleClose} variant="contained" className={styles.cancelButton} >Cancelar</Button>
                        </Stack>)}

                        {restStatus === undefined && (<Stack className={styles.constentDialogActionSend}>
                            <Button variant="contained" type="submit" className={styles.goBackButton} >Registrar</Button>
                        </Stack>)}

                        {restStatus !== undefined && (<Stack className={styles.constentDialogActionGoBack}>
                            <Button variant="contained" onClick={() => { handleClose() }} className={styles.goBackButton}>{texButtonGoback}</Button>
                        </Stack>)}

                    </DialogActions>

                </form>

            </Dialog>

        </React.Fragment>
    );
}

export default ModalAddProvider;