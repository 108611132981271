import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: 'frequentParticipants',
    initialState: {
        list: []
    },
    reducers: {
        FREQUENT: (state, action) => {

            if (state.list.length === 0) {
                state.list = action.payload;
            } else {

                state.list = [
                    ...state.list,
                    ...action.payload
                ].reduce((accumulator, item) => {
                    const existingItemIndex = accumulator.findIndex((stateItem) => stateItem.email === item.email);

                    if (existingItemIndex !== -1) {
                        accumulator[existingItemIndex] = { ...accumulator[existingItemIndex], ...item };
                    } else {
                        accumulator.push(item);
                    }

                    return accumulator;
                }, []);
            }

        }
    }
});

export const {
    FREQUENT,
} = slice.actions;

export const saveParticipants = (participant) => {
    return { type: FREQUENT.type, payload: participant }
};

export default slice.reducer;