import { forwardRef, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FaRedo } from "react-icons/fa";
import { BsEnvelope } from "react-icons/bs";
import NumberFormat from 'react-number-format';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import dayjs from 'dayjs';

import { BreadCrumbTracker, Btn, Notificationbanner, Spinner } from "../..";
import { ReactComponent as UserIcon } from "./img/user-icon.svg";
import { ReactComponent as WebPayLogo } from "./img/web-pay-logo.svg";
import { ContractDetailReviewStyles } from "./index.css";
import { api, verifiVariable } from "../../../utils/enums";
import DetailSignatories from "./molecules/detailSignatories";
import { Context } from "../../../context/utilsContext";
import DeleteContractAlert from "./molecules/deleteContractAlert";
import { sendContractPriotity, sendEmailSign } from "../../../utils/api";
import { getOneUser, scheduleContractDetail, scheduleDetail } from "./services";
import InfoDocs from "./molecules/infoDocs";
import Reschedule from "./molecules/reSchedule";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ResendContractCustomButtom = styled(Button)((props) => ({
    borderRadius: '7px',
    fontStyle: 'normal',
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: 'none',
    fontSize: '0.85rem',
    padding: '4px 20px',
    border: `1px solid ${props.theme.palette.primary.light}`,
    '&:hover': {
        border: `1px solid ${props.theme.palette.primary.light}`
    },
    fontFamily: [
        '"PoppinsBold"'
    ].join(','),
}));

const DeleteAndUpdateCustomButtom = styled(Button)((props) => ({
    border: `1.5px solid ${props.theme.palette.primary.light}`
}));

const CustomTitle = styled(Typography)((props) => ({
    color: props.theme.palette.black.main,
    fontSize: '1rem',
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));

const CustomSubTitle = styled(Typography)((props) => ({
    lineHeight: '0.75',
    color: props.theme.palette.gray.dark,
    fontSize: '0.75rem',
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));

const CustomPayInfo = styled(Typography)((props) => ({
    lineHeight: '3',
    color: props.theme.palette.black.main,
    fontSize: '0.75rem',
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));


const ScheduleDetailReview = () => {
    const styles = ContractDetailReviewStyles();
    const { loading, setLoading, setTimer, timer } = useContext(Context);
    const {
        session: {
            login: {
                user
            }
        }
    } = useSelector((state) => state.root);
    const { state, pathname } = useLocation();
    const [open, setOpen] = useState(false);
    const [detailContract, setDetailContract] = useState(undefined);
    const [payInfo, setPayInfo] = useState(undefined);
    const [textLoading, setTextLoading] = useState('');
    const [showReminderMessage, setShowReminderMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");


    const openTab = (url) => window.open(url, "_blank");

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const getTramit = async () => {
        try {

            let total = 0;
            let iva = 0;
            let notary = 0;
            let signature = 0;

            const { token } = user;

            const scheduleReq = await scheduleDetail(state, token);

            if (scheduleReq['status'] === 'Success') {

                const tramitReq = await scheduleContractDetail(scheduleReq['data']['sContractID'], token);

                if (tramitReq['status'] === 'Success') {

                    const userReq = await getOneUser(tramitReq['data']['message']['contrato']['iContractOwner'], token);

                    if (userReq['status'] === 'success') {

                        const data = {
                            contrato: tramitReq['data']['message']['contrato'],
                            owner: userReq['message'],
                            firmantes: tramitReq['data']['message']['firmantes'],
                            archivos: tramitReq['data']['message']['archivos'],
                            agendamiento: scheduleReq['data'],
                            comentarios: []
                        }

                        setDetailContract(data);

                        tramitReq['data']['message']['firmantes'].forEach((firmante) => {
                            if (firmante.portion !== '0.00') {
                                total += parseInt(firmante.totalPay);
                                iva += parseInt(firmante.iva);
                                notary += parseInt(firmante.notaryPay);
                                signature += parseInt(firmante.amount);
                            }
                        });

                        setPayInfo({
                            total,
                            iva,
                            notary,
                            signature,
                        });

                    } else {
                        console.log('error userReq: ', userReq);
                    }

                } else {
                    console.log('error tramitReq: ', tramitReq);
                }

            } else {
                console.log('error scheduleReq: ', scheduleReq);
            }

        } catch (error) {
            console.log('error: ', error);
        }
    }

    const sendReminder = async () => {

        try {

            setLoading(true);

            const { token } = user;

            await sendContractPriotity(state, token);

            await sendEmailSign(state, token);

            setLoading(false);
            setShowReminderMessage(true);
            setAlertType("success");
            setAlertMessage(
                "Se ha enviado un recordatorio a todos los firmantes"
            );

            setTimeout(() => {
                setShowReminderMessage(false);
                setAlertType("");
                setAlertMessage("");
            }, 4000);

            setTimer();

        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {

        if (user) getTramit();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);


    return (
        <div className="content-detail__container">

            <div className="contract-details__main">

                <div className="contract-details__container">

                    <section className="contract-detail__info-container">

                        <Notificationbanner
                            display={showReminderMessage}
                            notificationType={alertType}
                        >
                            <strong>{alertMessage}</strong>
                        </Notificationbanner>

                        {loading && (
                            <Spinner
                                loading={true}
                                type="bar"
                                text={textLoading}
                                opacity={true}
                            />
                        )}

                        {detailContract && (<BreadCrumbTracker
                            location={pathname}
                            status={detailContract['contrato']['sStatus']}
                            showGoback={true}
                        />)}

                        <Grid container>



                            <Grid item xs={12} sm={6}>

                                <div className={styles.contentTitle}>

                                    <div className={styles.titleNumber}>1</div>

                                    <div>

                                        <CustomTitle variant="h6" gutterBottom>
                                            Datos personales  del solicitante
                                        </CustomTitle>

                                        <CustomSubTitle variant="subtitle1" gutterBottom>
                                            Mis datos personales
                                        </CustomSubTitle>

                                    </div>

                                </div>

                            </Grid>

                            <Grid item xs={12} sm={6}>

                                <div className={styles.optionsContent}>

                                    {
                                        !verifiVariable(detailContract) &&
                                        ['REVIEW', 'CREATED', 'REJECTED'].includes(detailContract['contrato']['sStatus']) &&
                                        (
                                            <DeleteContractAlert />
                                        )
                                    }

                                    <DeleteAndUpdateCustomButtom variant="outlined" startIcon={<FaRedo className="icon--blue" />} onClick={() => getTramit()}>
                                        Actualizar seguimiento
                                    </DeleteAndUpdateCustomButtom>
                                </div>

                            </Grid>



                            <Grid item xs={12}>

                                <div className={styles.userInfoContent}>

                                    <div className={styles.userIcon}>
                                        <UserIcon />
                                    </div>

                                    <div className={styles.userTitleInfo}>

                                        <Typography variant="subtitle1" gutterBottom>
                                            Nombre
                                        </Typography>

                                        <Typography variant="subtitle1" gutterBottom>
                                            Correo electrónico
                                        </Typography>

                                        <Typography variant="subtitle1" gutterBottom>
                                            Teléfono
                                        </Typography>

                                    </div>

                                    {!verifiVariable(detailContract) && (<div className={styles.userInfo}>

                                        <Typography variant="subtitle1" gutterBottom>
                                            {detailContract['owner']['sFirstName']} {detailContract['owner']['sLastName']}
                                        </Typography>

                                        <Typography variant="subtitle1" gutterBottom>
                                            {detailContract['owner']['sEmail']}
                                        </Typography>

                                        <Typography variant="subtitle1" gutterBottom>
                                            {detailContract['owner']['sPhone']}
                                        </Typography>

                                    </div>)}

                                </div>

                            </Grid>

                            <Grid item xs={12}>

                                <div className={styles.contentTitle} style={{ marginTop: '100px' }}>

                                    <div className={styles.titleNumber}>2</div>

                                    <div>

                                        <CustomTitle variant="h6" gutterBottom>
                                            Datos del trámite
                                        </CustomTitle>

                                        <CustomSubTitle variant="subtitle1" gutterBottom>
                                            Datos del trámite a realizar
                                        </CustomSubTitle>

                                    </div>

                                </div>

                            </Grid>

                            <Grid item xs={4}>

                                <div className={styles.userInfoContent}>

                                    <div className={`${styles.infoTramitContent}`}>

                                        <div className={`${styles.titleInfoTramite} ${styles.userTitleInfo}`}>

                                            <Typography variant="subtitle1" gutterBottom>
                                                ID de trámite
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                Tipo de trámite
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                Trámite a gestionar
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                Participantes
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                Tipo de firma
                                            </Typography>

                                        </div>

                                    </div>

                                    {!verifiVariable(detailContract) && (<div className={styles.userInfo}>

                                        <Typography variant="subtitle1" gutterBottom>
                                            {detailContract['contrato']['autoId']}
                                        </Typography>

                                        <Typography variant="subtitle1" gutterBottom className="text--lowercase first-letter--uppercase">
                                            {detailContract['contrato']['sTipoContrato']}
                                        </Typography>

                                        <Typography variant="subtitle1" gutterBottom className="text--lowercase first-letter--uppercase">
                                            {detailContract['contrato']['sClaseContrato']}
                                        </Typography>

                                        <Typography variant="subtitle1" gutterBottom>
                                            {detailContract['contrato']['iSignedCount']}
                                        </Typography>

                                        <Typography variant="subtitle1" gutterBottom className="text--lowercase first-letter--uppercase">
                                            {detailContract['contrato']['sTipoFirma']}
                                        </Typography>

                                    </div>)}

                                </div>

                            </Grid>


                            {!verifiVariable(detailContract) && <Grid item xs={8}>

                                <InfoDocs contractCreatedDetails={detailContract} loading={(e) => setLoading(e)} textLoading={(e) => setTextLoading(e)} restUpDoc={() => getTramit()} />

                            </Grid>}


                            <Grid item xs={12}>

                                <div className={styles.contentTitle} style={{ marginTop: '80px' }}>

                                    <div className={styles.titleNumber}>3</div>

                                    <div>

                                        <CustomTitle variant="h6" gutterBottom>
                                            Datos del agendamiento
                                        </CustomTitle>

                                        <CustomSubTitle variant="subtitle1" gutterBottom>
                                            Fecha pautada para realizar el trámite
                                        </CustomSubTitle>

                                    </div>

                                    <div>

                                        <Reschedule contractCreatedDetails={detailContract} getTramit={() => getTramit()} />

                                    </div>

                                </div>

                            </Grid>

                            <Grid item xs={12}>

                                <div className={styles.userInfoContent}>

                                    <div className={`${styles.infoTramitContent}`}>

                                        <div className={`${styles.titleInfoTramite} ${styles.userTitleInfo}`}>

                                            <Typography variant="subtitle1" gutterBottom>
                                                Fecha
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                Hora
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                Comuna
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                Jurisdicción
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                Dirección
                                            </Typography>

                                        </div>

                                    </div>

                                    {!verifiVariable(detailContract) && (<div className={styles.userInfo}>

                                        <Typography variant="subtitle1" gutterBottom>
                                            {`${dayjs(detailContract['agendamiento']['date']).format('DD')} de ${dayjs(detailContract['agendamiento']['date']).format('MMMM')} de ${dayjs(detailContract['agendamiento']['date']).format('YYYY')}`}
                                        </Typography>

                                        <Typography variant="subtitle1" gutterBottom className="text--lowercase first-letter--uppercase">
                                            {dayjs(`${detailContract['agendamiento']['date']}T${detailContract['agendamiento']['start_hour']}`).format('hh:mm A')}
                                        </Typography>

                                        <Typography variant="subtitle1" gutterBottom className="text--lowercase first-letter--uppercase">
                                            {detailContract['agendamiento']['commune_name']}
                                        </Typography>

                                        <Typography variant="subtitle1" gutterBottom>
                                            {detailContract['agendamiento']['juridiction_name']}
                                        </Typography>

                                        <Typography variant="subtitle1" gutterBottom className="text--lowercase first-letter--uppercase">
                                            {detailContract['agendamiento']['directions'][0]['direction']}
                                        </Typography>

                                    </div>)}

                                </div>

                            </Grid>


                            <Grid item xs={6}>

                                <div className={styles.contentTitle} style={{ marginTop: '80px' }}>

                                    <div className={styles.titleNumber}>4</div>

                                    <div>

                                        <CustomTitle variant="h6" gutterBottom>
                                            Datos de los participantes
                                        </CustomTitle>

                                        <CustomSubTitle variant="subtitle1" gutterBottom>
                                            Datos de las personas que firman los documentos
                                        </CustomSubTitle>

                                    </div>

                                </div>

                            </Grid>

                            {
                                !verifiVariable(detailContract) &&
                                ["SIGNED_PENDING", 'REJECTED'].includes(detailContract['contrato']['sStatus']) &&
                                detailContract['contrato']['withDocument'] === 1 &&
                                (<Grid item xs={6} sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end'
                                }}>

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'end'
                                    }}>

                                        <ResendContractCustomButtom
                                            onClick={handleClickOpen}
                                            variant="outlined"
                                            color="primary"
                                            disabled={timer !== '00:00:00' ? true : false}
                                            startIcon={
                                                <BsEnvelope className='icon--blue icon--xs' />
                                            }
                                        >
                                            Reenviar contrato {timer !== '00:00:00' && timer}
                                        </ResendContractCustomButtom>

                                    </div>


                                    <Dialog
                                        fullWidth={true}
                                        TransitionComponent={Transition}
                                        open={open}
                                        onClose={handleClose}
                                        maxWidth='xs'
                                        className={styles.mainContainerDialog}
                                    >

                                        <DialogTitle sx={{ textAlign: 'center' }}>
                                            ¿Estás seguro que deseas realizar esta acción?
                                        </DialogTitle>

                                        <DialogContent>

                                            <DialogContentText>
                                                Si reenvías el contrato a los participantes, todos los firmantes deben realizar el proceso de revisión del documento nuevamente.
                                            </DialogContentText>

                                        </DialogContent>

                                        <DialogActions style={{ justifyContent: 'center', paddingBottom: '20px' }}>

                                            <Btn
                                                variant="outlined"
                                                text="Cancelar"
                                                color="error"
                                                onClick={() => handleClose()}
                                                sx={{
                                                    height: '39px',
                                                    borderRadius: '20px'
                                                }}
                                            />

                                            <Btn
                                                variant="outlined"
                                                text="Aceptar"
                                                color="primary"
                                                onClick={() => { sendReminder(); handleClose() }}
                                                sx={{
                                                    height: '39px',
                                                    borderRadius: '20px',
                                                    marginLeft: '30px !important'
                                                }}
                                            />

                                        </DialogActions>

                                    </Dialog>

                                </Grid>)}

                            <Grid item xs={12}>

                                {!verifiVariable(detailContract) && (<div className={`info-body padding-y--xxs flex--evenly ${styles.contentParticipant}`}>

                                    {detailContract['firmantes'].map((member, index) => {
                                        return (
                                            <DetailSignatories
                                                textLoading={(e) => setTextLoading(e)}
                                                loading={(e) => setLoading(e)}
                                                updateContract={() => getTramit()}
                                                member={member}
                                                index={index}
                                                comentarios={detailContract['comentarios']}
                                                contractDetail={detailContract['contrato']}
                                                contractMembers={detailContract['firmantes']}
                                                contractFiles={detailContract['archivos']}
                                                removeSignner={false}
                                                deleteSigner={() => console.log('displayDeleteSignnerWarning')}
                                            />
                                        );
                                    })}

                                </div>)}

                            </Grid>




                            <Grid item xs={12}>

                                <div className={styles.contentTitle} style={{ marginTop: '100px' }}>

                                    <div className={styles.titleNumber}>5</div>

                                    <div>

                                        <CustomTitle variant="h6" gutterBottom>
                                            Resúmern del pedido
                                        </CustomTitle>

                                        <CustomSubTitle variant="subtitle1" gutterBottom>
                                            Total a pagar
                                        </CustomSubTitle>

                                    </div>

                                </div>

                            </Grid>

                            {!verifiVariable(detailContract) && <Grid container spacing={4} className={styles.fourtSectionContent}>

                                <Grid item xs={8}>

                                    <div className={`${styles.contentTitle} ${styles.payTitleInfo}`}>

                                        <div>

                                            <CustomTitle variant="h6" gutterBottom>
                                                Resúmern del pedido
                                            </CustomTitle>

                                        </div>

                                    </div>

                                    <div className={styles.payInfoMainContent}>

                                        <div className={styles.payInfoContent}>

                                            <div>

                                                <CustomPayInfo variant="subtitle1" gutterBottom>
                                                    Servicio de notaría
                                                </CustomPayInfo>

                                                <CustomPayInfo variant="subtitle1" gutterBottom>
                                                    Servicio de firma electrónica
                                                </CustomPayInfo>

                                                <CustomPayInfo variant="subtitle1" gutterBottom>
                                                    IVA
                                                </CustomPayInfo>

                                            </div>

                                            <div>

                                                <CustomPayInfo variant="subtitle1" gutterBottom>
                                                    <NumberFormat
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        prefix={"$"}
                                                        value={!verifiVariable(payInfo) ? payInfo['notary'] : 0}
                                                        displayType={"text"}
                                                        renderText={(value, props) => value}
                                                    />
                                                </CustomPayInfo>

                                                <CustomPayInfo variant="subtitle1" gutterBottom>
                                                    <NumberFormat
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        prefix={"$"}
                                                        value={!verifiVariable(payInfo) ? payInfo['signature'] : 0}
                                                        displayType={"text"}
                                                        renderText={(value, props) => value}
                                                    />
                                                </CustomPayInfo>

                                                <CustomPayInfo variant="subtitle1" gutterBottom>
                                                    <NumberFormat
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        prefix={"$"}
                                                        value={!verifiVariable(payInfo) ? payInfo['iva'] : 0}
                                                        displayType={"text"}
                                                        renderText={(value, props) => value}
                                                    />
                                                </CustomPayInfo>

                                            </div>

                                        </div>

                                        <div className={styles.payTotalInfo}>

                                            <CustomPayInfo variant="subtitle1" gutterBottom>
                                                Total
                                            </CustomPayInfo>

                                            <CustomPayInfo variant="subtitle1" gutterBottom>
                                                <NumberFormat
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    prefix={"$"}
                                                    value={!verifiVariable(payInfo) ? payInfo['total'] : 0}
                                                    displayType={"text"}
                                                    renderText={(value, props) => value}
                                                />
                                            </CustomPayInfo>

                                        </div>

                                    </div>

                                </Grid>

                                <Grid item xs={4}>

                                    <div className={`${styles.contentTitle} ${styles.payTitleInfo}`}>

                                        <div>
                                            <CustomTitle variant="h6" gutterBottom>
                                                Métodos de pago
                                            </CustomTitle>
                                        </div>

                                    </div>

                                    <div className={`${styles.payInfoMainContent} ${styles.payContent}`}>
                                        {!verifiVariable(detailContract) && <WebPayLogo onClick={() => openTab(`${api.BASE_SCHEDULE_URI}${api.WEB_PAY}/${detailContract['contrato']['sContractID']}`)} />}
                                    </div>

                                </Grid>

                            </Grid>}

                        </Grid>

                    </section>

                </div>

            </div>

        </div >

    );
}

export default ScheduleDetailReview;