import React, { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRegGrinWink, FaRegSadCry } from "react-icons/fa";
import { BsPencilSquare } from "react-icons/bs";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import { ModalEditUserStyles } from "./ModalEditUser.css";
import Spinner from "../../../../spinners/Spinner";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import { validation } from "../../../../../utils/validations";
import { useDispatch, useSelector } from "react-redux";
import { editUser } from "../../../../../utils/api-user";
import { addUsers } from "../../../../../store/users/userList";

const ModalEditUser = ({ userData }) => {
    const {
        session: {
            login: { user },
        },
        users: { listUsers },
    } = useSelector((state) => state.root);
    const dispatch = useDispatch();
    const screenSizeHook = ScreenSizeHook();
    const styles = ModalEditUserStyles();

    const { control, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            sFirstName: "",
            sLastName: "",
            sEmail: "",
            sPhone: "",
        },
    });

    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [description, setDescription] = useState("");
    const [texButtonGoback, setTexButtonGoback] = useState("");
    const [restStatus, setRestStatus] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setModalTitle(
            `Editar Usuario ${userData["sFirstName"]} ${userData["sLastName"]}`
        );
        setDescription("");
        setValue("sFirstName", userData["sFirstName"]);
        setValue("sLastName", userData["sLastName"]);
        setValue("sEmail", userData["sEmail"]);
        setValue("sPhone", userData["sPhone"]);
        setTexButtonGoback("Cerrar");
        setRestStatus(undefined);
        setLoading(false);
        setOpen(true);
    };

    const handleClose = () => {
        if (loading) return;
        reset();
        setOpen(false);
    };

    const onSubmit = async (data) => {
        const { token } = user;

        try {
            setLoading(true);

            const restEditUser = await editUser(
                Object.assign(data, { rut: userData["sRUT"] }),
                userData["iUserID"],
                token
            );

            if (restEditUser["status"] === "success") {
                setTexButtonGoback("Cerrar");
                setModalTitle("Solicitud Exitosa");
                setDescription("Sus datos fueron editados correctamente");
                setRestStatus(true);
                dispatch(addUsers([...listUsers, restEditUser['message']]));
            } else {
                setTexButtonGoback("Cerrar");
                setModalTitle("Edición Fallida");
                setDescription(restEditUser["message"]["msg"]);
                setRestStatus(false);
            }

            setLoading(false);
        } catch (error) {
            setTexButtonGoback("Cerrar");
            setModalTitle("Edición Fallida");
            setDescription(
                "Hubo un problema al editar sus datos, intentelo nuevamente o comunícate con un administrador del sistema."
            );
            setRestStatus(false);
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <BsPencilSquare
                className="icon--xs icon-pointer icon--blue-light"
                onClick={() => handleClickOpen()}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={["xs"].includes(screenSizeHook) ? "xs" : "xl"}
            >
                {restStatus === undefined && (
                    <Stack className={styles.buttomsTopContent}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={handleClose}
                        >
                            <AiOutlineCloseCircle
                                fontSize="inherit"
                                size={20}
                            />
                        </IconButton>
                    </Stack>
                )}

                {restStatus === true && (
                    <Stack className={styles.buttomsTopSuccessContent}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={handleClose}
                        >
                            <FaRegGrinWink
                                fontSize="inherit"
                                size={150}
                                className="icon--red"
                            />
                        </IconButton>
                    </Stack>
                )}

                {restStatus === false && (
                    <Stack className={styles.buttomsTopSuccessContent}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={handleClose}
                        >
                            <FaRegSadCry
                                fontSize="inherit"
                                size={150}
                                className="icon--red"
                            />
                        </IconButton>
                    </Stack>
                )}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    encType="multipart/form-data"
                >
                    <DialogTitle className={styles.constentDialogTitle}>
                        {modalTitle}
                    </DialogTitle>

                    <DialogContent>
                        {loading && (
                            <Spinner loading={true} type="bar" opacity={true} />
                        )}

                        <DialogContentText align="center">
                            {description}
                        </DialogContentText>

                        <br />

                        {restStatus === undefined && (
                            <Grid
                                container
                                spacing={1}
                                className={styles.gridContainer}
                            >
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant={""}>
                                            Nombres
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            name="sFirstName"
                                            control={control}
                                            rules={{
                                                required:
                                                    "Los nombres son requerido",
                                            }}
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    ref,
                                                },
                                                fieldState,
                                            }) => {
                                                return (
                                                    <TextField
                                                        error={
                                                            fieldState.error
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            styles.inputs
                                                        }
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        helperText={
                                                            fieldState.error
                                                                ? fieldState
                                                                      .error
                                                                      .message
                                                                : null
                                                        }
                                                        placeholder={
                                                            "Ingrese Nombres"
                                                        }
                                                        color={
                                                            fieldState.error
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        focused={
                                                            fieldState.isTouched ||
                                                            fieldState.isDirty
                                                        }
                                                        fullWidth
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant={""}>
                                            Apellidos
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            name="sLastName"
                                            control={control}
                                            rules={{
                                                required:
                                                    "Los apellidos son requerido",
                                            }}
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    ref,
                                                },
                                                fieldState,
                                            }) => {
                                                return (
                                                    <TextField
                                                        error={
                                                            fieldState.error
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            styles.inputs
                                                        }
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        helperText={
                                                            fieldState.error
                                                                ? fieldState
                                                                      .error
                                                                      .message
                                                                : null
                                                        }
                                                        placeholder={
                                                            "Ingrese Apellidos"
                                                        }
                                                        color={
                                                            fieldState.error
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        focused={
                                                            fieldState.isTouched ||
                                                            fieldState.isDirty
                                                        }
                                                        fullWidth
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant={""}>
                                            Correo electrónico
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            name="sEmail"
                                            control={control}
                                            rules={{
                                                required:
                                                    "El Correo es requerido",
                                            }}
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    ref,
                                                },
                                                fieldState,
                                            }) => {
                                                return (
                                                    <TextField
                                                        error={
                                                            fieldState.error
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            styles.inputs
                                                        }
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        helperText={
                                                            fieldState.error
                                                                ? fieldState
                                                                      .error
                                                                      .message
                                                                : null
                                                        }
                                                        placeholder={
                                                            "Ingrese correo electrónico"
                                                        }
                                                        color={
                                                            fieldState.error
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        focused={
                                                            fieldState.isTouched ||
                                                            fieldState.isDirty
                                                        }
                                                        fullWidth
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant={""}>
                                            Número de celular
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            name="sPhone"
                                            control={control}
                                            rules={{
                                                required:
                                                    "El Número celular es requerido",
                                                pattern: {
                                                    value: validation.phone,
                                                    message:
                                                        "Formato de Número celular invalido",
                                                },
                                            }}
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    ref,
                                                },
                                                fieldState,
                                            }) => {
                                                return (
                                                    <TextField
                                                        error={
                                                            fieldState.error
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            styles.inputs
                                                        }
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        helperText={
                                                            fieldState.error
                                                                ? fieldState
                                                                      .error
                                                                      .message
                                                                : null
                                                        }
                                                        placeholder={
                                                            "Ingrese número celular"
                                                        }
                                                        color={
                                                            fieldState.error
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        focused={
                                                            fieldState.isTouched ||
                                                            fieldState.isDirty
                                                        }
                                                        fullWidth
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>

                    <DialogActions style={{ justifyContent: "center" }}>
                        {restStatus === undefined && (
                            <Stack className={styles.constentDialogActionSend}>
                                <Button
                                    onClick={handleClose}
                                    variant="contained"
                                    className={styles.cancelButton}
                                >
                                    Cancelar
                                </Button>
                            </Stack>
                        )}

                        {restStatus === undefined && (
                            <Stack className={styles.constentDialogActionSend}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    className={styles.goBackButton}
                                >
                                    Editar
                                </Button>
                            </Stack>
                        )}

                        {restStatus !== undefined && (
                            <Stack
                                className={styles.constentDialogActionGoBack}
                            >
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        handleClose();
                                    }}
                                    className={styles.goBackButton}
                                >
                                    {texButtonGoback}
                                </Button>
                            </Stack>
                        )}
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    );
};

export default ModalEditUser;
