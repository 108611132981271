import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import Spinner from "../spinners/Spinner";
import { ContractListHeader } from "..";
import { LineChartWorkStadistics } from "./LineChartWorkStadistics";
import { BarChartStadistics } from "../common/barChart/BarChartStadistics";
import { getContractsCreated } from "../../store/contract/contractDetail";


const ContentCards = () => {

    const {
        session: {
            login: {
                user
            }
        },
        contract: {
            contractCreator: {
                loading
            }
        }
    } = useSelector(state => state.root);
    const dispatch = useDispatch();

    const fields = [
        {
            color: "secondary",
            title: "Total trámites",
            counter: "452",
            avatar: true,
            img: 1,
        },
        {
            color: "senary",
            title: "Total entregados",
            counter: "358",
            avatar: true,
            img: 2,
        },
        {
            color: "tertiary",
            title: "Total pagados",
            counter: "542",
            avatar: true,
            img: 3,
        },
    ];

    const data = [
        {
            name: "01-10-Dic",
            doc: "2h 24m 13s",
            firmas: "3h 24m 13s",
            cant_mes: 3,
            cant_firmas: 2,
        },
        {
            name: "10-02-Dic",
            doc: "11h 24m 13s",
            firmas: "11h 24m 13s",
            cant_mes: 2,
            cant_firmas: 2,
        },
        {
            name: "10-10-Dic",
            doc: "11h 24m 13s",
            firmas: "11h 24m 13s",
            cant_mes: 3,
            cant_firmas: 4,
        },
        {
            name: "20-10-Dic",
            doc: "11h 24m 13s",
            firmas: "11h 24m 13s",
            cant_mes: 4,
            cant_firmas: 3,
        },
        {
            name: "27-10-Dic",
            doc: "11h 24m 13s",
            firmas: "11h 24m 13s",
            cant_mes: 7,
            cant_firmas: 3,
        },
    ];

    const dataBar = [
        {
            name: 2015,
            trimestre1: 4,
            trimestre2: 24,
            trimestre3: 35,
        },
        {
            name: 2016,
            trimestre1: 3,
            trimestre2: 13,
            trimestre3: 25,
        },
        {
            name: 2017,
            trimestre1: 20,
            trimestre2: 75,
            trimestre3: 25,
        },
        {
            name: 2018,
            trimestre1: 27,
            trimestre2: 39,
            trimestre3: 25,
        },
        {
            name: 2019,
            trimestre1: 18,
            trimestre2: 48,
            trimestre3: 25,
        },
        {
            name: 2020,
            trimestre1: 23,
            trimestre2: 38,
            trimestre3: 25,
        },
        {
            name: 2021,
            trimestre1: 7,
            trimestre2: 12,
            trimestre3: 10,
        },
    ];

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {

        const { userId, token } = user;

        if (![null, undefined].includes(userId)) {
            dispatch(getContractsCreated(userId, token));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <div className="detail__container">

            {loading && <Spinner loading={true} type="bar" text={'Cargando contratos, por favor espera'} opacity={true} />}

            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ContractListHeader fields={fields} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Item>
                            <LineChartWorkStadistics data={data} />
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Item>
                            <BarChartStadistics data={dataBar} />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default ContentCards;
