import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { BiRightArrowAlt } from "react-icons/bi";

import { motion } from "framer-motion";

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

import { ManagerAgreementsStyles } from "../../index.css";
import { checkRut, validation } from "../../../../../utils/validations";
import { getUsersStorage } from "../../../../../store/users/userList";
import { errorStepFormAgreement } from "../../../../../store/formAgreement";


const StepOneOrganizationData = ({ control, watch, trigger, setValue }) => {
    const {
        session: {
            login: {
                user
            }
        },
        users: {
            loading,
            listUsers
        }
    } = useSelector((state) => state.root);
    const styles = ManagerAgreementsStyles();
    const dispatch = useDispatch();
    const [rutErrorInput, setRutErrorInput] = useState(undefined);

    const watchRut = watch('rut');
    const watchDesc = watch('desc');
    const watchDomain = watch('domain');
    const watchFullName = watch('fullName');
    const watchPhone = watch('phone');
    const watchEmail = watch('email');

    useEffect(() => {

        const userFind = listUsers.find((item) => item['sRUT'] === watchRut);

        if (userFind) {
            setValue('email', userFind['sEmail']);
            setValue('fullName', `${userFind['sFirstName']} ${userFind['sLastName']}`);
            setValue('desc', userFind['sComments']);
            setValue('phone', userFind['sPhone']);
            setValue('domain', userFind['sEmail']);
            dispatch(errorStepFormAgreement(false));
        } else {
            dispatch(errorStepFormAgreement(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listUsers]);

    useEffect(() => {

        const { token } = user

        if (rutErrorInput) dispatch(getUsersStorage(token));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rutErrorInput]);

    useEffect(() => {
        trigger();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        watchRut,
        watchDesc,
        watchDomain,
        watchFullName,
        watchPhone,
        watchEmail
    ]);

    return (
        <div className={styles.stepBox}>

            <motion.div
                initial={{ y: "50vh" }}
                animate={{ y: 0 }}
                transition={{ stiffness: 150 }}
            >

                <Grid container xs={12}>

                    <Grid item xs={1} className={styles.boxIndetify}>

                        1<BiRightArrowAlt color="rgb(0, 79, 159)" />

                    </Grid>

                    <Grid item xs={11}>

                        <Typography variant="h4" className={styles.cssTexto1} gutterBottom>
                            Datos de la Organización
                        </Typography>

                        <Typography variant="h5" className={styles.cssTexto2} gutterBottom>
                            Rut
                        </Typography>

                        <Controller
                            name="rut"
                            control={control}
                            rules={{
                                required: "El RUT o Pasaporte es requerido",
                                validate: {

                                    isPassPortOrRut: (value) => {

                                        if ([checkRut(value), validation.passport.test(value)].includes(true)) {
                                            return true;
                                        } else {
                                            return 'Ingresa un RUT o Pasaporte válido';
                                        }
                                    }

                                }
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState
                            }) => {
                                setRutErrorInput([null, undefined].includes(fieldState.error));
                                return (
                                    <TextField
                                        disabled={loading}
                                        InputProps={{
                                            startAdornment: loading && (
                                                <InputAdornment position="start">
                                                    <CircularProgress />
                                                </InputAdornment>
                                            )
                                        }}
                                        error={
                                            fieldState.error
                                                ? true
                                                : false
                                        }
                                        ref={ref}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error
                                                    .message
                                                : null
                                        }
                                        placeholder={"Ingrese el rut"}
                                        color={
                                            fieldState.error
                                                ? "error"
                                                : "success"
                                        }
                                        focused={
                                            fieldState.isTouched ||
                                            fieldState.isDirty
                                        }
                                        fullWidth
                                    />
                                );
                            }}
                        />

                    </Grid>

                </Grid>

                <Grid container xs={12}>

                    <Grid item xs={1} className={styles.boxIndetify}>

                    </Grid>

                    <Grid item xs={11}>

                        <Typography variant="h5" className={styles.cssTexto2} gutterBottom>
                            Descripción
                        </Typography>

                        <Controller
                            name="desc"
                            control={control}
                            /* rules={{
                                required: "La descripción es requerida"
                            }} */
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState
                            }) => {
                                return (
                                    <TextField
                                        disabled
                                        error={
                                            fieldState.error
                                                ? true
                                                : false
                                        }
                                        ref={ref}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error
                                                    .message
                                                : null
                                        }
                                        placeholder={
                                            "Ingrese la descripción"
                                        }
                                        color={
                                            fieldState.error
                                                ? "error"
                                                : "success"
                                        }
                                        focused={
                                            fieldState.isTouched ||
                                            fieldState.isDirty
                                        }
                                        fullWidth
                                    />
                                );
                            }}
                        />

                    </Grid>

                </Grid>

                <Grid container xs={12}>

                    <Grid item xs={1} className={styles.boxIndetify}>

                    </Grid>

                    <Grid item xs={11}>

                        <Typography variant="h5" className={styles.cssTexto2} gutterBottom>
                            Email
                        </Typography>

                        <Controller
                            name="email"
                            control={control}
                            /* rules={{
                                required: "El email es requerido",
                                pattern: {
                                    value: validation.email,
                                    message: "Email invalido"
                                },
                            }} */
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState
                            }) => {
                                return (
                                    <TextField
                                        disabled
                                        error={
                                            fieldState.error
                                                ? true
                                                : false
                                        }
                                        ref={ref}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error
                                                    .message
                                                : null
                                        }
                                        placeholder={"Ingrese el email"}
                                        color={
                                            fieldState.error
                                                ? "error"
                                                : "success"
                                        }
                                        focused={
                                            fieldState.isTouched ||
                                            fieldState.isDirty
                                        }
                                        fullWidth
                                    />
                                );
                            }}
                        />

                    </Grid>

                </Grid>

                <Grid container xs={12}>

                    <Grid item xs={1} className={styles.boxIndetify}>

                    </Grid>

                    <Grid item xs={11}>

                        <Typography variant="h5" className={styles.cssTexto2} gutterBottom>
                            Nombre Completo
                        </Typography>

                        <Controller
                            name="fullName"
                            control={control}
                            /* rules={{
                                required: "El nombre es requerido",
                            }} */
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState
                            }) => {
                                return (
                                    <TextField
                                        disabled
                                        error={
                                            fieldState.error
                                                ? true
                                                : false
                                        }
                                        ref={ref}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error
                                                    .message
                                                : null
                                        }
                                        placeholder={
                                            "Ingrese el nombre completo"
                                        }
                                        color={
                                            fieldState.error
                                                ? "error"
                                                : "success"
                                        }
                                        focused={
                                            fieldState.isTouched ||
                                            fieldState.isDirty
                                        }
                                        fullWidth
                                    />
                                );
                            }}
                        />

                    </Grid>

                </Grid>

                <Grid container xs={12}>

                    <Grid item xs={1} className={styles.boxIndetify}>

                    </Grid>

                    <Grid item xs={11}>

                        <Typography variant="h5" className={styles.cssTexto2} gutterBottom>
                            Teléfono
                        </Typography>

                        <Controller
                            name="phone"
                            control={control}
                            /* rules={{
                                required: "El teléfono es requerido",
                                validate: {
                                    validPhoneNumber: (value) => validatePhoneNumber(value) || 'Formato de numero telefonico invalido'
                                }
                            }} */
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState
                            }) => {
                                return (
                                    <TextField
                                        disabled
                                        error={
                                            fieldState.error
                                                ? true
                                                : false
                                        }
                                        ref={ref}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error
                                                    .message
                                                : null
                                        }
                                        placeholder={
                                            "Ingrese el teléfono"
                                        }
                                        color={
                                            fieldState.error
                                                ? "error"
                                                : "success"
                                        }
                                        focused={
                                            fieldState.isTouched ||
                                            fieldState.isDirty
                                        }
                                        fullWidth
                                    />
                                );
                            }}
                        />

                    </Grid>

                </Grid>

            </motion.div>

        </div>
    );
}

export default StepOneOrganizationData;