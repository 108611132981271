import { useSelector } from "react-redux";

import Box from '@mui/material/Box';

import CargarPdf from "../cargarPdf";
import PreViewPdf from "../preViewPdf";

const Step4 = () => {

    const {
        firmaDocs: {
            pdfBase64
        }
    } = useSelector((state) => state.root);

    return (
        <Box style={{width: "100%"}}>

            {pdfBase64 === null && (<CargarPdf />)}

            {pdfBase64 !== null && (<PreViewPdf />)}

        </Box>
    );
}

export default Step4;