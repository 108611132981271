import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

import { ExpressProcessStyles } from "../../index.css";

import { ReactComponent as CopyIcon } from "../../../../../assets/icons/icon-copy-outlined-darkBlue.svg";
import { ReactComponent as DeteleIcon } from "../../icons/detele-icon.svg";
import { ReactComponent as EditIcon } from "../../icons/edit-icon.svg";
import { ReactComponent as Ellipse1 } from "../../icons/ellipse1.svg";
import { ReactComponent as PayIconLisIcon } from "../../../../../assets/icons/icon-payer-outlined-darkBlue.svg";
import { ReactComponent as PlusIcon } from "../../icons/plusIcon.svg";
import { ReactComponent as QuestionIcon } from "../../../../../assets/icons/icon-question-outlined-blue.svg";
import { ReactComponent as SingIconListIcon } from "../../../../../assets/icons/icon-signer-outlined-darkBlue.svg";

import AddParticipants from "../addParticipants";
import { saveTramitExpressInfo } from "../../../../../store/formExpressTramit";
import { useEffect } from "react";
import ModalAddSignInfo from "../../../../modalAddSignsInfo";
import { verifiVariable } from "../../../../../utils/enums";
//import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import { Box, Grid } from "@mui/material";

const FireNav = styled(List)({
    "& .MuiListItemIcon-root": {
        minWidth: 0,
        marginRight: 12,
    },
    "& .css-mf1cb5-MuiButtonBase-root-MuiIconButton-root": {
        width: 45,
        height: 45,
        padding: 0
    }
});

const Participants = () => {
    const {
        formExpressTramit: { participants },
    } = useSelector((state) => state.root);
    const dispatch = useDispatch();
    //const screenSizeHook = ScreenSizeHook();
    const styles = ExpressProcessStyles();
    const [addPart, setAddPart] = useState(0);
    const [textPay, setTextPay] = useState("");
    const [openModal, setOpenModal] = useState(false);

    const openDiag = (edit) => {
        dispatch(saveTramitExpressInfo({ indexe: "editSigner", value: edit }));
        setAddPart((event) => event + 1);
    };

    const removeParticipant = (item) => {
        const arrayData = [...participants];

        dispatch(
            saveTramitExpressInfo({
                indexe: "participants",
                value: arrayData.filter(
                    (signatori) => signatori["email"] !== item["email"]
                ),
            })
        );
    };

    const TextList = ({ textList, rol }) => {
        const [iconList, setIconList] = useState(undefined);

        useEffect(() => {

            if (!verifiVariable(rol)) {

                if (rol.includes("Firmante") && rol.includes("Pagador")) {
                    setIconList(
                        <Tooltip
                            title={
                                <Typography
                                    variant="subtitle1"
                                    color={["white.main"]}
                                    gutterBottom
                                >
                                    Esta persona participará
                                    firmando y pagando el documento.
                                </Typography>
                            }
                            placement="top"
                            TransitionComponent={Fade}
                            TransitionProps={{
                                timeout: 600,
                            }}
                            arrow
                        >
                            <Box sx={{ display: 'flex' }}>
                                <SingIconListIcon style={{ width: '15px', height: '15px', marginRight: '10px' }} />
                                <PayIconLisIcon style={{ width: '15px', height: '15px' }} />
                            </Box>
                        </Tooltip>
                    )
                } else if (rol.includes("Firmante")) {
                    setIconList(
                        <Tooltip
                            title={
                                <Typography
                                    variant="subtitle1"
                                    color={["white.main"]}
                                    gutterBottom
                                >
                                    Esta persona participará
                                    únicamente como firmante.
                                </Typography>
                            }
                            placement="top"
                            TransitionComponent={Fade}
                            TransitionProps={{
                                timeout: 600,
                            }}
                            arrow
                        >
                            <SingIconListIcon style={{ width: '15px', height: '15px' }} />
                        </Tooltip>
                    )
                } else if (rol.includes("Copia")) {
                    setIconList(
                        <Tooltip
                            title={
                                <Typography
                                    variant="subtitle1"
                                    color={["white.main"]}
                                    gutterBottom
                                >
                                    Esta persona recibirá
                                    una copia del documento.
                                </Typography>
                            }
                            placement="top"
                            TransitionComponent={Fade}
                            TransitionProps={{
                                timeout: 600,
                            }}
                            arrow
                        >
                            <CopyIcon style={{ width: '15px', height: '15px' }} />
                        </Tooltip>
                    )
                } else if (rol.includes("Pagador")) {
                    setIconList(
                        <Tooltip
                            title={
                                <Typography
                                    variant="subtitle1"
                                    color={["white.main"]}
                                    gutterBottom
                                >
                                    Esta persona participará pagando
                                    el trámite.
                                </Typography>
                            }
                            placement="top"
                            TransitionComponent={Fade}
                            TransitionProps={{
                                timeout: 600,
                            }}
                            arrow
                        >
                            <PayIconLisIcon style={{ width: '15px', height: '15px' }} />
                        </Tooltip>
                    );
                };
            };

        }, [rol]);

        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center"
                }}
            >
                {!verifiVariable(textList) && (
                    <Typography
                        style={{
                            width: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>
                        {textList}
                    </Typography>
                )}

                {!verifiVariable(iconList) && iconList}
            </div>
        );

    };

    const handleOpenModal = () => {
        setOpenModal(true)
    };

    useEffect(() => {
        const payers = participants.filter(
            (item) => parseInt(item["pay"]) !== 0
        );

        let pay = 0;

        payers.forEach((item) => (pay += parseInt(item["pay"])));

        if (payers.length === 0) setTextPay("No se ha definido un pagador");

        if (payers.length !== 0 && pay === 50)
            setTextPay("Solo se ha definido un 50% del pago");

        if (payers.length !== 0 && pay === 100) setTextPay("");
    }, [participants]);

    return (
        <Fragment>

            <ModalAddSignInfo openModal={openModal} setOpenModal={setOpenModal} />

            <AddParticipants openAddDiag={addPart} />

            <Typography
                variant="h6"
                gutterBottom
                className={`${styles.title} ${styles.spacinContent}`}
                marginTop={"20px"}
                marginBottom={"20px"}
            >
                Participantes {participants.length}
            </Typography>

            {participants.length !== 0 && (
                <Typography
                    variant="h6"
                    gutterBottom
                    className={`${styles.title} ${styles.spacinContent}`}
                    marginBottom={"20px"}
                    marginTop={"20px"}
                    sx={{ color: "red !important" }}
                >
                    {textPay}
                </Typography>
            )}

            <FireNav component="nav" disablePadding>
                {participants.map((item, index) => {
                    return (
                        <Fragment key={item["email"] + index} sx={{ padding: '0 .2rem' }}>

                            <Divider />

                            <Grid container sx={{ padding: '0 .4rem' }}>

                                <ListItem component="div" disablePadding>

                                    <Grid sx={{
                                        width: {
                                            xs: '12rem',
                                            lg: '15rem'
                                        },
                                        marginRight: '1rem',
                                    }}>
                                        <ListItemButton sx={{ height: 56 }}>
                                            <ListItemIcon>
                                                <Ellipse1 />
                                            </ListItemIcon>

                                            <ListItemText
                                                primary={
                                                    <TextList
                                                        textList={item["email"]}
                                                    />
                                                }
                                                primaryTypographyProps={{
                                                    color: "black.main",
                                                    fontWeight: "medium",
                                                    variant: "body2",
                                                }}
                                            />
                                        </ListItemButton>
                                    </Grid>

                                    <Grid>
                                        <Box>
                                            <TextList
                                                rol={item["rol"]}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid sx={{ marginLeft: 'auto' }}>
                                        <Tooltip title="Eliminar participante">
                                            <IconButton
                                                className={styles.deleteIcon}
                                                size="small"
                                                onClick={() => removeParticipant(item)}
                                            >
                                                <DeteleIcon />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip
                                            title="Editar participante"
                                            sx={{ margin: '0 1rem 0 0rem' }}
                                        >
                                            <IconButton
                                                size="small"
                                                onClick={() => openDiag(item)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>

                                </ListItem>
                            </Grid>
                        </Fragment>
                    );
                })}

                <Divider />

                <ListItem
                    component="div"
                    disablePadding
                    sx={{ padding: '0 .4rem' }}
                >
                    <ListItemButton sx={{ height: 56 }}>
                        <ListItemIcon>
                            <Ellipse1 />
                        </ListItemIcon>

                        <ListItemText
                            primary="Agregar participante"
                            onClick={() => openDiag(undefined)}
                            primaryTypographyProps={{
                                color: "gray.light",
                                fontWeight: "medium",
                                variant: "body2",
                            }}
                        />
                    </ListItemButton>

                    <Tooltip title="Agregar participante" >
                        <IconButton
                            size="small"
                            onClick={() => openDiag(undefined)}
                        >
                            <PlusIcon style={{ width: '15px', height: '15px' }} />
                        </IconButton>
                    </Tooltip>

                    <Tooltip
                        title="Como realizar la asignación de firmas"
                        sx={{ margin: '0 .9rem 0 0' }}
                    >
                        <IconButton
                            size="small"
                            onClick={handleOpenModal}
                        >
                            <QuestionIcon style={{ width: '17px', height: '17px' }} />
                        </IconButton>
                    </Tooltip>
                </ListItem>

                <Divider />
            </FireNav>
        </Fragment>
    );
};

export default Participants;
