import { Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import dayjs from 'dayjs';

import { ReactComponent as CalendarIcon } from "../../img/calendar-icon.svg";

import { verifiVariable } from "../../../../../utils/enums";
import { blackList, updateSchedule } from "../../services";
import Text from "../../../../heading/Text";
import { Context } from "../../../../../context/utilsContext";


const ActionsCustomButton = styled(Button)((props) => ({
    marginTop: '27px',
    fontStyle: 'normal',
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: 'none',
    fontSize: 16,
    padding: '11px 11px',
    border: '1px solid',
    borderRadius: '7px',
    lineHeight: 1.5,
    backgroundColor: props.theme.palette.primary.light,
    // backgroundColor: '#0063cc',
    borderColor: props.theme.palette.primary.light,
    fontFamily: [
        '"PoppinsBold"'
    ].join(','),
    '&:hover': {
        backgroundColor: props.theme.palette.primary.xlight,
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
}));

const DeleteAndUpdateCustomButtom = styled(Button)((props) => ({
    border: `1.5px solid ${props.theme.palette.primary.light}`
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    boxShadow: '0px 4px 11px 5px rgba(0, 79, 159, 0.04)',
    borderRadius: '7px',
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
};

const Reschedule = ({ contractCreatedDetails, getTramit }) => {
    const { setLoading } = useContext(Context);
    const {
        session: {
            login: {
                user
            }
        },
        scheduling: {
            notaryList
        }
    } = useSelector((state) => state.root);
    const today = dayjs();
    const [valueCalendar, setValueCalendar] = useState(dayjs(dayjs().format('YYYY-MM-DD')));
    const [valueTimePicker, setValueTimePicker] = useState(dayjs(dayjs().format('YYYY-MM-DDTHH:mm')));
    const [endTime, setEndTime] = useState(dayjs().set('hour', 17).startOf('hour'));
    const [startTime, setStartTime] = useState(dayjs().set('hour', 9).startOf('hour'));
    const [open, setOpen] = useState(false);
    const [blackListDate, setBlackListDate] = useState([]);
    const [blackListTime, setBlackListTime] = useState([]);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const isWeekend = (date) => {
        const day = date.day();

        return day === 0 || day === 6;
    }

    const consulNotary = async (tramit) => {
        try {

            const notary = notaryList.find((item) => tramit['agendamiento']['juridiction_id'] === item['juridiction_id']);

            if (!verifiVariable(notary)) {

                setStartTime(dayjs().set('hour', parseInt(notary['start_hour'].split(':')[0])).startOf('hour'));

                setEndTime(dayjs().set('hour', parseInt(notary['end_hour'].split(':')[0])).startOf('hour'));

            }

        } catch (error) {
            console.log(error);
        }
    }


    const consulBlackList = async () => {
        try {

            const { token } = user;

            const response = await blackList(dayjs().format('YYYY-MM'), token);

            if (response['status'] === 'Success') setBlackListDate(response['data']);

        } catch (error) {
            console.log(error);
        }
    }

    const reAgendar = async () => {
        try {
            const { token } = user;
            setLoading(true);

            const response = await updateSchedule(
                contractCreatedDetails['agendamiento']['id'],
                contractCreatedDetails['agendamiento']['notario_id'],
                {
                    date: valueCalendar.format('YYYY-MM-DD'),
                    start_hour: valueTimePicker.format('HH:mm'),
                    end_hour: valueTimePicker.add(1, 'hour').format('HH:mm')
                },
                token
            );


            if (response['status'] === 'Success') {
                getTramit();
                handleClose();
            } else {
                handleClose();
            }

            console.log('updateSchedule: ', response);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            handleClose();
        }
    };

    useEffect(() => {

        if (!verifiVariable(contractCreatedDetails)) {
            consulBlackList();
            consulNotary(contractCreatedDetails);

            setValueCalendar(dayjs(contractCreatedDetails['agendamiento']['date']));
            setValueTimePicker(dayjs(`${contractCreatedDetails['agendamiento']['date']}T${contractCreatedDetails['agendamiento']['start_hour']}`));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractCreatedDetails]);

    return (

        <Fragment>

            <Tooltip
                title="Ver contrato"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                arrow
            >
                {/* <div>
                    <EyeIcon onClick={() => handleOpen()} />
                </div> */}


                <DeleteAndUpdateCustomButtom
                    sx={{
                        padding: '4px 40px',
                        marginLeft: '20px'
                    }}
                    variant="outlined"
                    startIcon={<CalendarIcon className="icon--blue" />}
                    onClick={() => handleOpen()}
                >
                    Reagendar
                </DeleteAndUpdateCustomButtom>


            </Tooltip>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>

                    <Box sx={style}>


                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                            <DemoContainer components={['DateCalendar']}>

                                <DemoItem
                                    label={
                                        <Text
                                            align="left"
                                            mgButton
                                            variant="h6"
                                        >
                                            Seleccione una fecha
                                        </Text>
                                    }
                                >

                                    <DateCalendar
                                        disablePast
                                        shouldDisableDate={(day) => [today.format('YYYY-MM-DD')].includes(day.format('YYYY-MM-DD')) || isWeekend(day)}
                                        value={valueCalendar}
                                        onChange={(newValue) => {

                                            const blackListData = [...blackListDate].filter((item) => item['date'] === newValue.format('YYYY-MM-DD'));
                                            const arrayData = [];

                                            blackListData.forEach(element => {
                                                arrayData.push(element['start_hour'].split(':')[0]);
                                                // arrayData.push(element['end_hour'].split(':')[0]);
                                            });

                                            setBlackListTime(arrayData);

                                            setValueCalendar(newValue);
                                        }}
                                    />

                                </DemoItem>

                            </DemoContainer>

                        </LocalizationProvider>


                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                            <DemoContainer
                                components={[
                                    'MobileTimePicker'
                                ]}
                            >

                                <DemoItem
                                    label={
                                        <Text
                                            align="left"
                                            mgButton
                                            variant="h6"
                                        >
                                            Seleccione una hora
                                        </Text>
                                    }
                                >

                                    <MobileTimePicker
                                        minTime={startTime}
                                        maxTime={endTime}
                                        shouldDisableTime={(hour, view) => {
                                            return view === "hours" && blackListTime.includes(hour.format('HH'));
                                        }}
                                        value={valueTimePicker}
                                        onChange={(newValue) => {
                                            setValueTimePicker(newValue);
                                        }}
                                    />

                                </DemoItem>

                            </DemoContainer>

                        </LocalizationProvider>

                        <ActionsCustomButton sx={{ alignSelf: 'flex-end' }} variant="contained" color="primary" /* disabled={disableBackButtom} */ onClick={() => reAgendar()}>
                            Guardar cambios
                        </ActionsCustomButton>

                    </Box>

                </Fade>

            </Modal>

        </Fragment>

    );

}

export default Reschedule;